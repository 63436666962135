//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : index.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240712     Jecyline     import CompanyInfo, FirstTimeLogin, create NoSidebarRoutingObjects, create NoSidebarLayout
//* 20240715     Jecyline     import Affiliate
//* 20240724     Jecyline     implement scroll to div for chat
//* 20241001     Jecyline     import CampaignSummary
//
//***************************************************************************/
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import Header from "../../views/layout/Header";
import Sidebar from "../../views/layout/Sidebar";
import ChatSidebar from "../../components/Sidebar/chatSidebar";
import Dashboard from "../../views/pages/Pages/Dashboard/dashboard";
import Chat from "../../views/pages/Pages/Chat/index";
import ChatList from "../../views/pages/Pages/Chat/chatList";
import FileManager from "../../views/pages/Pages/FileManager/index";
import CampaignSummary from "../../views/pages/Pages/CampaignSummary/index";
import PolotnoDisplay from "../../views/pages/Pages/PolotnoDisplay/index";
import CampaignPolotno from "../../views/pages/Pages/CampaignPolotno/index";

// import ClientProfile from "../../views/pages/Profile/ClientProfile";
import Profile from "../../views/pages/Pages/Profile/Profile";
// import Search from "../../views/pages/Pages/Search/Search";
import Faq from "../../views/pages/Pages/Faq";
import PrivacyPolicy from "../../views/pages/Pages/PrivacyPolicy";
import BlankPage from "../../views/pages/Pages/BlankPage";

import CompanyInfo from "../../views/pages/Pages/CompanyInfo";
import FirstTimeLogin from "../../views/pages/Pages/FirstTimeLogin";
import Affiliate from "../../views/pages/Pages/Affiliate";

import SmartScan from "../../views/pages/Pages/Chat/chatSmartScan";

const AppContainer = () => {
  const bottomRef = useRef(null);

  useEffect(() => {
    localStorage.setItem("colorschema", "purple");
    localStorage.setItem("layout", "vertical");
    localStorage.setItem("layoutwidth", "fixed");
    localStorage.setItem("layoutpos", "fluid");
    localStorage.setItem("topbartheme", "light");
    localStorage.setItem("layoutSized", "lg");
    localStorage.setItem("layoutStyling", "default");
    localStorage.setItem("layoutSidebarStyle", "dark");
  }, []);

  const routingObjects = [
    {
      id: 2,
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      id: 23,
      path: "file-manager",
      element: <FileManager />,
    },
    {
      id: 24,
      path: "campaign-summary",
      element: <CampaignSummary />,
    },
    {
      id: 25,
      path: "visuallex",
      element: <PolotnoDisplay />,
    },
    {
      id: 26,
      path: "Editor-summary",
      element: <CampaignPolotno />,
    },
    
    // {
    //   id: 52,
    //   path: "client-profile",
    //   element: <ClientProfile />,
    // },
    {
      id: 53,
      path: "profile",
      element: <Profile />,
    },
    {
      id: 54,
      path: "company-info",
      element: <CompanyInfo />,
    },
    {
      id: 55,
      path: "affiliate",
      element: <Affiliate />,
    },
    // {
    //   id: 57,
    //   path: "search",
    //   element: <Search />,
    // },
    {
      id: 58,
      path: "faq",
      element: <Faq />,
    },
    {
      id: 61,
      path: "privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      id: 62,
      path: "blank-page",
      element: <BlankPage />,
    },
  ];

  const ChatRoutingeObjects = [
    {
      id: 1,
      path: "chat",
      element: <Chat />,
    },
    {
      id: 6,
      path: "chat-list",
      element: <ChatList />,
    },
    {
      id: 7,
      path: "smart-scan",
      element: <SmartScan />,
    }
  ];
 
  const NoSidebarRoutingObjects = [
    {
      id: 1,
      path: "first-time-login",
      element: <FirstTimeLogin />,
    },
  ]
  const NoSidebarLayout = () => (
    <>
      <Outlet />
    </>
  );
  const SidebarLayout = () => (
    <>
      <Header />
      <Sidebar />
      {/* <OffCanvas /> */}
      <Outlet />
    </>
  );
  const ChatSidebarLayout = () => (
    <>
      <Header />
      <ChatSidebar 
        bottomRef={bottomRef}
      />
      <Outlet />
    </>
  );

  const mobileResponsive = (event) => {
    const excludedHeader = document.querySelector(".header");
    const excludedSidebar = document.querySelector(".sidebar");

    if(excludedHeader && excludedSidebar) {
      if (
        !excludedHeader.contains(event.target) &&
        !excludedSidebar.contains(event.target)
      ) {
        document.body.classList.remove("slide-nav");
      }
    }
  };

  return (
    <>
      <div className="main-wrapper" onClick={mobileResponsive}>
        <Routes>
          <Route path={"/*"} element={<SidebarLayout />}>
            {routingObjects.map((item) => (
              <Route key={item.id} path={item.path} element={item.element} />
            ))}
          </Route>
          <Route path={"/*"} element={<NoSidebarLayout />}>
            {NoSidebarRoutingObjects.map((item) => (
              <Route key={item.id} path={item.path} element={item.element} />
            ))}
          </Route>
          <Route path={"/*"} element={<ChatSidebarLayout bottomRef={bottomRef}/>}>
            {ChatRoutingeObjects.map((item) => (
              // <Route key={item.id} path={item.path} element={item.element} />
              <Route 
                key={item.id} 
                path={item.path} 
                // element={React.cloneElement(item.element, { bottomRef })}
                element={item.path === 'chat' ? React.cloneElement(item.element, { bottomRef }) : item.element} 
               />
            ))}
          </Route>
          <Route path={"/*"} element={<SidebarLayout />}>
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default AppContainer;
