//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : index.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline     Add index.s file for Campaign Summary
//
//***************************************************************************/
import React, { Suspense, useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CampaignPolotnoChatSidebar = React.lazy(() => import('./campaignPolotnoSidebar'));
const CampaignPolotnoView = React.lazy(() => import('./campaignPolotnoView'));

const CampaignPolotno = () => {
  
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-sm-12">
            <CampaignPolotnoView />
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};

export default CampaignPolotno;
