//****************************************************************************
//* Filename  : chatSagas.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240829     Jecyline     Create chatSagas.jsx
//* 20240830     Jecyline     add fetchChatMemory
//* 20240905     Jecyline     add handleCreateChat(), handleChatClick(), handleFetchMoreChatDetails(), handleUpdateReaction(), handleUpdateImageSatisfaction(), handleStorePrompt(), handleStoreReply(), handleSelectionMode()
//* 20240905     Jecyline     add handleUploadImage(), handleCamera(), handleChatbot(), handleTextToImage(), handleChangeBackground(), handleRemoveBackground(), handleMasking(), handleInpainting()
//* 20240906     Jecyline     get selection option from chatConstants
//* 20240909     Jecyline     fix bug for handleCamera() & handleTextToImage(): set full image url
//* 20240911     Jecyline     update brain flow, fix date format issue(use moment)
//* 20240912     Jecyline     integrate brain flow 70%
//* 20240918     Ashraf       regen image function
//* 20240930     Jecyline     redo flow for handleUploadImage(), handleCamera() and image editing flow 
//* 20240930     Jecyline     fix chat loading issue
//* 20241001     Jecyline     add handleChatClickExternal
//* 20241002     Jecyline     add load more ads for ads library
//* 20241003     Jecyline     initial_copy: ask for strategy before generate copy
//* 20241004     Jecyline     add suggest more ads flow
//* 20241004     Glynnis      add budget and reach
//
//***************************************************************************/
import { call, put, takeLatest, delay, all, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { backend_url } from '../../base_urls';
import { getFullImageUrl } from '../../utils/urlUtils';
import moment from 'moment';
import { 
  createChatAction,
  chatClickAction,
  chatClickExternalAction,
  fetchMoreChatDetailsAction,
  fetchChatMemoryAction,
  updateReactionAction,
  storePromptAction,
  selectionModeAction,
  uploadImageAction,
  cameraAction,
  loadMoreAdsAction,
  createMoreAdsAction,
  selectLocationAction,
  createMoreCopyCraftAction,
} from '../actions/chatActions';

import { 
  appendCurrentChatDetails, 
  appendChatMemory, 
  setIsSufficientCredit,
  editCurrentChatData,
  setChatLoader,
  setLoadingMoreAds,
  editCurrentChatDetails,
  setBottomRefTrigger,
  setCurrentChatData,
  setCurrentChatDetails,
  setCurrentPage,
  setHasMore,
  setIsLoadingChatDetails,
  setChatStage,
  prependCurrentChatDetails,
  setIsLoadingFirstPage,
  setChatList,
  setChatSelection,
  editChatSelection,
  editChatStage,
  setTempPrompt,
  setChatOption,
  setBrandBoostDetail,
  editBrandBoostDetail,
} from '../slices/chatSlice';

import { 
  selectChatMemoryForCurrentChat,
  selectCurrentPage,
  selectHasMore,
  selectIsLoadingChatDetails,
  selectIsLoadingFirstPage,
  selectChatStage,
  selectChatSelectionForCurrentChat,
  selectCurrentChatData,
  selectChatSelectionById,
  selectChatOptionById,
  selectBrandBoostDetailById,
} from '../selectors/chatSelectors';

import { 
  createChatApi,
  getChatListApi,
  getChatDetailApi,
  getChatByIdApi,
  fetchChatMemoryApi,
  updateReactionApi,
  updateImageSatisfactionApi,
  resetSmartScanApi,
  uploadSmartScanKeyword,
  storePromptApi,
  uploadImageApi,
  updateChatDetailApi,
  getNextApi,
  storeReplyApi,
  storeBulkReplyApi,
  updateChatContentFlagApi,
  aiImageRecognitionApi,
  aiChatbotApi,
  aiProofreaderApi,
  aiTranslatorApi,
  aiImageFluxApi,
  aiBackgroundRegenerateApi,
  aiBackgroundRemoveApi,
  aiImageMaskApi,
  aiImageInpaintingApi,
  smartScanApi,
  adsLibraryApi,
  storeProductKeywordApi,
  updateProductKeywordApi,
  updateAdsLocationApi,
  aiCountriesApi,
  aiAdsInfoFromFBUrlApi,
  aiAdsInterestScraperApi,
  adsMetricsApi,
} from '../../api/chatApi';

import {
  targetList,
  targetOptionsList,
  beforeInitial,
  beforeInitialOptions,
} from '../../utils/chatContants'
// Handle Backend API ---------------------------------------------- //
function* handleCreateChat(action) {
  console.log('chatSagas: handleCreateChat', action);

  const { chat_name, campaign_type, brand_from } = action.payload;

  try {
    yield put(setCurrentChatDetails({
      date: '',
      newDetails: []
    }));

    let introduction_text = i18n.t('Hi I am Marvis, a digital marketing virtual assistant.');
    const chatResponse = yield call(createChatApi, chat_name, campaign_type, brand_from, introduction_text);
    const { chat, chatDetail } = chatResponse.data;
    console.log('handleCreateChat: chatResponse', chatResponse);

    // Update the current chat data
    yield put(setCurrentChatData(chat));
  
    const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    yield put(setCurrentChatDetails({
      date: today,
      newDetails: Array.isArray(chatDetail) ? chatDetail : [chatDetail]
    }));
    // Fetch the updated chat list
    const chatListResponse = yield call(getChatListApi);

    const groupedChats = {
      chatList: chatListResponse.data.chats,
      today: chatListResponse.data.today_chats,
      yesterday: chatListResponse.data.yesterday_chats,
      last7Days: chatListResponse.data.last_7_days_chats,
      olderDays: chatListResponse.data.older_days_chats
    };
    
    yield put(setChatList(groupedChats));
    yield put(setIsLoadingChatDetails(false));
    yield put(setHasMore(false));
    yield put(setLoadingMoreAds({ chat_id: chat.id, isLoading: false }));
    yield put(setChatStage({ chat_id: chat.id }));
    yield put(editChatStage({
      chat_id: chat.id,
      updates: {campaign_type: chat.campaign_type}
    }));
    yield call(handleFetchChatMemory, { payload: { chatId: chat.id } });

    if(campaign_type === 'AdsClone'){
      yield put(editChatStage({
        chat_id: chat.id,
        updates: {
          stage: '1',
          subStage: 'adsclone_location',
          status: 'start',
        }
      }));

      yield put(setChatLoader({ 
        chat_id: chat.id, 
        isLoading: true, 
        isListening: false, 
        message: "" 
      }));
      const options = yield call(fetchCountryList);

      yield put(setChatSelection({ 
        chat_id: chat.id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: 'Location Dropdown',
        title: "",
        options: options,
        animationClass: 'slide-up'
      }));
      
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat.id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("Where is the location of the product or service you want to advertise?")}`, 
            reply_role: 'system' 
          },
          isSuccess: true,
        }
      });
    } else if(campaign_type === 'BrandBoost'){
      yield put(setBrandBoostDetail({ chat_id: chat.id }));

      yield put(editChatStage({
        chat_id: chat.id,
        updates: {
          stage: '1',
          subStage: 'brandboost_location',
          status: 'start',
        }
      }));

      yield put(setChatLoader({ 
        chat_id: chat.id, 
        isLoading: true, 
        isListening: false, 
        message: "" 
      }));
      const options = yield call(fetchCountryList);

      yield put(setChatSelection({ 
        chat_id: chat.id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: 'Location Dropdown',
        title: "",
        options: options,
        animationClass: 'slide-up'
      }));
      
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat.id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("Where is the location of the product or service you want to advertise?")}`, 
            reply_role: 'system' 
          },
          isSuccess: true,
        }
      });
    } else if(campaign_type === 'CopyCraft'){

      yield put(editChatStage({
        chat_id: chat.id,
        updates: {
          stage: '1',
          subStage: 'copycraft_option',
          status: 'start',
        }
      }));

      yield put(setChatLoader({ 
        chat_id: chat.id, 
        isLoading: true, 
        isListening: false, 
        message: "" 
      }));
      
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat.id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("Ready to craft some attention-grabbing copy?")}`,
            reply_role: 'system' 
          },
          isSuccess: true,
        }
      });

      yield put(setChatLoader({ 
        chat_id: chat.id, 
        isLoading: false, 
        isListening: false, 
        message: "" 
      }));
  
      yield put(setChatSelection({
        chat_id: chat.id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: targetList.copycraft_option,
        title: "",
        options: targetOptionsList.copycraft_option,
        animationClass: 'slide-up'
      }));
    } else if(campaign_type === 'VisualAds'){

      yield put(editChatStage({
        chat_id: chat.id,
        updates: {
          stage: '1',
          subStage: 'visualads_option',
          status: 'start',
        }
      }));

      yield put(setChatLoader({ 
        chat_id: chat.id, 
        isLoading: true, 
        isListening: false, 
        message: "" 
      }));
      
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat.id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("Ready to create some stunning images! Just let me know what you'd like to generate, and we'll get started.")}`,
            reply_role: 'system' 
          },
          isSuccess: true,
        }
      });

      yield put(setChatLoader({ 
        chat_id: chat.id, 
        isLoading: false, 
        isListening: false, 
        message: "" 
      }));
  
      yield put(setChatSelection({
        chat_id: chat.id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: targetList.visualads_option,
        title: "",
        options: targetOptionsList.visualads_option,
        animationClass: 'slide-up'
      }));
    } else if(campaign_type === 'SketchSpark'){

      yield put(editChatStage({
        chat_id: chat.id,
        updates: {
          stage: '1',
          subStage: 'sketchspark_get_img',
          status: 'start',
        }
      }));

      yield put(setChatLoader({ 
        chat_id: chat.id, 
        isLoading: true, 
        isListening: false, 
        message: "" 
      }));
      
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat.id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("Ready to craft some attention-grabbing copy and stunning image from your sketch? Upload or take the image of your sketch")}`,
            reply_role: 'system' 
          },
          isSuccess: true,
        }
      });

      yield put(setChatLoader({ 
        chat_id: chat.id, 
        isLoading: false, 
        isListening: false, 
        message: "" 
      }));

    } else {
      
      yield put(editChatStage({
        chat_id: chat.id,
        updates: {
          stage: '1',
          subStage: 'scanner', //scanner
          status: 'start',
        }
      }));
      
      // // added before initial
      // const stageList = yield select(selectChatStage, chat.id);
      // const stageData = stageList[chat.id];
  
      // if (stageData.brands_options === '') {
      //   yield put(setChatLoader({ 
      //     chat_id: chatDetail.chat_id, 
      //     isLoading: false, 
      //     isListening: false, 
      //     message: "" 
      //   }));
      //   yield call( handleStoreReply, { 
      //     payload: { 
      //       replyData: {
      //         chat_detail_id: chatDetail.id,
      //         reply: 'Hi I am Marvis, a digital marketing virtual assistant. Let\'s start creating your winning ads campaign. First of all, are you creating this campaign for your company?', 
      //         reply_role: 'system' 
      //       },
      //       isSuccess: true,
      //     }
      //   });
      //   yield put(setChatSelection({ 
      //     chat_id: chatDetail.chat_id, 
      //     chat_detail_id: chatDetail.id, 
      //     isDisplay: true, 
      //     target: beforeInitial.brands_options,
      //     // title: 'Let\'s start creating your winning ads campaign. First of all, are you creating this campaign for your company?',
      //     options: beforeInitialOptions.brands_options,
      //     animationClass: 'slide-up',
      //   }));
      // }
      
      //temporary start
      yield put(setChatLoader({ 
        chat_id: chat.id, 
        isLoading: false, 
        isListening: false, 
        message: "" 
      }));
  
      yield put(setChatSelection({
        chat_id: chatDetail.chat_id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: beforeInitial.brands_options,
        title: `${i18n.t("First of all, are you creating this campaign for your company?")}`,
        options: beforeInitialOptions.brands_options,
        animationClass: 'slide-up'
      }));
      // yield put(setChatSelection({
      //   chat_id: chatDetail.chat_id, 
      //   chat_detail_id: '', 
      //   isDisplay: true, 
      //   target: beforeInitial.campaign_creation_options,
      //   title: "Let's create your campaign.",
      //   options: beforeInitialOptions.campaign_creation_options,
      //   animationClass: 'slide-up'
      // }));
      // temporary end
      // // end of before initial
    }
    

  } catch (error) {
    console.error('handleCreateChat Saga Error:', error);

  }
}

function* fetchCountryList() {
  console.log('chatSagas: fetchCountryList');
  try {
    const aiResponse = yield call(aiCountriesApi);
    console.log('fetchCountryList: aiResponse',aiResponse);

    const countryList = aiResponse.data.all_countries;
    
    if (!Array.isArray(countryList)) {
      console.log('fetchCountryList: Invalid countryList format', countryList);
      return [{
        value: 'MY',
        label: 'Malaysia',
      }];
    }

    const countries = countryList.map(country => ({
      value: country.cca2,
      label: country.name.common,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
    console.log('fetchCountryList: countries',countries);

    return countries;

  } catch (error) {
    // console.error('Error fetching data:', error);
    return [{
      value: 'MY',
      label: 'Malaysia',
    }];
  }
}

function* handleSelectLocation(action) {
  console.log('chatSagas: handleSelectLocation', action);
  const { chat_id, location_code, location_full } = action.payload;

  try {
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        ads_location: location_code,
      }
    }));

    yield put(setChatSelection({ 
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: false, 
      target: '',
      action: '',
      title: '',
      options: [],
      animationClass: 'slide-down'
    }));

    //set prompt date and time
    const now = moment(); // Get current time
    const promptDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
    const promptTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

    yield call( handleStorePrompt, { 
      payload: { 
        chatId: chat_id, 
        promptData: {
          prompt: location_full,
          promptDate: promptDate,
          promptTime: promptTime,
        }
      }
    });

  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

function* handleChatClick(action) {
  console.log('chatSagas: handleChatClick', action);

  const { chatId } = action.payload;

  try {
    const currentChatData = yield select(selectCurrentChatData);

    if(currentChatData && chatId === currentChatData.id){
      yield put(setBottomRefTrigger(Date.now()));

    } else {

    
      yield put(setCurrentChatData(null));
      yield put(setCurrentChatDetails({
        date: '',
        newDetails: []
      }));

      yield put(setIsLoadingFirstPage(false));
      yield put(setIsLoadingChatDetails(false));
      yield put(setCurrentPage(0));
      yield put(setHasMore(true));
      yield put(setLoadingMoreAds({ chat_id: chatId, isLoading: false }));
      yield put(setChatStage({ chat_id: chatId }));
      yield call(handleFetchMoreChatDetails, { payload: { chatId, from: 'Saga' } });
    }
  } catch (error) {
    console.error('handleChatClick Saga Error:', error);

  }
}

function* handleChatClickExternal(action) {
  console.log('chatSagas: handleChatClickExternal', action);

  const { chatId, from } = action.payload;

  try {
    //get chat list
    const chatListResponse = yield call(getChatListApi);
    console.log('handleChatClickExternal: chatListResponse:', chatListResponse);

    const groupedChats = {
      chatList: chatListResponse.data.chats,
      today: chatListResponse.data.today_chats,
      yesterday: chatListResponse.data.yesterday_chats,
      last7Days: chatListResponse.data.last_7_days_chats,
      olderDays: chatListResponse.data.older_days_chats
    };
    
    yield put(setChatList(groupedChats));

    //set current chatdata
    yield put(setCurrentChatData(null));
    yield put(setCurrentChatDetails({
      date: '',
      newDetails: []
    }));

    yield put(setIsLoadingFirstPage(false));
    yield put(setIsLoadingChatDetails(false));
    yield put(setCurrentPage(0));
    yield put(setHasMore(true));
    yield put(setLoadingMoreAds({ chat_id: chatId, isLoading: false }));
    yield put(setChatStage({ chat_id: chatId })); 

    yield call(handleFetchMoreChatDetails, { payload: { chatId, from } });

  } catch (error) {
    console.error('handleChatClickExternal Saga Error:', error);

  }
}

function* handleFetchMoreChatDetails(action) {
  console.log('chatSagas: handleFetchMoreChatDetails', action);

  const { chatId, from } = action.payload;
  const hasMore = yield select(selectHasMore);
  const currentPage = yield select(selectCurrentPage);
  const isLoadingChatDetails = yield select(selectIsLoadingChatDetails);
  const isLoadingFirstPage = yield select(selectIsLoadingFirstPage);
  // console.log('chatId', chatId);
  // console.log('currentPage', currentPage);
  // console.log('hasMore', hasMore);
  // console.log('isLoadingChatDetails', isLoadingChatDetails);
  // console.log('isLoadingFirstPage', isLoadingFirstPage);
  if(hasMore && !isLoadingFirstPage && !isLoadingChatDetails) {
    try {
      
      if(currentPage === 0) {
        yield put(setIsLoadingFirstPage(true));
      } else {
        yield put(setIsLoadingChatDetails(true));
        yield delay(200); // Optional delay
      }
      
      const newPage = Number(currentPage) + 1;
      const response = yield call(getChatDetailApi, chatId, newPage);
  
      const { chat, chatdetails, current_page, last_page, selected_copy, selected_image_url, selected_image_desc } = response.data;
      
      const checkPage = currentPage;
      if(checkPage === 0) {
        yield put(setCurrentChatData(chat));
        console.log('handleFetchMoreChatDetails: chat', chat);
      }

      yield put(prependCurrentChatDetails({ chat_id: chat.id, chatData: chatdetails }));
      yield put(setBottomRefTrigger(Date.now()));
      yield put(setCurrentPage(newPage));
      if (Number(current_page) >= Number(last_page)) {
        yield put(setHasMore(false));
      }

      // Initial load
      if(checkPage !== 0) {
        yield put(setIsLoadingChatDetails(false));

      } else if(checkPage === 0) {

        yield put(editChatStage({
          chat_id: chat.id,
          updates: {campaign_type: chat.campaign_type}
        }));

        yield put(setIsLoadingFirstPage(false));

        const stageList = yield select(selectChatStage, chat.id);
        const stageData = stageList[chat.id];

        if(stageData.status === 'pending'){ // not from create new chat

          //set copy and image content
          yield put(editChatStage({
            chat_id: chat.id,
            updates: {
              selected_copy: selected_copy,
              selected_image_url: selected_image_url,
              selected_image_desc: selected_image_desc,
            }
          }));

          
          if(chat.campaign_type === 'AdsClone'){
            if(from === 'BrandScrap'){
              yield put(editChatStage({
                chat_id: chat.id,
                updates: {
                  stage: '1',
                  subStage: 'adsclone_location',
                  status: 'start',
                }
              }));
        
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));
              const options = yield call(fetchCountryList);

              yield put(setChatSelection({ 
                chat_id: chat.id, 
                chat_detail_id: '', 
                isDisplay: true, 
                target: 'Location Dropdown',
                title: "",
                options: options,
                animationClass: 'slide-up'
              }));
              
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chat.id, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("Where is the location of the product or service you want to advertise?")}`, 
                    reply_role: 'system' 
                  },
                  isSuccess: true,
                }
              });
            } else {

              if(selected_copy === '' && selected_image_url === ''){ //if no selected ads yet
                yield put(editChatStage({
                  chat_id: chat.id,
                  updates: {
                    stage: '1',
                    subStage: 'adsclone_location',
                    status: 'start',
                  }
                }));
                yield put(setChatLoader({ 
                  chat_id: chatId, 
                  isLoading: true, 
                  isListening: false, 
                  message: ''
                }));
                yield call( handleStoreReplyGeneral, { 
                  payload: { 
                    chatId: chatId, 
                    replyData: {
                      chat_detail_id: '',
                      reply: `${i18n.t("Welcome back, it seems you did not complete the AdsClone previously. Let's re-start creating your winning ads campaign. Where is the location of the product or service you want to advertise?")}`,
                      reply_role: 'system',
                    },
                    isSuccess: true,
                  }
                });
                yield put(setChatLoader({ 
                  chat_id: chatId, 
                  isLoading: true, 
                  isListening: false, 
                  message: ''
                }));

                const options = yield call(fetchCountryList);

                yield put(setChatSelection({ 
                  chat_id: chat.id, 
                  chat_detail_id: '', 
                  isDisplay: true, 
                  target: 'Location Dropdown',
                  title: "",
                  options: options,
                  animationClass: 'slide-up'
                }));
                yield put(setBottomRefTrigger(Date.now()));

                yield put(setChatLoader({ 
                  chat_id: chatId, 
                  isLoading: false, 
                  isListening: false, 
                  message: ''
                }));
                
              } else if(selected_copy !== '' && selected_image_url !== ''){ //have selected ads
                yield put(editChatStage({
                  chat_id: chat.id,
                  updates: {
                    stage: '1',
                    subStage: 'adsclone_end',
                    status: 'complete',
                  }
                }));
              }
            }
          } else if(chat.campaign_type === 'BrandBoost'){
            yield put(setBrandBoostDetail({ chat_id: chat.id }));

            // NO MORE brand scrap for brandbost
            // if(from === 'BrandScrap'){
            //   yield put(editChatStage({
            //     chat_id: chat.id,
            //     updates: {
            //       stage: '1',
            //       subStage: 'brandboost_location',
            //       status: 'start',
            //     }
            //   }));
        
            //   yield put(setChatLoader({ 
            //     chat_id: chat.id, 
            //     isLoading: true, 
            //     isListening: false, 
            //     message: "" 
            //   }));
            //   const options = yield call(fetchCountryList);

            //   yield put(setChatSelection({ 
            //     chat_id: chat.id, 
            //     chat_detail_id: '', 
            //     isDisplay: true, 
            //     target: 'Location Dropdown',
            //     title: "",
            //     options: options,
            //     animationClass: 'slide-up'
            //   }));
              
            //   yield call( handleStoreReplyGeneral, { 
            //     payload: { 
            //       chatId: chat.id, 
            //       replyData: {
            //         chat_detail_id: '',
            //         reply:`${i18n.t( "Where is the location of the product or service you want to advertise?")}`, 
            //         edited_reply: "Where is the location of the product or service you want to advertise?", 
            //         reply_role: 'assistant' 
            //       },
            //       isSuccess: true,
            //     }
            //   });
            // } else {
              if(selected_copy === ''){ //if no selected ads yet
                yield put(editChatStage({
                  chat_id: chat.id,
                  updates: {
                    stage: '1',
                    subStage: 'brandboost_location',
                    status: 'start',
                  }
                }));
                yield put(setChatLoader({ 
                  chat_id: chatId, 
                  isLoading: true, 
                  isListening: false, 
                  message: ''
                }));
                yield call( handleStoreReplyGeneral, { 
                  payload: { 
                    chatId: chatId, 
                    replyData: {
                      chat_detail_id: '',
                      reply: `${i18n.t("Welcome back, looks like you have left half way. Would you like to retry again? Tell me where is the location of the product or service you want to get insights?")}`,
                      reply_role: 'system',
                    },
                    isSuccess: true,
                  }
                });
                yield put(setChatLoader({ 
                  chat_id: chatId, 
                  isLoading: true, 
                  isListening: false, 
                  message: ''
                }));

                const options = yield call(fetchCountryList);

                yield put(setChatSelection({ 
                  chat_id: chat.id, 
                  chat_detail_id: '', 
                  isDisplay: true, 
                  target: 'Location Dropdown',
                  title: "",
                  options: options,
                  animationClass: 'slide-up'
                }));
                yield put(setBottomRefTrigger(Date.now()));

                yield put(setChatLoader({ 
                  chat_id: chatId, 
                  isLoading: false, 
                  isListening: false, 
                  message: ''
                }));
                
              } else if(selected_copy !== ''){ //have selected ads
                yield put(editChatStage({
                  chat_id: chat.id,
                  updates: {
                    stage: '1',
                    subStage: 'brandboost_summary',
                    status: 'start',
                  }
                }));


                yield call( handleStoreReplyGeneral, { 
                  payload: { 
                    chatId: chatId, 
                    replyData: {
                      chat_detail_id: '',
                      reply: `${i18n.t("Welcome back, This is the prediction for your ads. Would you like to retry with another keyword?")}`,
                      reply_role: 'system',
                    },
                    isSuccess: true,
                  }
                });

                yield put(setChatLoader({ 
                  chat_id: chatId, 
                  isLoading: true, 
                  isListening: false, 
                  message: ''
                }));
                const detail = yield call( handleStoreReplyGeneral, { 
                  payload: { 
                    chatId: chatId, 
                    replyData: {
                      chat_detail_id: '',
                      reply: selected_copy,
                      edited_reply: selected_copy,
                      reply_role: 'system',
                      ui_type: 'brandboost',
                    },
                    isSuccess: true,
                  }
                });
                yield put(editChatStage({
                  chat_id: chatId,
                  updates: {
                    adsclone_copy: selected_copy,
                    adsclone_copy_id: detail.id,
            
                  }
                }));

                yield put(setChatSelection({ 
                  chat_id: chatId, 
                  chat_detail_id: '', 
                  isDisplay: true, 
                  target: targetList.brandboost_is_result,
                  title: '',
                  options: targetOptionsList.brandboost_is_result,
                  animationClass: 'slide-up'
                }));
              }
            // }
          } else if(chat.campaign_type === 'CopyCraft'){

            yield put(editChatStage({
              chat_id: chat.id,
              updates: {
                stage: '1',
                subStage: 'copycraft_option',
                status: 'start',
              }
            }));
      
            yield put(setChatLoader({ 
              chat_id: chat.id, 
              isLoading: true, 
              isListening: false, 
              message: "" 
            }));
            
            if(from === 'BrandScrap'){
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chat.id, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("Ready to craft some attention-grabbing copy?")}`,
                    reply_role: 'system' 
                  },
                  isSuccess: true,
                }
              });
            } else {
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chat.id, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("Welcome back! What would you like me to assist you on?")}`,
                    edited_reply: "Welcome back! What would you like me to assist you on?", 
                    reply_role: 'assistant' 
                  },
                  isSuccess: true,
                }
              });
            }
      
            yield put(setChatLoader({ 
              chat_id: chat.id, 
              isLoading: false, 
              isListening: false, 
              message: "" 
            }));
        
            yield put(setChatSelection({
              chat_id: chat.id, 
              chat_detail_id: '', 
              isDisplay: true, 
              target: targetList.copycraft_option,
              title: "",
              options: targetOptionsList.copycraft_option,
              animationClass: 'slide-up'
            }));
          } else if(chat.campaign_type === 'VisualAds'){
            
            
            //no need brand scrap
            // if(from === 'BrandScrap'){

            //   yield call( handleStoreReplyGeneral, { 
            //     payload: { 
            //       chatId: chat.id, 
            //       replyData: {
            //         chat_detail_id: '',
            //         reply: `${i18n.t("Ready to create some stunning images! Just let me know what you'd like to generate, and we'll get started.",)}` 
            //         reply_role: 'assistant' 
            //       },
            //       isSuccess: true,
            //     }
            //   });
            // } else {
            if(selected_image_url === ''){ //no selected ads
              yield put(editChatStage({
                chat_id: chat.id,
                updates: {
                  stage: '1',
                  subStage: 'visualads_option',
                  status: 'start',
                }
              }));
        
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chat.id, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("Welcome back, it seems you did not complete the VisualAds previously. Let's re-start creating some stunning images!.")}`,
                    reply_role: 'system' 
                  },
                  isSuccess: true,
                }
              });
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: false, 
                isListening: false, 
                message: "" 
              }));
          
              yield put(setChatSelection({
                chat_id: chat.id, 
                chat_detail_id: '', 
                isDisplay: true, 
                target: targetList.visualads_option,
                title: "",
                options: targetOptionsList.visualads_option,
                animationClass: 'slide-up'
              }));
            } else if(selected_image_url !== ''){ //have selected ads
              yield put(editChatStage({
                chat_id: chat.id,
                updates: {
                  stage: '1',
                  subStage: 'visualads_revise',
                  status: 'start',
                }
              }));
        
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chat.id, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("Welcome back, here is your image")}`,
                    reply_role: 'system' 
                  },
                  isSuccess: true,
                }
              });
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chat.id, 
                  replyData: {
                    chat_detail_id: '',
                    reply_url: getFullImageUrl(selected_image_url),
                    reply_role: 'system',
                    ui_type: 'result_existing',
                  },
                  isSuccess: true,
                }
              });
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chat.id, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("Would you like to revise your images?")}`,
                    reply_role: 'system' 
                  },
                  isSuccess: true,
                }
              });
            }

            yield put(setChatLoader({ 
              chat_id: chat.id, 
              isLoading: false, 
              isListening: false, 
              message: "" 
            }));
        
            yield put(setChatSelection({
              chat_id: chat.id, 
              chat_detail_id: '', 
              isDisplay: true, 
              target: targetList.visualads_revise_option,
              title: "",
              options: targetOptionsList.visualads_revise_option,
              animationClass: 'slide-up'
            }));

          } else if(chat.campaign_type === 'SketchSpark'){

            if(selected_copy !== '' && selected_image_url !== ''){ //have selected copy and image
              
        
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));

              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chat.id, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("Welcome back, Let's recall what we have done previously")}`,
                    reply_role: 'system' 
                  },
                  isSuccess: true,
                }
              });
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chat.id, 
                  replyData: {
                    chat_detail_id: '',
                    reply: selected_copy,
                    reply_url: getFullImageUrl(selected_image_url),
                    reply_role: 'system',
                    ui_type: 'result_existing',
                  },
                  isSuccess: true,
                }
              });
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));
              let prompt_reg = 'What do you see? give me a detailed real-life version of image generation prompt to turn this sketch into a real image. Give me only the image generation prompts. must highlight to turn the sketch into a reaslistic and real life image. i do not want an animated image';
              let generated_image_url = selected_image_url ? getFullImageUrl(selected_image_url) : '';
              if(backend_url === "http://127.0.0.1:8000") {
                generated_image_url = 'https://images.squarespace-cdn.com/content/v1/616cfb6a0343cf58c0a570b5/c5505422-d6f5-4817-ad8d-a80bf328af06/IMG_1463.PNG';//'https://www.coca-cola.com/content/dam/onexp/us/en/homepage/usa_home_1_campaign_card_1280x1024.jpg/width1960.jpg';
              }
              const recognitionResponse = yield call(aiImageRecognitionApi, generated_image_url, prompt_reg);
              console.log('handleSketchSpark: recognitionResponse', recognitionResponse);

              let image_desc = recognitionResponse.data;
        
              yield put(editChatStage({
                chat_id: chat.id,
                updates: {
                  stage: '1',
                  subStage: 'sketchspark_recall',
                  status: 'start',
                  source_url: selected_image_url,
                  source_desc: image_desc,
                  product_keyword: chat.product_keyword,
                }
              }));
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));
              yield call(handleSketchSpark, chat.id);
              
            } else {
              yield put(editChatStage({
                chat_id: chat.id,
                updates: {
                  stage: '1',
                  subStage: 'sketchspark_get_img',
                  status: 'start',
                }
              }));
        
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));

              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chat.id, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("Welcome back, it seems you did not complete the SketchSpark previously.  Let's start craft some attention-grabbing copy and stunning image from your sketch. Upload or take the image of your sketch.")}`,
                    reply_role: 'system' 
                  },
                  isSuccess: true,
                }
              });
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: false, 
                isListening: false, 
                message: "" 
              }));
            }
            
          } else {
            if(selected_copy !== '' && selected_image_url === ''){ //have copy, no image
              yield put(editChatStage({
                chat_id: chat.id,
                updates: {
                  stage: '3',
                  subStage: 'initial_image',
                  status: 'start',
                }
              }));
            } else if(selected_copy !== '' && selected_image_url !== ''){ //have both
              yield put(editChatStage({
                chat_id: chat.id,
                updates: {
                  stage: '1',
                  subStage: 'initial_prompt',
                  status: 'complete',
                }
              }));
              yield put(setChatLoader({ 
                chat_id: chat.id, 
                isLoading: false, 
                isListening: false, 
                message: "" 
              }));
            } else if(selected_copy === '' && selected_image_url !== ''){ //no copy, have image
              yield put(editChatStage({
                chat_id: chat.id,
                updates: {
                  stage: '1',
                  subStage: 'initial_prompt',
                  status: 'start',
                }
              }));
            } else { // no both
              if(chat.product_keyword === ''){
                yield put(editChatStage({ //not yet went through scanner and ads library
                  chat_id: chat.id,
                  updates: {
                    stage: '1',
                    subStage: 'scanner',
                    status: 'start',
                  }
                }));
              } else if (chat.product_keyword !== ''){ //went through scanner, but not ads library
                yield put(editChatStage({
                  chat_id: chat.id,
                  updates: {
                    stage: '1',
                    subStage: 'adslibrary',
                    status: 'start',
                  }
                }));
  
                yield put(setChatSelection({
                  chat_id: chat.id, 
                  chat_detail_id: '', 
                  isDisplay: true, 
                  target: beforeInitial.campaign_creation_options,
                  title: `${i18n.t("Let's create your campaign.")}`,
                  options: beforeInitialOptions.campaign_creation_options,
                  animationClass: 'slide-up'
                }));
              }
            }
          }
        }
      }

      
    } catch (error) {
      console.error('handleFetchMoreChatDetails Saga Error:', error);
    } finally {

      

    }
  }
}

function* handleFetchChatMemory(action) {
  console.log('chatSagas: handleFetchChatMemory', action);

  const { chatId } = action.payload;

  try {
    const response = yield call(fetchChatMemoryApi, chatId);
    console.log('handleFetchChatMemory: response', response);
    const { chat_memory } = response.data;

    yield put(appendChatMemory({
      chat_id: chatId,
      chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
    }));
  } catch (error) {
    console.error('handleFetchChatMemory Saga Error:', error);
  }
}

function* handleUpdateReaction(action) {
  console.log('chatSagas: handleUpdateReaction', action);

  const { chatDetailId, reply_reaction } = action.payload;

  try {
    const response = yield call(updateReactionApi, chatDetailId, reply_reaction);
    // console.log('handleUpdateReaction: Response:', response);

    const { chatDetail, updateData } = response.data;
    
    yield put(editCurrentChatDetails({
      chat_id: chatDetail.chat_id,
      chat_detail_id: chatDetail.id,
      updatedData: updateData
    }));

  } catch (error) {
    console.error('handleUpdateReaction Saga Error:', error);
  }
}

function* handleUpdateImageSatisfaction(action) {
  console.log('chatSagas: handleUpdateImageSatisfaction', action);

  const { chatDetailId, image_satisfaction } = action.payload;

  try {
    const response = yield call(updateImageSatisfactionApi, chatDetailId, image_satisfaction);
    // console.log('handleUpdateImageSatisfaction: Response:', response);

    const { chatDetail, updateData } = response.data;
    
    yield put(editCurrentChatDetails({
      chat_id: chatDetail.chat_id,
      chat_detail_id: chatDetail.id,
      updatedData: updateData
    }));

  } catch (error) {
    console.error('handleUpdateImageSatisfaction Saga Error:', error);
  }
}

function* handleInsertSmartScanKeyword(chat_id, action) {
  console.log('chatSagas: handleInsertSmartScanKeyword');

  try {
    const response = yield call(uploadSmartScanKeyword, chat_id);
    // console.log('Response:', response);
    
    yield put(editCurrentChatData({ field: 'product_keyword', value: '' }));

  } catch (error) {
    console.error('handleUpdateReaction Saga Error:', error);
  }
}

function* handleResetSmartScan(chat_id) {
  console.log('chatSagas: handleResetSmartScan');

  try {
    const response = yield call(resetSmartScanApi, chat_id);
    // console.log('Response:', response);
    
    yield put(editCurrentChatData({ field: 'marketing_obj', value: '' }));
    yield put(editCurrentChatData({ field: 'ads_location', value: '' }));
    yield put(editCurrentChatData({ field: 'product_keyword', value: '' }));

  } catch (error) {
    console.error('handleUpdateReaction Saga Error:', error);
  }
}

function* handleStorePrompt(action) {
  console.log('chatSagas: handleStorePrompt', action);

  const { chatId, promptData } = action.payload;

  //set temporary prompt if not empty
  const now = moment();
  yield put(setTempPrompt({ 
    chat_id: chatId, 
    temp_prompt: promptData.prompt ?? '', 
    prompt_time: now.format('hh.mm A'),
  }));
  yield put(setChatLoader({ 
    chat_id: chatId, 
    isLoading: true, 
    isListening: false, 
    message: '',//`${i18n.t("Just a moment! We're getting everything ready for you. Thanks for hanging tight!")}` 
  }));
  try{
    const stageList = yield select(selectChatStage, chatId);
    const stageData = stageList[chatId];
    console.log('handleStorePrompt: stageData', stageData)

    let stage = stageData.stage;
    if(stageData.subStage === 'generate_copy' || stageData.subStage === 'generate_adslib_image' || stageData.subStage === 'initial_copy') {
      stage = '';
    }

    let updatedPromptData = {
      ...promptData,
      stage: stage,
    };

    switch (stageData.stage) {
      case '1': //Initial
      
        if (stageData.subStage === 'smartscan_budget'){
          let budget = promptData.prompt;
      
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: budget,
          }
          
        }
        if (stageData.subStage === 'smartscan_reach'){
          let reach = promptData.prompt;
      
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: reach,
          }
          
        }
        if (stageData.subStage === 'smartscan_keyword'){
          let product_keyword = promptData.prompt;
      
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: product_keyword,
          }
        }
        
        if (stageData.subStage === 'adsclone_location') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: promptData.prompt,
          };
        } else if (stageData.subStage === 'adsclone_keyword') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };
        } else if (stageData.subStage === 'adsclone_fb_url') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };
        // } else if (stageData.subStage === 'adsclone_generate_copy'){
        //   updatedPromptData = {
        //     ...updatedPromptData,
        //     editedPrompt: stageData.initial_prompt,
        //   };
        } else if (stageData.subStage === 'brandboost_location') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: promptData.prompt,
          };
        } else if(stageData.subStage === 'brandboost_keyword') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };
        } else if(stageData.subStage === 'brandboost_budget') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };
        } else if(stageData.subStage === 'copycraft_prompt') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };
        } else if(stageData.subStage === 'visualads_new_image') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };
        } else if(stageData.subStage === 'visualads_change_bg') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };
        } else if(stageData.subStage === 'visualads_inpainting_mask') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };
        } else if(stageData.subStage === 'visualads_inpainting') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };
        } else if(stageData.subStage === 'visualads_describe') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };
        } else if(stageData.subStage === 'sketchspark_keyword') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: '',
          };

        } if (stageData.subStage === 'generate_copy') {
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: stageData.initial_prompt,
          };
        } else if (stageData.subStage === 'generate_adslib_image') {
          //get chat details
          const chatByIdResponse = yield call(getChatByIdApi, chatId);
          const { chat } = chatByIdResponse.data;
          console.log('handleStorePrompt: chatByIdResponse', chatByIdResponse);

          //get image prompt
          let prompt_request = 'Write me a prompt for image generation on a '+chat.product_keyword+' product, location in '+chat.ads_location+
            ', brand industry '+chat.chat_brand_business_type+', brand color '+chat.chat_brand_colors+
            '. Give suggestion on the scenario '+stageData.selected_copy+', this is marketing image , to post in social media, only need visual in the image , only return me in text form will do , no listing , no quote , no description';
          let image_prompt = yield call(handleChatbotGeneral, chatId, prompt_request);
          
          image_prompt = 'Design a '+chat.platform+' post. Remove references to personalities, brands, and text-generating elements. '+image_prompt;
          
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: image_prompt,
          };
        } else if (stageData.subStage === 'initial_copy') {
          //get chat details
          const chatByIdResponse = yield call(getChatByIdApi, chatId);
          const { chat } = chatByIdResponse.data;
          console.log('handleStorePrompt: chatByIdResponse', chatByIdResponse);

          let strategy = stageData.subStageOption;
          if(stageData.subStageOption === 'custom strategy'){
            strategy = promptData.prompt;
          } 
          
          const edited_prompt = "Generate a strategize copywriting based on '"+strategy+"' to align with brand name, '"+chat.chat_brand_name+"' and the business type is '"+chat.chat_brand_business_type+
            "'. The product is '"+chat.product_keyword+"'.The advertisement location is in '"+chat.ads_location+"'.";
          
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: edited_prompt,
          };

        } else if(stageData.subStage === 'initial_prompt') {
          // Proofread API
          const proofreadResponse = yield call(aiProofreaderApi, promptData.prompt);
          console.log('handleStorePrompt: proofreadResponse', proofreadResponse);

          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: proofreadResponse.data.corrected_text ?? '',
          };

          // Translator API
          const translatorResponse = yield call(aiTranslatorApi, updatedPromptData.editedPrompt);
          console.log('handleStorePrompt: translatorResponse', translatorResponse);
          const translatedToData = translatorResponse.data.to;

          const nextApiResponse = yield call(getNextApi, translatedToData.text.value ?? '');
          console.log('handleStorePrompt: nextApiResponse', nextApiResponse);
          const reponseNextApiData = nextApiResponse.data.next_api_data;
  
          updatedPromptData = {
            ...updatedPromptData, // Spread the already updated prompt data
            translatedPrompt: reponseNextApiData.prompt ?? '',
            translationLanguage: translatedToData.language.iso ?? [],
            promptApiCalled: reponseNextApiData.nextApi ?? process.env.REACT_APP_MARVIS_CHATBOT,
            promptScore: reponseNextApiData.score ?? 0,
          };
        } else if (stageData.subStage === 'image_to_copy_prompt') {
          updatedPromptData = {
            ...updatedPromptData,
            promptApiCalled: process.env.REACT_APP_MARVIS_CHATBOT,
            editedPrompt: 'Based on this image description "'+stageData.selected_image_desc+'" and this text description "'+promptData.prompt+'", generate a strategized copywriting.',
          };
        } else if (stageData.subStage === 'selected_copy_addon') {
          updatedPromptData = {
            ...updatedPromptData,
            promptApiCalled: process.env.REACT_APP_MARVIS_CHATBOT,
            edited_prompt: promptData.prompt,
            // editedPrompt: 'Based on this copy "'+stageData.selected_copy+'" and this text description "'+promptData.prompt+'", generate a strategized copywriting.',
          };
        } else if (stageData.subStage === 'selected_copy_addon_strategy') {
          updatedPromptData = {
            ...updatedPromptData,
            promptApiCalled: process.env.REACT_APP_MARVIS_CHATBOT,
            edited_prompt: promptData.prompt,
          };
        } else if (stageData.subStage === 'selected_copy_addon_style') {
          updatedPromptData = {
            ...updatedPromptData,
            promptApiCalled: process.env.REACT_APP_MARVIS_CHATBOT,
            editedPrompt: 'Write me a strategize copywriting '+stageData.subStageOption+' on my copywriting "'+stageData.selected_copy+'" and this text description "'+stageData.initial_prompt+'" in style of "'+stageData.initial_reply+'"'
          };
        } else if (stageData.subStage === 'ads_suggestion_strategy') {
          updatedPromptData = {
            ...updatedPromptData,
            promptApiCalled: process.env.REACT_APP_MARVIS_CHATBOT,
            edited_prompt: promptData.prompt,
          };
        }
        break;
      case '2': //Chat
        let edited_prompt = '';
        if(stageData.subStage === 'strategy') {
          if(stageData.subStageOption === 'custom strategy'){
            //combine initial_prompt + current prompt
            edited_prompt = stageData.initial_reply + ' ' + promptData.prompt;
          } else { // options: driving conversion, consultancy, time_limited_offer, discounts
            //edited_prompt = write me a strategize copywriting based on {stageData.subStageOption.value } on my copywriting {initial_reply}
            const strategy = targetOptionsList.copywriting_strategy.find(option => option.id === stageData.subStageOption);
            const strategyValue = strategy ? strategy.value:'';
            edited_prompt = 'write me a strategize copywriting based on '+strategyValue+' on my copywriting '+stageData.initial_reply;
          }
          
        } else if (stageData.subStage === 'revise') {
          edited_prompt = promptData.prompt;
        }
        updatedPromptData = {
          ...updatedPromptData,
          editedPrompt: edited_prompt,
          promptApiCalled: process.env.REACT_APP_MARVIS_CHATBOT,
        };
        break;
      case '3': //Image
        if(stageData.subStage === 'initial_image'){
          if(stageData.status === 'start'){
            // Proofread API
            const proofreadResponse = yield call(aiProofreaderApi, promptData.prompt);
            console.log('handleStorePrompt: proofreadResponse', proofreadResponse);

            updatedPromptData = {
              ...updatedPromptData,
              editedPrompt: proofreadResponse.data.corrected_text ?? '',
            };

            // Translator API
            const translatorResponse = yield call(aiTranslatorApi, updatedPromptData.editedPrompt);
            console.log('handleStorePrompt: translatorResponse', translatorResponse);
            const translatedToData = translatorResponse.data.to;

            const nextApiResponse = yield call(getNextApi, translatedToData.text.value ?? '');
            console.log('handleStorePrompt: nextApiResponse', nextApiResponse);
            const reponseNextApiData = nextApiResponse.data.next_api_data;

            updatedPromptData = {
              ...updatedPromptData, // Spread the already updated prompt data
              translatedPrompt: reponseNextApiData.prompt ?? '',
              translationLanguage: translatedToData.language.iso ?? [],
              promptApiCalled: reponseNextApiData.nextApi ?? process.env.REACT_APP_MARVIS_CHATBOT,
              promptScore: reponseNextApiData.score ?? 0,
            };
          } else if (stageData.status === 'ongoing') { //initial_image + ongoing
            let edited_prompt = promptData.prompt;

            if (stageData.image_size !== '' && stageData.image_style !== '') {
              let copy = stageData.initial_prompt;
              if(stageData.selected_copy !== ''){
                copy = stageData.selected_copy;
              }
              //get chat details
              const chatByIdResponse = yield call(getChatByIdApi, chatId);
              const { chat } = chatByIdResponse.data;
              console.log('handleStorePrompt: chatByIdResponse', chatByIdResponse);

              //get image prompt
              let prompt_request = 'Write me a prompt for image generation on a '+chat.product_keyword+' product, location in '+chat.ads_location+
                ', brand industry '+chat.chat_brand_business_type+', brand color '+chat.chat_brand_colors+
                '. Give suggestion on the scenario '+copy+', this is marketing image , to post in social media, only need visual in the image , only return me in text form will do , no listing , no quote , no description';
              let image_prompt = yield call(handleChatbotGeneral, chatId, prompt_request);
              
              edited_prompt = 'Design a '+chat.platform+' post, '+stageData.image_stylet+' style. Remove references to personalities, brands, and text-generating elements. '+image_prompt;
              
            } else {
              //from any other: do nothing
            }
          
            updatedPromptData = {
              ...updatedPromptData,
              editedPrompt: edited_prompt,
              promptApiCalled: process.env.REACT_APP_MARVIS_IMAGE_FLUX,
            };
          }
        } else if (stageData.subStage === 'recreate_image') {
          let style_text = '';
          if(stageData.image_style !== ''){
            style_text = ', '+stageData.image_style+' style';
          }

          let copy = stageData.selected_copy+' and considering '+promptData.prompt;

          //get chat details
          const chatByIdResponse = yield call(getChatByIdApi, chatId);
          const { chat } = chatByIdResponse.data;
          console.log('handleStorePrompt: chatByIdResponse', chatByIdResponse);

          //get image prompt
          let prompt_request = 'Write me a prompt for image generation on a '+chat.product_keyword+' product, location in '+chat.ads_location+
            ', brand industry '+chat.chat_brand_business_type+', brand color '+chat.chat_brand_colors+
            '. Give suggestion on the scenario '+copy+', this is marketing image , to post in social media, only need visual in the image , only return me in text form will do , no listing , no quote , no description';
          let image_prompt = yield call(handleChatbotGeneral, chatId, prompt_request);
          
          image_prompt = 'Design a '+chat.platform+' post'+style_text+'. Remove references to personalities, brands, and text-generating elements. '+image_prompt;
          
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: image_prompt,
          };
        } else if (stageData.subStage === 'regenerate_image') {
          let style_text = '';
          if(stageData.image_style !== ''){
            style_text = ', '+stageData.image_style+' style';
          }

          let copy = stageData.selected_copy;
          if(stageData.initial_prompt !== ''){
            copy = stageData.initial_prompt;
          }

          //get chat details
          const chatByIdResponse = yield call(getChatByIdApi, chatId);
          const { chat } = chatByIdResponse.data;
          console.log('handleStorePrompt: chatByIdResponse', chatByIdResponse);

          //get image prompt
          let prompt_request = 'Write me a prompt for image generation on a '+chat.product_keyword+' product, location in '+chat.ads_location+
            ', brand industry '+chat.chat_brand_business_type+', brand color '+chat.chat_brand_colors+
            '. Give suggestion on the scenario '+copy+', this is marketing image , to post in social media, only need visual in the image , only return me in text form will do , no listing , no quote , no description';
          let image_prompt = yield call(handleChatbotGeneral, chatId, prompt_request);
          
          image_prompt = 'Design a '+chat.platform+' post'+style_text+'. Remove references to personalities, brands, and text-generating elements. '+image_prompt;
          
          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: image_prompt,
          };
        } else if (stageData.subStage === 'edit_image_part_mask') {
          //do nothing
        } else if (stageData.subStage === 'edit_image_part_inpainting') {
          //do nothing
        } else if (stageData.subStage === 'edit_image_part_background') {
          //do nothing
        } else if (stageData.subStage === 'selected_image_revise') {
          let edited_prompt = promptData.prompt;

          if (stageData.image_size !== '' && stageData.image_style !== '') {
            let style_text = '';
            if(stageData.image_style !== ''){
              style_text = ', '+stageData.image_style+' style';
            }

            //get chat details
            const chatByIdResponse = yield call(getChatByIdApi, chatId);
            const { chat } = chatByIdResponse.data;
            console.log('handleStorePrompt: chatByIdResponse', chatByIdResponse);

            //get image prompt
            let prompt_request = 'Write me a prompt for image generation on a '+chat.product_keyword+' product, location in '+chat.ads_location+
              ', brand industry '+chat.chat_brand_business_type+', brand color '+chat.chat_brand_colors+
              '. Give suggestion on the scenario '+promptData.prompt+', this is marketing image , to post in social media, only need visual in the image , only return me in text form will do , no listing , no quote , no description';
            let image_prompt = yield call(handleChatbotGeneral, chatId, prompt_request);
            
            edited_prompt = 'Design a '+chat.platform+' post'+style_text+'. Remove references to personalities, brands, and text-generating elements. '+image_prompt;
            
          }

          updatedPromptData = {
            ...updatedPromptData,
            editedPrompt: edited_prompt,
            promptApiCalled: process.env.REACT_APP_MARVIS_IMAGE_FLUX,
          };
        }
        break;
      default:
        break;
    }

    console.log('handleStorePrompt: updatedPromptData', updatedPromptData);

    // Store prompt API
    const promptReponse = yield call(storePromptApi, chatId, updatedPromptData);
    const { chatDetail, chat_memory, sufficient_credit } = promptReponse.data;
    console.log('handleStorePrompt: promptReponse', promptReponse);
    console.log('handleStorePrompt: stageData', stageData)

    // Add new chat detail
    yield put(appendCurrentChatDetails(Array.isArray(chatDetail) ? chatDetail : [chatDetail]));
    yield put(setTempPrompt({ 
      chat_id: chatId, 
      temp_prompt: '', 
      prompt_time: '', 
    }));
    
    yield put(setBottomRefTrigger(Date.now()));

    // update last activtime
    yield put(editCurrentChatData({ field: 'last_active_datetime', value: chatDetail.updated_at }));

    // Append chat memory if any
    if(chat_memory) {
      yield put(appendChatMemory({
        chat_id: chatId,
        chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
      }));
    }

    yield delay(100); // Optional delay

    if (stageData.subStage === 'smartscan_budget'){
      yield put(editChatStage({
        chat_id: chatId,
        updates: {
          stage: '1',
          subStage: 'smartscan_reach',
        }
      }));
  
      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatId, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("What is your budget?")}`,
            reply_role: 'assistant' 
          },
          isSuccess: true,
        }
      });
    }

    if (stageData.subStage === 'smartscan_reach'){
      yield put(editChatStage({
        chat_id: chatId,
        updates: {
          stage: '1',
          subStage: 'smartscan_graph',
        }
      }));
  
      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatId, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("What is your estimated reach?")}`,
            reply_role: 'assistant' 
          },
          isSuccess: true,
        }
      });
    }

    if (stageData.subStage === 'smartscan_graph'){
      yield put(setChatSelection({
        chat_id: chatId, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: beforeInitial.smartscan_graph,
        title: `${i18n.t("This is our analytics based on your company product")}`,
        options: beforeInitialOptions.smartscan_graph,
        animationClass: 'slide-up'
      }));
    }

    if (sufficient_credit) {
      switch (stageData.stage) { 
        case '1': //Initial
          if(stageData.status === 'complete') {
            if(stageData.stage === '1' && stageData.subStage === 'initial_prompt' && chatDetail.prompt_score > 0){
              if (chatDetail.prompt_api_called === process.env.REACT_APP_MARVIS_CHATBOT || chatDetail.prompt_api_called === process.env.REACT_APP_MARVIS_IMAGE_FLUX) {
                yield put(setChatLoader({ 
                  chat_id: chatDetail.chat_id, 
                  isLoading: false, 
                  isListening: false, 
                  message: "" 
                }));
      
                yield put(setChatSelection({ 
                  chat_id: chatDetail.chat_id, 
                  chat_detail_id: chatDetail.id, 
                  isDisplay: true, 
                  target: targetList.revise_complete_ads,
                  title: "It seems like you are starting a new advertisement for another product, as your digital marketing assistant. I would advise you to 'start a new chat' for each product. Or do you want to revise anything from our discussion so far?",
                  options: targetOptionsList.revise_complete_ads,
                  animationClass: 'slide-up'
                }));
              }
            } else { //if is normal chat
              yield call(handleChatbot, chatDetail);
            }
          } else {
            if(stageData.subStage === 'adsclone_location') {
        
              //update keyword to db
              yield call(updateAdsLocationApi, chatId, stageData.ads_location);
              
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  stage: '1',
                  subStage: 'adsclone_keyword',
                }
              }));

              //store reply
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chatId, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("Can you provide me the keyword you wish to search on ?")}`,
                    reply_role: 'system' 
                  },
                  isSuccess: true,
                }
              });
            } else if(stageData.subStage === 'brandboost_location') {
        
              //update keyword to db
              yield call(updateAdsLocationApi, chatId, stageData.ads_location);
              
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  stage: '1',
                  subStage: 'brandboost_keyword',
                }
              }));

              //store reply
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chatId, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("What product would you like to focus on for your ad campaign?")}`,
                    reply_role: 'system' 
                  },
                  isSuccess: true,
                }
              });
            } else if(stageData.subStage === 'brandboost_keyword') {
              //get chat details
              // const chatByIdResponse = yield call(getChatByIdApi, chatId);
              // const { chat } = chatByIdResponse.data;
              // console.log('handleStorePrompt: chatByIdResponse', chatByIdResponse);

              //get keyword prompt
              // let prompt_request = 'Give me an accurate keyword for my product '+promptData.prompt+
              // ', my business type is '+ chat.chat_brand_business_type + ' in '+ chat.ads_location +
              // '. just specify the product, must fit facebook keywords. give me 1 keywords and only give me the 1 keywords.';
              let prompt_request = 'Based on my keyword: '+promptData.prompt+
              '. Let me know if it belongs to which industry with a single word. It must fit facebook keywords. give me 1 keywords and only give me 1 word keywords.';
              
              let product_keyword = yield call(handleChatbotGeneral, chatId, prompt_request);
              console.log('handleStorePrompt: prompt_request keyword', product_keyword);

              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  stage: '1',
                  subStage: 'brandboost_scan',
                }
              }));

              yield put(editBrandBoostDetail({
                chat_id: chatId,
                updates: {
                  keyword_approach: 'prompt',
                  keyword: chatDetail.prompt,
                  facebook_keyword: product_keyword,
                }
              }));
              yield call(handleBrandBoost, chatId);
            } else if(stageData.subStage === 'brandboost_budget') {
              yield put(editBrandBoostDetail({
                chat_id: chatId,
                updates: {
                  budget: promptData.prompt,
                }
              }));
              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chatId, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("Please hold on for a moment while we gather and compile the predictive insights for your ads.")}`,
                    reply_role: 'system',
                  },
                  isSuccess: true,
                }
              });
              yield put(setChatLoader({ 
                chat_id: chatId, 
                isLoading: true, 
                isListening: false, 
                message: "" 
              }));
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  stage: '1',
                  subStage: 'brandboost_summary',
                }
              }));
              yield call(handleBrandBoostSummary, chatId);
            } else if(stageData.subStage === 'copycraft_prompt') {
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  stage: '1',
                  subStage: 'copycraft_prompt_gen',
                  adsclone_image_desc: promptData.prompt
                }
              }));
              yield call(handleCreateMoreCopyCraft,{ 
                payload: { 
                  chat_id: chatId, 
                }
              });
            } else if(stageData.subStage === 'visualads_new_image') {
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  initial_prompt: promptData.prompt,
                  adsclone_image_desc: promptData.prompt,
                }
              }));
              yield call(handleVACreateNewImage, chatId, promptData.prompt);
            } else if(stageData.subStage === 'visualads_change_bg') {
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  initial_prompt: promptData.prompt,
                }
              }));

              let image_url = (stageData.adsclone_image_url !== '') ? getFullImageUrl(stageData.adsclone_image_url) : '' ;
              // if(stageData.selected_image_url !== ''){
              //   image_url = getFullImageUrl(stageData.selected_image_url);
              // }
              yield call(handleVAChangeBackground, chatId, promptData.prompt, image_url);
            } else if(stageData.subStage === 'visualads_inpainting_mask') {
              yield call(handleVAMasking, chatId, promptData.prompt);
            } else if(stageData.subStage === 'visualads_inpainting') {
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  initial_prompt: promptData.prompt,
                }
              }));
              yield call(handleVAInpainting, chatId, promptData.prompt);
            } else if(stageData.subStage === 'visualads_describe') {
              let prompt = stageData.adsclone_image_desc + ' and '+ promptData.prompt
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  initial_prompt: prompt,
                  subStage: 'visualads_new_image'
                }
              }));
              yield call(handleVACreateNewImage, chatId, prompt);
            } else if(stageData.subStage === 'sketchspark_keyword') {
              yield call(updateProductKeywordApi, chatId, promptData.prompt);
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  product_keyword: promptData.prompt,
                  subStage: 'sketchspark',
                }
              }));
              yield call(handleSketchSpark, chatId);

            } else if(stageData.subStage === 'adsclone_keyword') {
              //get chat details
              const chatByIdResponse = yield call(getChatByIdApi, chatId);
              const { chat } = chatByIdResponse.data;
              console.log('handleStorePrompt: chatByIdResponse', chatByIdResponse);

              //get keyword prompt
              let prompt_request = 'Give me an accurate keyword for my product '+promptData.prompt+
              ', my business type is '+ chat.chat_brand_business_type + ' in '+ chat.ads_location +
              '. just specify the product, must fit facebook keywords. give me 1 keywords and only give me the 1 keywords';
              // let prompt_request = 'Give me an accurate keyword for my product '+promptData.prompt+
              // ', my business type is '+ chat.chat_brand_business_type + ' in '+ chat.ads_location +
              // '. just specify the product, must fit facebook keywords. give me 1 keywords and only give me the 1 keywords with double quote and escape function';

              let product_keyword = yield call(handleChatbotGeneral, chatId, prompt_request);
              console.log('handleStorePrompt: prompt_request keyword', product_keyword);

              //update keyword to db
              yield call(updateProductKeywordApi, chatId, product_keyword);

              yield call( handleStoreReplyGeneral, { 
                payload: { 
                  chatId: chatId, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("How would you like to create your winning ads ?")}`,
                    reply_role: 'system',
                  },
                  isSuccess: true,
                }
              });
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  stage: '1',
                  subStage: 'adsclone_method',
                }
              }));
              yield put(setChatSelection({
                chat_id: chatDetail.chat_id, 
                chat_detail_id: '', 
                isDisplay: true, 
                target: targetList.adsclone_method,
                title: "",
                options: targetOptionsList.adsclone_method,
                animationClass: 'slide-up'
              }));
              
            } else if (stageData.subStage === 'adsclone_fb_url') {
              //call get ads info from fb url
              yield call(handleGetFBAdsInfoFromUrl, chatId, chatDetail.prompt);
            // } else if (stageData.subStage === 'adsclone_generate_copy'){
            //   yield call(handleChatbot, chatDetail);
            } else if(stageData.subStage === 'generate_copy') {
              yield call(handleChatbot, chatDetail);
            } else if (stageData.subStage === 'generate_adslib_image') {
              yield call(handleTextToImage, chatDetail);
            } else if (stageData.subStage === 'initial_copy') {
              yield call(handleChatbot, chatDetail);
            } else if(stageData.subStage === 'initial_prompt') {

              if(stageData.status !== 'complete'){
                //set initial prompt
                yield put(editChatStage({
                  chat_id: chatId,
                  updates: {
                    initial_prompt: chatDetail.edited_prompt,
                    chat_detail_id: chatDetail.id,
                    status: 'ongoing'
                  }
                }));
              }

              //condition to call either chatbot / textToImage api
              if (chatDetail.prompt_api_called === process.env.REACT_APP_MARVIS_IMAGE_FLUX){
                yield put(editChatStage({
                  chat_id: chatId,
                  updates: {
                    stage: '3',
                    subStage: 'initial_image',
                    initial_prompt: chatDetail.edited_prompt,
                    chat_detail_id: chatDetail.id
                  }
                }));

                if(stageData.selected_image_url !== ''){ // if have selected image
                  yield put(setChatLoader({ 
                    chat_id: chatDetail.chat_id, 
                    isLoading: false, 
                    isListening: false, 
                    message: "" 
                  }));
                  yield put(setChatSelection({ 
                    chat_id: chatDetail.chat_id, 
                    chat_detail_id: chatDetail.id, 
                    isDisplay: true, 
                    target: targetList.revise_complete_ads,
                    title: "It seems like you are starting a new advertisement for another product, as your digital marketing assistant. I would advise you to 'start a new chat' for each product. Or do you want to revise anything from our discussion so far?",
                    options: targetOptionsList.revise_complete_ads,
                    animationClass: 'slide-up'
                  }));
                } else { //no selected image 
                  yield put(setChatLoader({ 
                    chat_id: chatDetail.chat_id, 
                    isLoading: false, 
                    isListening: false, 
                    message: "" 
                  }));
                  yield put(setChatSelection({ 
                    chat_id: chatDetail.chat_id, 
                    chat_detail_id: chatDetail.id, 
                    isDisplay: true, 
                    target: targetList.image_sizes,
                    title: 'Tell me what is your image size',
                    options: targetOptionsList.image_sizes,
                    animationClass: 'slide-up'
                  }));
                }
                
              } else {
                yield call(handleChatbot, chatDetail);
              }
            } else if (stageData.subStage === 'image_to_copy_prompt') {
              yield call(handleChatbot, chatDetail);
            } else if (stageData.subStage === 'selected_copy_addon') {
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  initial_prompt: chatDetail.edited_prompt,
                }
              }));
              yield put(setChatLoader({ 
                chat_id: chatId, 
                isLoading: false, 
                isListening: false, 
                message: "" 
              }));
              yield put(setChatSelection({ 
                chat_id: chatId, 
                chat_detail_id: chatDetail.id, 
                isDisplay: true, 
                target: targetList.copywriting_strategy_revise,
                title: 'What kind of strategy you would like?',
                options: targetOptionsList.copywriting_strategy_revise,
                animationClass: 'slide-up'
              }));
            } else if (stageData.subStage === 'selected_copy_addon_strategy') {

              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  subStageOption: 'based on "'+chatDetail.edited_prompt+'"',
                }
              }));
              yield put(setChatLoader({ 
                chat_id: chatDetail.chat_id, 
                isLoading: false, 
                isListening: false, 
                message: "" 
              }));
              yield put(setChatSelection({ 
                chat_id: chatDetail.chat_id, 
                chat_detail_id: chatDetail.id, 
                isDisplay: true, 
                target: targetList.copywriting_style_revise,
                title: 'What kind of copywriting style you would like?',
                options: targetOptionsList.copywriting_style_revise,
                animationClass: 'slide-up'
              }));
            } else if (stageData.subStage === 'selected_copy_addon_style') {
              yield call(handleChatbot, chatDetail);
            } else if (stageData.subStage === 'ads_suggestion_strategy') {
              yield put(editChatStage({
                chat_id: chatId,
                updates: {
                  subStageOption: 'based on "'+chatDetail.edited_prompt+'"',
                }
              }));
              yield put(setChatLoader({ 
                chat_id: chatId, 
                isLoading: true, 
                isListening: false, 
                message: `${i18n.t("Please hold on! We're working on creating more ads for you.")}`
              }));
      
              yield put(setBottomRefTrigger(Date.now()));
              yield call( handleCreateMoreAds, {payload: {chat_id: chatDetail.id}});
            }
          }
          break;
        case '2': //Chat
          if(stageData.status === 'complete') {
            yield call(handleChatbot, chatDetail);
          } else {
            if(stageData.subStage === 'strategy' || stageData.subStage === 'revise') {
              yield call(handleChatbot, chatDetail);
            }
          }
          break;
        case '3': //Image
          if(stageData.subStage === 'initial_image') {
            yield put(editChatStage({
              chat_id: chatId,
              updates: {
                initial_prompt: chatDetail.edited_prompt,
                chat_detail_id: chatDetail.id
              }
            }));

            if(stageData.status === 'start') { // initial_image + start
              if(chatDetail.prompt_score > 0.5 && chatDetail.prompt_api_called === process.env.REACT_APP_MARVIS_IMAGE_FLUX) {
                yield put(setChatLoader({ 
                  chat_id: chatDetail.chat_id, 
                  isLoading: false, 
                  isListening: false, 
                  message: "" 
                }));
                yield put(setChatSelection({ 
                  chat_id: chatDetail.chat_id, 
                  chat_detail_id: chatDetail.id, 
                  isDisplay: true, 
                  target: targetList.image_sizes,
                  title: 'Tell me what is your image size',
                  options: targetOptionsList.image_sizes,
                  animationClass: 'slide-up'
                }));
              } else {
                yield put(setChatLoader({ 
                  chat_id: chatDetail.chat_id, 
                  isLoading: false, 
                  isListening: false, 
                  message: "" 
                }));
                yield put(setChatSelection({ 
                  chat_id: chatDetail.chat_id, 
                  chat_detail_id: chatDetail.id, 
                  isDisplay: true, 
                  target: targetList.generate_image,
                  title: 'Do you want to generate image based on the above content?',
                  options: targetOptionsList.generate_image,
                  animationClass: 'slide-up'
                }));
              }
              
            } else if (stageData.status === 'ongoing') { //initial image + ongoing

              if(stageData.selected_image_url !== ''){ //have selected image
                yield put(setChatLoader({ 
                  chat_id: chatDetail.chat_id, 
                  isLoading: false, 
                  isListening: false, 
                  message: "" 
                }));
                yield put(setChatSelection({ 
                  chat_id: chatDetail.chat_id, 
                  chat_detail_id: chatDetail.id, 
                  isDisplay: true, 
                  target: targetList.revise_complete_ads,
                  title: "It seems like you are starting a new advertisement for another product, as your digital marketing assistant. I would advise you to 'start a new chat' for each product. Or do you want to revise anything from our discussion so far?",
                  options: targetOptionsList.revise_complete_ads,
                  animationClass: 'slide-up'
                }));
              } else if (stageData.image_size !== '' && stageData.image_style !== ''){  //have size and style
                yield put(setChatLoader({ 
                  chat_id: chatDetail.chat_id, 
                  isLoading: true, 
                  isListening: false, 
                  message: `${i18n.t("Hang tight! Your image is on its way!")}`
                }));
                yield call(handleTextToImage, chatDetail);

                
              } else if ( stageData.image_size === ''){
                yield put(setChatLoader({ 
                  chat_id: chatDetail.chat_id, 
                  isLoading: false, 
                  isListening: false, 
                  message: "" 
                }));
                yield put(setChatSelection({ 
                  chat_id: chatDetail.chat_id, 
                  chat_detail_id: chatDetail.id, 
                  isDisplay: true, 
                  target: targetList.image_sizes,
                  title: 'Tell me what is your image size',
                  options: targetOptionsList.image_sizes,
                  animationClass: 'slide-up'
                }));
              } else if (stageData.image_style === '') {
                yield put(setChatLoader({ 
                  chat_id: chatDetail.chat_id, 
                  isLoading: false, 
                  isListening: false, 
                  message: "" 
                }));
                yield put(setChatSelection({ 
                  chat_id: chatDetail.chat_id, 
                  chat_detail_id: chatDetail.id, 
                  isDisplay: true, 
                  target: targetList.image_styles,
                  title: 'Tell me what kind of style you want your image to be like?',
                  options: targetOptionsList.image_styles,
                  animationClass: 'slide-up'
                }));
              }
            }
          } else if (stageData.subStage === 'recreate_image') {
            yield call(handleTextToImage, chatDetail);
          } else if (stageData.subStage === 'regenerate_image') {
            yield call(handleTextToImage, chatDetail);
          } else if (stageData.subStage === 'edit_image_part_mask') {
            yield call(handleMasking, chatDetail);
          } else if (stageData.subStage === 'edit_image_part_inpainting') {
            yield call(handleInpainting, chatDetail);
          } else if (stageData.subStage === 'edit_image_part_background') {
            yield call(handleChangeBackground, chatDetail);
          } else if (stageData.subStage === 'selected_image_revise'){
            yield put(editChatStage({
              chat_id: chatId,
              updates: {
                initial_prompt: chatDetail.edited_prompt,
                chat_detail_id: chatDetail.id
              }
            }));

            if (stageData.image_size !== '' && stageData.image_style !== '') {
              yield put(setChatLoader({ 
                chat_id: chatDetail.chat_id, 
                isLoading: true, 
                isListening: false, 
                message: `${i18n.t("Hang tight! Your image is on its way!")}`
              }));
              yield call(handleTextToImage, chatDetail);
            } else {
              yield put(setChatLoader({ 
                chat_id: chatDetail.chat_id, 
                isLoading: false, 
                isListening: false, 
                message: "" 
              }));

              yield put(setChatSelection({ 
                chat_id: chatDetail.chat_id, 
                chat_detail_id: chatDetail.id, 
                isDisplay: true, 
                target: targetList.image_sizes,
                title: 'Tell me what is your image size',
                options: targetOptionsList.image_sizes,
                animationClass: 'slide-up'
              }));
            }
          }
          break;
        default:
          break;
      }
    } else {
      // disable submit buttons due to insufficient credits
      yield put(setIsSufficientCredit(false));
      // show error reply
      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: chatDetail.id,
            reply: `${i18n.t("errors.INSUFFICIENT_BALANCE")}`, 
            reply_role: 'system',
          },
          isSuccess: false,
        }
      });
      // scroll to bottom of chat
      yield put(setBottomRefTrigger(Date.now()));
    }
  } catch (error) {
    console.error('handleStorePrompt Saga Error:', error);
    yield put(setChatLoader({
      chat_id: chatId,
      isLoading: false, 
      isListening: false, 
      message: ""
    }));

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatId, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleStorePromptGeneral(action) {
  console.log('chatSagas: handleStorePromptGeneral', action);

  const { chatId, promptData } = action.payload;

  //set temporary prompt if not empty
  const now = moment();
  yield put(setTempPrompt({ 
    chat_id: chatId, 
    temp_prompt: promptData.prompt ?? '', 
    prompt_time: now.format('hh.mm A'),
  }));

  try{

    let updatedPromptData = {
      ...promptData,
      stage: '',
    };

    console.log('handleStorePromptGeneral: updatedPromptData', updatedPromptData);

    // Store prompt API
    const promptReponse = yield call(storePromptApi, chatId, updatedPromptData);
    const { chatDetail, chat_memory, sufficient_credit } = promptReponse.data;
    console.log('handleStorePromptGeneral: promptReponse', promptReponse);

    // Add new chat detail
    yield put(appendCurrentChatDetails(Array.isArray(chatDetail) ? chatDetail : [chatDetail]));
    yield put(setTempPrompt({ 
      chat_id: chatId, 
      temp_prompt: '', 
      prompt_time: '', 
    }));
    yield put(setBottomRefTrigger(Date.now()));
    
    // update last activtime
    yield put(editCurrentChatData({ field: 'last_active_datetime', value: chatDetail.updated_at }));

    // Append chat memory if any
    if(chat_memory) {
      yield put(appendChatMemory({
        chat_id: chatId,
        chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
      }));
    }

    yield delay(100); // Optional delay

    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
    if (sufficient_credit) {
      return chatDetail;
    } else {
      // disable submit buttons due to insufficient credits
      yield put(setIsSufficientCredit(false));
      // show error reply
      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: chatDetail.id,
            reply: `${i18n.t("errors.INSUFFICIENT_BALANCE")}`, 
            reply_role: 'system',
          },
          isSuccess: false,
        }
      });
      // scroll to bottom of chat
      yield put(setBottomRefTrigger(Date.now()));
    }
  } catch (error) {
    console.error('handleStorePromptGeneral Saga Error:', error);
    yield put(setChatLoader({
      chat_id: chatId,
      isLoading: false, 
      isListening: false, 
      message: ""
    }));

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatId, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}
// handleStoreReply() function
function* handleStoreReply(action) {
  console.log('chatSagas: handleStoreReply', action);
  const { chatId, replyData, isSuccess } = action.payload;

  try {
    const chat_detail_id = replyData.chat_detail_id ?? '';
    const replyResponse = yield call(storeReplyApi, chatId, replyData);
    console.log('handleStoreReply: replyResponse', replyResponse);

    const { chatDetail, updateData, chat_memory } = replyResponse.data;

    //set prompt date and time
    const now = moment(); // Get current time
    const promptDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
    const promptTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

    if(chat_detail_id === ''){ //new details
      console.log('handleStoreReply: chatDetail', chatDetail);

      yield put(appendCurrentChatDetails(Array.isArray(chatDetail) ? chatDetail : [chatDetail]));
    } else { //existing details
      // Dispatch actions to update the state
      yield put(editCurrentChatDetails({
        chat_id: chatDetail.chat_id,
        chat_detail_id: chatDetail.id,
        updatedData: updateData,
      }));
    }

    yield put(editCurrentChatData({ field: 'last_active_datetime', value: updateData.updated_at }));
    yield put(setBottomRefTrigger(Date.now()));

    yield put(setChatLoader({ 
      chat_id: chatDetail.chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    })); 

    if(chat_memory) {
      yield put(appendChatMemory({
        chat_id: chatDetail.chat_id,
        chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
      }));
    }
    const stageList = yield select(selectChatStage, chatDetail.chat_id);
    const stageData = stageList[chatDetail.chat_id];
    console.log('handleStoreReply: stageData', stageData)

    if(isSuccess){
      switch (stageData.stage) {
        case '1':
          console.log(stageData.stage);
          if (stageData.subStage === 'adsclone_adslib'){
            yield put(setChatLoader({ 
              chat_id: chatId, 
              isLoading: true, 
              isListening: false, 
              message: ''
            }));
            yield call(handleAdsLibrary, chatId, '');
          // } else if (stageData.subStage === 'adsclone_generate_copy'){
          //   yield put(setChatLoader({ 
          //     chat_id: chatId, 
          //     isLoading: true, 
          //     isListening: false, 
          //     message: `${i18n.t("We are now generating the winning ads for you!")}` 
          //   }));
            
          } else if (stageData.subStage === 'adsclone_generate_selected_ads') {
            yield put(setChatSelection({ 
              chat_id: chatId, 
              chat_detail_id: '', 
              isDisplay: true, 
              target: targetList.adsclone_satisfaction,
              title: "",
              options: targetOptionsList.adsclone_satisfaction,
              animationClass: 'slide-up'
            }));
          } else if (chat_detail_id === '' && stageData.subStage === 'adslib') { //no ads provided by adslibrary
            yield put(setChatSelection({ 
              chat_id: chatDetail.chat_id, 
              chat_detail_id: chatDetail.id, 
              isDisplay: true, 
              target: beforeInitial.no_adslibrary_options,
              title: '',
              options: beforeInitial.no_adslibrary_options,
              animationClass: 'slide-up'
            }));
          } else if(stageData.subStage === 'select_adslib') {

            yield put(setChatLoader({ 
              chat_id: chatId, 
              isLoading: true, 
              isListening: false, 
              message: `${i18n.t("Just a moment! We're getting everything ready for you. Thanks for hanging tight!")}` 
            }));

            //get chat details
            const chatByIdResponse = yield call(getChatByIdApi, chatId);
            const { chat } = chatByIdResponse.data;
            console.log('handleStoreReply: chatByIdResponse', chatByIdResponse);

            const initial_prompt = "Generate a copywriting based on this copy '"+stageData.initial_prompt+"' but to fit with brand name, '"+chat.chat_brand_name+"' and the business type is '"+chat.chat_brand_business_type+"'. The advertisement location is in '"+chat.ads_location+"'.";
            yield put(editChatStage({
              chat_id: chatId,
              updates: {
                initial_prompt: initial_prompt,
                stage: '1',
                subStage: 'generate_copy',
              }
            }));

            yield call( handleStorePrompt, { 
              payload: { 
                chatId: chatId, 
                promptData: {
                  prompt: '',
                  promptDate: promptDate,
                  promptTime: promptTime
                }
              }
            });
          } else if(stageData.subStage === 'generate_copy') {

            yield put(setChatSelection({ 
              chat_id: chatDetail.chat_id, 
              chat_detail_id: chatDetail.id, 
              isDisplay: true, 
              target: beforeInitial.regenerate_copy,
              title: 'Would you prefer to regenerate this copy?',
              options: beforeInitialOptions.regenerate_copy,
              animationClass: 'slide-up'
            }));
          } else if(stageData.subStage === 'generate_adslib_image') {
            yield put(setChatSelection({ 
              chat_id: chatDetail.chat_id, 
              chat_detail_id: chatDetail.id, 
              isDisplay: true, 
              target: targetList.image_satisfaction,
              title: 'Does this look like what you had in mind?',
              options: targetOptionsList.image_satisfaction,
              animationClass: 'slide-up'
            }));
          } else if (stageData.subStage === 'initial_copy') {
            yield put(editChatStage({
              chat_id: chatDetail.chat_id,
              updates: {
                stage: '2',
                subStage: 'revise',
                subStageOption: '',
                initial_reply: '',
                initial_prompt: '',
                chat_detail_id: '',
              }
            }));

            yield put(setChatLoader({ 
              chat_id: chatDetail.chat_id, 
              isLoading: false, 
              isListening: false, 
              message: "" 
            }));

            yield put(setChatSelection({ 
              chat_id: chatDetail.chat_id, 
              isDisplay: true, 
              chat_detail_id: chatDetail.id,
              target: targetList.revise_strategy_copywriting,
              title: 'Would you like me to expand on specific sections or revise this further? Let me know!',
              options: targetOptionsList.revise_strategy_copywriting,
              animationClass: 'slide-up'
            }));

            // yield put(setChatSelection({ 
            //   chat_id: chatDetail.chat_id, 
            //   chat_detail_id: chatDetail.id, 
            //   isDisplay: true, 
            //   target: targetList.copywriting_strategy,
            //   title: 'Do you want me to provide a more strategize copywriting?',
            //   options: targetOptionsList.copywriting_strategy,
            //   animationClass: 'slide-up'
            // }));

            // yield put(editChatStage({
            //   chat_id: chatDetail.chat_id,
            //   updates: {
            //     stage: '2',
            //     subStage: 'strategy',
            //     subStageOption: '',
            //     chat_detail_id: chatDetail.id,
            //     initial_prompt: chatDetail.edited_prompt,
            //     initial_reply: chatDetail.edited_reply ?? '',
            //   }
            // }));
          } else if(chat_detail_id !== '' && stageData.subStage === 'initial_prompt'){
            if(chatDetail.prompt_score > 0 && chatDetail.prompt_api_called === process.env.REACT_APP_MARVIS_CHATBOT){
              yield put(setChatLoader({ 
                chat_id: chatDetail.chat_id, 
                isLoading: false, 
                isListening: false, 
                message: "" 
              }));
              yield put(setChatSelection({ 
                chat_id: chatDetail.chat_id, 
                chat_detail_id: chatDetail.id, 
                isDisplay: true, 
                target: targetList.copywriting_strategy,
                title: 'Do you want me to provide a more strategize copywriting?',
                options: targetOptionsList.copywriting_strategy,
                animationClass: 'slide-up'
              }));
  
              yield put(editChatStage({
                chat_id: chatDetail.chat_id,
                updates: {
                  stage: '2',
                  subStage: 'strategy',
                  subStageOption: '',
                  chat_detail_id: chatDetail.id,
                  initial_prompt: chatDetail.edited_prompt,
                  initial_reply: chatDetail.edited_reply ?? '',
                }
              }));
            }
          } else if (stageData.subStage === 'image_to_copy_prompt'){
  
            if(chat_detail_id !== ''){
              yield put(setChatLoader({ 
                chat_id: chatDetail.chat_id, 
                isLoading: false, 
                isListening: false, 
                message: "" 
              }));
              yield put(setChatSelection({ 
                chat_id: chatDetail.chat_id, 
                chat_detail_id: chatDetail.id, 
                isDisplay: true, 
                target: targetList.copywriting_strategy,
                title: 'Do you want me to provide a more strategize copywriting?',
                options: targetOptionsList.copywriting_strategy,
                animationClass: 'slide-up'
              }));
    
              yield put(editChatStage({
                chat_id: chatDetail.chat_id,
                updates: {
                  stage: '2',
                  subStage: 'strategy',
                  subStageOption: '',
                  chat_detail_id: chatDetail.id,
                  initial_prompt: chatDetail.edited_prompt,
                  initial_reply: chatDetail.edited_reply ?? '',
                }
              }));
            }
          } else if (stageData.subStage === 'selected_copy_addon_style') {
            yield put(setChatSelection({ 
              chat_id: chatDetail.chat_id, 
              chat_detail_id: chatDetail.id, 
              isDisplay: true, 
              target: targetList.copywriting_strategy,
              title: 'Do you want me to provide a more strategize copywriting?',
              options: targetOptionsList.copywriting_strategy,
              animationClass: 'slide-up'
            }));
  
            yield put(editChatStage({
              chat_id: chatDetail.chat_id,
              updates: {
                stage: '2',
                subStage: 'strategy',
                subStageOption: '',
                chat_detail_id: chatDetail.id,
                initial_prompt: chatDetail.edited_prompt,
                initial_reply: chatDetail.edited_reply ?? '',
              }
            }));
          } else if (stageData.subStage === 'display_campaign') {

            yield call( handleStoreReplyGeneral, { 
              payload: { 
                chatId: chatDetail.chat_id, 
                replyData: {
                  chat_detail_id: '',
                  reply: stageData.selected_copy,
                  reply_url: stageData.selected_image_url,
                  reply_role: 'system',
                  ui_type: 'facebook',
                },
                isSuccess: true,
              }
            });

            yield put(setChatSelection({ 
              chat_id: chatDetail.chat_id, 
              chat_detail_id: chatDetail.id, 
              isDisplay: true, 
              target: targetList.create_more_ads,
              title: 'Do you want me to give more ads suggestion?',
              options: targetOptionsList.create_more_ads,
              animationClass: 'slide-up'
            }));
          }
          break;
        case '2':
          yield put(editChatStage({
            chat_id: chatDetail.chat_id,
            updates: {
              stage: '2',
              subStage: 'revise',
              subStageOption: '',
              initial_reply: '',
              initial_prompt: '',
              chat_detail_id: '',
            }
          }));
  
          if(chat_detail_id !== ''){ //for non system reply
            yield put(setChatLoader({ 
              chat_id: chatDetail.chat_id, 
              isLoading: false, 
              isListening: false, 
              message: "" 
            }));
            yield put(setChatSelection({ 
              chat_id: chatDetail.chat_id, 
              isDisplay: true, 
              chat_detail_id: chatDetail.id,
              target: targetList.revise_strategy_copywriting,
              title: 'Would you like me to expand on specific sections or revise this further? Let me know!',
              options: targetOptionsList.revise_strategy_copywriting,
              animationClass: 'slide-up'
            }));
          }
            
          break;
        case '3':
          yield put(setChatLoader({ 
            chat_id: chatDetail.chat_id, 
            isLoading: false, 
            isListening: false, 
            message: "" 
          }));

          if (chat_detail_id !== '') {

            if(chatDetail.reply_url){
              if(stageData.subStage === 'edit_image_part_inpainting') {
                
                yield put(editChatStage({
                  chat_id: chatDetail.chat_id,
                  updates: {
                    mask_url: chatDetail.reply_url,
                    mask_desc: chatDetail.edited_reply,
                  }
                }));
                

              } else {
                yield put(editChatStage({
                  chat_id: chatDetail.chat_id,
                  updates: {
                    source_url: chatDetail.reply_url,
                    source_desc: chatDetail.edited_reply,
                  }
                }));
              }
            }
              
            if(stageData.subStage === 'initial_image'){ // initial image / after recreate / ...
              yield put(setChatSelection({ 
                chat_id: chatDetail.chat_id, 
                chat_detail_id: chatDetail.id, 
                isDisplay: true, 
                target: targetList.image_satisfaction,
                title: 'Does this look like what you had in mind?',
                options: targetOptionsList.image_satisfaction,
                animationClass: 'slide-up'
              }));
            } else if(stageData.subStage === 'edit_image_part_inpainting'){
              
              yield call( handleStoreReply, { 
                payload: { 
                  chatId: chatDetail.chat_id, 
                  replyData: {
                    chat_detail_id: '',
                    reply: `${i18n.t("What do you want to replace this with?")}`, 
                    reply_role: 'system' 
                  },
                  isSuccess: true,
                }
              });
            } else if(stageData.subStage === 'selected_image_edit'){
              yield put(setChatSelection({ 
                chat_id: chatDetail.chat_id, 
                chat_detail_id: chatDetail.id, 
                isDisplay: true, 
                target: targetList.image_satisfaction,
                title: 'Does this look like what you had in mind?',
                options: targetOptionsList.image_satisfaction,
                animationClass: 'slide-up'
              }));
            } else if(stageData.subStage === 'upload_image'){
              yield put(setChatSelection({ 
                chat_id: chatDetail.chat_id, 
                isDisplay: true, 
                chat_detail_id: chatDetail.id,
                target: targetList.upload_image_options,
                title: "I've notice you have uploaded an image, what do you want me to help you with?",
                options: targetOptionsList.upload_image_options,
                animationClass: 'slide-up'
              }));
            }
          }
          break;
        default:
          break;
      }
    } else {
      yield call(handleErrorReplies, chatId);
    }
  } catch (error) {
    console.error('handleStoreReply Saga Error:', error);

    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
  }
}

function* handleStoreReplyGeneral(action) {
  console.log('chatSagas: handleStoreReplyGeneral', action);
  const { chatId, replyData, isSuccess } = action.payload;

  try {
    const chat_detail_id = replyData.chat_detail_id ?? '';
    const replyResponse = yield call(storeReplyApi, chatId, replyData);
    console.log('handleStoreReplyGeneral: replyResponse', replyResponse);

    const { chatDetail, updateData, chat_memory } = replyResponse.data;

    if(chat_detail_id === ''){ //new details
      console.log('handleStoreReplyGeneral: chatDetail', chatDetail);

      yield put(appendCurrentChatDetails(Array.isArray(chatDetail) ? chatDetail : [chatDetail]));
    } else { //existing details
      // Dispatch actions to update the state
      yield put(editCurrentChatDetails({
        chat_id: chatDetail.chat_id,
        chat_detail_id: chatDetail.id,
        updatedData: updateData,
      }));
    }

    yield put(editCurrentChatData({ field: 'last_active_datetime', value: updateData.updated_at }));
    yield put(setBottomRefTrigger(Date.now()));

    if(chat_memory) {
      yield put(appendChatMemory({
        chat_id: chatDetail.chat_id,
        chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
      }));
    }

    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));

    if(isSuccess){
      return chatDetail;
    } else {
      yield call(handleErrorReplies, chatId);
    }
  } catch (error) {
    console.error('handleStoreReply Saga Error:', error);

    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
  }
}

function* handleErrorReplies(chat_id) {
  const stageList = yield select(selectChatStage, chat_id);
  const stageData = stageList[chat_id];
  console.log('handleErrorReplies: stageData', stageData)
  
  yield put(setChatLoader({ 
    chat_id: chat_id, 
    isLoading: false, 
    isListening: false, 
    message: "" 
  }));
  //get chat details
  const chatByIdResponse = yield call(getChatByIdApi, chat_id);
  const { chat } = chatByIdResponse.data;
  console.log('handleErrorReplies: chatByIdResponse', chatByIdResponse);

  if(stageData.campaign_type === 'AdsClone') {
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        stage: '1',
        subStage: 'adsclone_location',
        status: 'start',
      }
    }));
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: ''
    }));
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("Let's re-start creating your winning ads campaign. Where is the location of the product or service you want to advertise?")}`, 
          edited_reply: "Where is the location of the product or service you want to advertise?", 
          reply_role: 'assistant',
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: ''
    }));

    const options = yield call(fetchCountryList);

    yield put(setChatSelection({ 
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: true, 
      target: 'Location Dropdown',
      title: "",
      options: options,
      animationClass: 'slide-up'
    }));
    yield put(setBottomRefTrigger(Date.now()));

    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: ''
    }));
  } else if(stageData.campaign_type === 'BrandBoost') {
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        stage: '1',
        subStage: 'brandboost_location',
        status: 'start',
      }
    }));
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: ''
    }));
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("Let's start creating your ads insights campaign. Where is the location of the product or service you want to get insights?")}`, 
          reply_role: 'system',
        },
        isSuccess: true,
      }
    });
    yield put(setBottomRefTrigger(Date.now()));

    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: ''
    }));

    const options = yield call(fetchCountryList);

    yield put(setChatSelection({ 
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: true, 
      target: 'Location Dropdown',
      title: "",
      options: options,
      animationClass: 'slide-up'
    }));

    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: ''
    }));
  } else if(stageData.campaign_type === 'CopyCraft') {
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        stage: '1',
        subStage: 'copycraft_option',
        status: 'start',
      }
    }));

    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("Let's re-start crafting some attention-grabbing copy.")}`, 
          reply_role: 'system',
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));

    yield put(setChatSelection({
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: true, 
      target: targetList.copycraft_option,
      title: "",
      options: targetOptionsList.copycraft_option,
      animationClass: 'slide-up'
    }));
  } else if(stageData.campaign_type === 'VisualAds') {
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        stage: '1',
        subStage: 'visualads_option',
        status: 'start',
      }
    }));

    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("Let's re-start create some stunning images! Just let me know what you'd like to generate, and we'll get started.")}`, 
          reply_role: 'system',
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));

    yield put(setChatSelection({
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: true, 
      target: targetList.visualads_option,
      title: "",
      options: targetOptionsList.visualads_option,
      animationClass: 'slide-up'
    }));
  } else if(stageData.campaign_type === 'SketchSpark') {
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        stage: '1',
        subStage: 'sketchspark_get_img',
        status: 'start',
      }
    }));

    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));

    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("Let's re-start crafting some attention-grabbing copy and stunning image from your sketch. Upload or take the image of your sketch.")}`, 
          reply_role: 'system' 
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
  } else {
    if(stageData.selected_copy !== '' && stageData.selected_image_url === ''){ //have copy, no image
      yield put(editChatStage({
        chat_id: chat_id,
        updates: {
          stage: '3',
          subStage: 'initial_image',
          status: 'start',
        }
      }));
    } else if(stageData.selected_copy !== '' && stageData.selected_image_url !== ''){ //have both
      yield put(editChatStage({
        chat_id: chat_id,
        updates: {
          stage: '1',
          subStage: 'initial_prompt',
          status: 'complete',
        }
      }));
    } else if(stageData.selected_copy === '' && stageData.selected_image_url !== ''){ //no copy, have image
      yield put(editChatStage({
        chat_id: chat_id,
        updates: {
          stage: '1',
          subStage: 'initial_prompt',
          status: 'start',
        }
      }));
    } else { // no both
      if(chat.product_keyword === ''){
        yield put(editChatStage({ //not yet went through scanner and ads library
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'scanner',
            status: 'start',
          }
        }));
      } else if (chat.product_keyword !== ''){ //went through scanner, but not ads library
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adslibrary',
            status: 'start',
          }
        }));

        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.campaign_creation_options,
          title: "Let's create your campaign.",
          options: beforeInitialOptions.campaign_creation_options,
          animationClass: 'slide-up'
        }));
      }
    }
    yield put(setChatSelection({ 
      chat_id: chat.id, 
      chat_detail_id: '', 
      isDisplay: false, 
      target: '',
      action: '',
      title: '',
      options: [],
      animationClass: 'slide-down'
    }));
  }
}


function* handleStoreBulkReply(action) {
  console.log('chatSagas: handleStoreBulkReply', action);
  const { chatId, replyData } = action.payload;

  try {
    const replyResponse = yield call(storeBulkReplyApi, chatId, replyData);
    const { chatDetails, updateData, chat_memory, copyId, imageId, imagePath } = replyResponse.data;

    //foreach chat detail append to current chat details
    if (chatDetails) {
      yield put(appendCurrentChatDetails(Array.isArray(chatDetails) ? chatDetails : [chatDetails]));
    }
    
    yield put(editCurrentChatData({ field: 'last_active_datetime', value: updateData.updated_at }));
    yield put(setBottomRefTrigger(Date.now()));

    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    })); 

    if(chat_memory) {
      yield put(appendChatMemory({
        chat_id: chatId,
        chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
      }));
    }
    
    //set prompt date and time
    const now = moment(); // Get current time
    const promptDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
    const promptTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

    const stageList = yield select(selectChatStage, chatId);
    const stageData = stageList[chatId];
    console.log('handleStoreBulkReply: stageData', stageData);
  
    switch (stageData.stage) {
      case '1':
        console.log(stageData.stage);
        if (stageData.subStage === 'adsclone_select_ads') { //selected fb ads           
          
          yield put(setChatLoader({ 
            chat_id: chatId, 
            isLoading: true, 
            isListening: false, 
            message: `${i18n.t("Hold on, we are generating your winning ads.")}` 
          }));

          yield put(editChatStage({
            chat_id: chatId,
            updates: {
              stage: '1',
              subStage: 'adsclone_generate_selected_ads',
            }
          }));

          //get chat details
          const chatByIdResponse = yield call(getChatByIdApi, chatId);
          const { chat } = chatByIdResponse.data;
          console.log('handleStoreBulkReply: chatByIdResponse', chatByIdResponse);

          // Construct the prompt for requesting a image prompt
          // const request_image_prompt = 'Write me a prompt for image generation on a '+chat.product_keyword+'. Brand industry '+chat.chat_brand_business_type+' , brand color '+chat.chat_brand_colors+
          //   ', give suggestion on the scenario , this is for marketing image, to post in social media, give me more attractive ideas to makes the images looks more eye catchy, put in human with posture or action into it if you feels will makes the image more lively, only need visual in the image, change the color in the description to my brand color make sure it mix and match nicely to the image , only return me in text form will do , no listing , no quote , no description., i need only the prompt return to me , i need the prompt to be in 1 whole sentence , explain the object position in the image is important .'+
          //   stageData.initial_prompt; //initial_prompt is image description from obj reg
          const request_image_prompt = `Write me a prompt for image generation on a ${chat.product_keyword} product, location in ${chat.ads_location}, brand industry ${chat.chat_brand_business_type} , brand color ${chat.chat_brand_colors}. ${stageData.source_desc}. this is marketing image , to post in social media, only need visual in the image , only return me in text form will do , no listing , no quote , no description`;
          // Call chat API to generate the image prompt
          const requestImgPromptResponse = yield call(handleChatbotGeneral, chatId, request_image_prompt);
          console.log('handleStoreBulkReply: requestImgPromptResponse', requestImgPromptResponse);

          const image_prompt = 'Design an attention-grabbing graphic for a social post,  realistic style, Remove references to personalities, brands, and text-generating elements.'+requestImgPromptResponse;

          // Call text-to-image API to generate the image
          let generated_image_url = yield call(handleTextToImageGeneral, chatId, image_prompt, '');
          console.log('handleStoreBulkReply: generated_image_url', generated_image_url);

          //call chatbot to get adsclone copy using image prompt and ...
          // const initial_prompt = 'Product keywords: '+chat.product_keyword+'. Business type: '+chat.chat_brand_business_type+'. Company name: '+chat.chat_brand_name+'. Image idea: '+requestImgPromptResponse+'. Write me a compelling digital marketing copywriting without explanations and descriptions. Give me only the copywriting';
          const initial_prompt = `Write me a excatly same copywriting based on this ${stageData.initial_prompt} , but it have to fit with my product ${chat.product_keyword}, put in my company name ${chat.chat_brand_name} if needed , follow exaclty the same if the copywriting format and if there is any emoji.`;
          const generatedCopyResponse = yield call(handleChatbotGeneral, chatId, initial_prompt);
          console.log('handleStoreBulkReply: generatedCopyResponse', generatedCopyResponse);

          //prepare reply data to store to db
          let replyData = []; // Declare an empty array
          let image_desc = '';
          //set reply date and time
          const now = moment(); // Get current time
          const replyDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
          const replyTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

          if(generated_image_url !== ''){
      
            // call objreg for image url
            const recognitionResponse = yield call(aiImageRecognitionApi, generated_image_url);
            console.log('handleStoreBulkReply: recognitionResponse', recognitionResponse);
      
            image_desc = recognitionResponse.data;
            
            // Append to replyData using spread operator
            replyData = [...replyData, {
              reply_url: generated_image_url, 
              reply_role: 'assistant',
              edited_reply: image_desc,
              reply_date: replyDate,
              reply_time: replyTime,
              ui_type: 'hide',
            }];
          }
      
          replyData = [...replyData, {
            reply: generatedCopyResponse, 
            reply_role: 'assistant',
            edited_reply: generatedCopyResponse,
            reply_date: replyDate,
            reply_time: replyTime,
            ui_type: 'hide',
          }];

          //for display in facebook
          replyData = [...replyData, {
            reply: generatedCopyResponse, 
            reply_url: generated_image_url, 
            reply_role: 'system',
            reply_date: replyDate,
            reply_time: replyTime,
            ui_type: 'facebook',
          }];

          yield put(editChatStage({
            chat_id: chatId,
            updates: {
              adsclone_copy: generatedCopyResponse,
              adsclone_image_url: generated_image_url,
              adsclone_image_desc: image_desc,
            }
          }));

          //call handleStoreBulkReply to store data
          yield call( handleStoreBulkReply, { 
            payload: { 
              chatId: chatId, 
              replyData: replyData,
            }
          });
          
          
        } else if (stageData.subStage === 'adsclone_generate_selected_ads') {

          yield put(editChatStage({
            chat_id: chatId,
            updates: {
              adsclone_copy_id: copyId ?? '',
              adsclone_image_id: imageId ?? '',
              // adsclone_image_url: imagePath ? getFullImageUrl(imagePath) : '',
            }
          }));

          yield call( handleStoreReply, { 
            payload: { 
              chatId: chatId, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("What are your thoughts? Do these ads align with your preferences?")}`,
                reply_role: 'system',
              },
              isSuccess: true,
            }
          });


        } else if (stageData.subStage === 'select_adslib') { //selected ads library          

          yield call( handleStoreReply, { 
            payload: { 
              chatId: chatId, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Here is your winning ads copywriting specialise just for you")}`,
                reply_role: 'system',
              },
              isSuccess: true,
            }
          });
          
        } else if (stageData.subStage === 'select_suggested_ads') { //select suggested ads       
          
          yield put(setChatLoader({ 
            chat_id: chatId, 
            isLoading: false, 
            isListening: false, 
            message: "" 
          }));

          yield put(setChatSelection({ 
            chat_id: chatId, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.revise_suggested_ads,
            title: "Do you want to revise this ads?",
            options: targetOptionsList.revise_suggested_ads,
            animationClass: 'slide-up'
          }));
          
        }
        break;
      case '2':
        break;
      default:
        break;
    }
  } catch (error) {
    console.error('handleStoreReply Saga Error:', error);
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatId, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}


function* handleSelectionMode(action) {
  console.log('chatSagas: handleSelectionMode', action);

  const { chat_id, index } = action.payload;

  yield put(setChatLoader({ 
    chat_id: chat_id, 
    isLoading: true, 
    isListening: false, 
    message: '',//`${i18n.t("Just a moment! We're getting everything ready for you. Thanks for hanging tight!")}` 
  }));

  const chatSelection = yield select(selectChatSelectionForCurrentChat);
  console.log('handleSelectionMode: chatSelection', chatSelection);

  const chat_detail_id = chatSelection.chat_detail_id;
  const selectedItem = chatSelection.options[index];
  const target = chatSelection.target;
  const options = chatSelection.options;

  yield put(setChatSelection({ 
    chat_id: chat_id, 
    chat_detail_id: '', 
    isDisplay: false, 
    target: '',
    action: '',
    title: '',
    options: [],
    animationClass: 'slide-down'
  }));

  const stageList = yield select(selectChatStage, chat_id);
  const stageData = stageList[chat_id];
  console.log('handleSelectionMode: stageData', stageData);
  //set prompt date and time
  const now = moment(); // Get current time
  const promptDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
  const promptTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

  if (target === beforeInitial.brands_options) {
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
    switch (selectedItem.id) {
      case 'yes':
        // call marketing objective
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.brand_marketing_obj,
          title: "What's your marketing objective? We'll use it to focus on the results that matter most to your business.",
          options: beforeInitialOptions.brand_marketing_obj,
          animationClass: 'slide-up'
        }));
        break;
      case 'no':
        // call marketing objective
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.brand_marketing_obj,
          title: "What's your marketing objective? We'll use it to focus on the results that matter most to your business.",
          options: beforeInitialOptions.brand_marketing_obj,
          animationClass: 'slide-up'
        }));
        break;
      default:
        break;
    }
  } 

  if (target === beforeInitial.brand_marketing_obj) {
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
    switch (selectedItem.id) {
      case 'awareness':
        // call marketing objective
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.brand_ads_location,
          title: "Where is the location of the product or service you want to advertise?",
          options: beforeInitialOptions.brand_ads_location,
          animationClass: 'slide-up'
        }));
        break;
      case 'traffic':
        // call marketing objective
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.brand_ads_location,
          title: "Where is the location of the product or service you want to advertise?",
          options: beforeInitialOptions.brand_ads_location,
          animationClass: 'slide-up'
        }));
        break;
      case 'engagement':
        // call marketing objective
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.brand_ads_location,
          title: "Where is the location of the product or service you want to advertise?",
          options: beforeInitialOptions.brand_ads_location,
          animationClass: 'slide-up'
        }));
        break;
      case 'sales':
        // call marketing objective
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.brand_ads_location,
          title: "Where is the location of the product or service you want to advertise?",
          options: beforeInitialOptions.brand_ads_location,
          animationClass: 'slide-up'
        }));
        break;
      case 'leads':
        // call marketing objective
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.brand_ads_location,
          title: "Where is the location of the product or service you want to advertise?",
          options: beforeInitialOptions.brand_ads_location,
          animationClass: 'slide-up'
        }));
        break;
      case 'app_promotion':
        // call marketing objective
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.brand_ads_location,
          title: "Where is the location of the product or service you want to advertise?",
          options: beforeInitialOptions.brand_ads_location,
          animationClass: 'slide-up'
        }));
        break;
      default:
        break;
    }
  } 

  if (target === beforeInitial.brand_ads_location) {
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
    switch (selectedItem.id) {
      case 'company_location':
        // call marketing objective
        // yield call(handleSmartScan, chat_id);
        // yield put(setChatSelection({ 
        //   chat_id: chat_id, 
        //   chat_detail_id: '', 
        //   isDisplay: true, 
        //   title: <a href="https://google.com">Smart scan</a>,
        //   animationClass: 'slide-up'
        // }));
        //Glynnis
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.selection_ss_al,
          title: "Get your ads predictions based on your company product",
          options: beforeInitialOptions.selection_ss_al,
          animationClass: 'slide-up'
        }));
        
        // yield put(setChatSelection({
        //   chat_id: chat_id, 
        //   chat_detail_id: '', 
        //   isDisplay: true, 
        //   target: beforeInitial.campaign_creation_options,
        //   title: "Let's create your campaign.",
        //   options: beforeInitialOptions.campaign_creation_options,
        //   animationClass: 'slide-up'
        // }));
        break;
      default:
        break;
    }
  }

  // if (target === beforeInitial.smartscan) {
  //   yield call( handleBeforeInitial, { 
  //     payload: { 
  //       chatId: chat_id, 
  //       // product_keyword: promptData.prompt,
  //     }
  //   });
  // }

  if (target === beforeInitial.selection_ss_al) {
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
    switch (selectedItem.id) {
      case 'smartscan_selection':
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.smartscan,
          title: "This is our analytics based on your company product",
          options: beforeInitialOptions.smartscan,
          animationClass: 'slide-up'
        }));
        // yield put(setChatSelection({
        //   chat_id: chat_id, 
        //   chat_detail_id: '', 
        //   isDisplay: true, 
        //   target: beforeInitial.smartscan_graph,
        //   title: "This is our analytics based on your company product",
        //   options: beforeInitialOptions.smartscan_graph,
        //   animationClass: 'slide-up'
        // }));
        break;
      case 'adslibrary_selection':
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.campaign_creation_options,
          title: "Let's create your campaign.",
          options: beforeInitialOptions.campaign_creation_options,
          animationClass: 'slide-up'
        }));
        break;
      default:
        break;
    }
  }

  // if (target === beforeInitial.smartscan_graph) {
  //   switch (selectedItem.id) {
  //     case 'smartscan_graph':
  //       yield put(setChatSelection({
  //         chat_id: chat_id, 
  //         chat_detail_id: '', 
  //         isDisplay: true, 
  //         target: beforeInitial.campaign_creation_options,
  //         title: "Let's create your campaign.",
  //         options: beforeInitialOptions.campaign_creation_options,
  //         animationClass: 'slide-up'
  //       }));
  //     default:
  //       break;
  //   }
  // }
  //glynnis
  if (target === beforeInitial.smartscan) {
    switch (selectedItem.id) {
      case 'smartscan_image':
        
        break;
      case 'smartscan_desc':

        // call handle store prompt
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'smartscan_keyword',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me more about your product")}`, 
              reply_role: 'assistant' 
            },
            isSuccess: true,
          }
        });

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'smartscan_budget',
          }
        }));
        
        // yield put(setChatSelection({
        //   chat_id: chat_id, 
        //   chat_detail_id: '', 
        //   isDisplay: true, 
        //   target: beforeInitial.smartscan_graph,
        //   title: "This is our analytics based on your company product",
        //   options: beforeInitialOptions.smartscan_graph,
        //   animationClass: 'slide-up'
        // }));
        // yield call( handleStoreReply, { 
        //   payload: { 
        //     chatId: chat_id, 
        //     replyData: {
        //       chat_detail_id: '',
        //       reply: `${i18n.t("Tell me more about your product")}`, 
        //       reply_role: 'assistant' 
        //     },
        //     isSuccess: true,
        //   }
        // });
        // console.log('selected smartscan_desc tell me');
        // yield call( handleStorePrompt, { 
        //   payload: { 
        //     chatId: chat_id, 
        //     promptData: {
        //       prompt: '',
        //       promptDate: promptDate,
        //       promptTime: promptTime
        //     }
        //   }
        // });
        break;
      default:
        break;
    }
  }

  if (stageData.subStage === 'smartscan_graph'){
    yield put(setChatSelection({
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: true, 
      target: beforeInitial.smartscan_graph,
      title: "This is our analytics based on your company product",
      options: beforeInitialOptions.smartscan_graph,
      animationClass: 'slide-up'
    }));
  }

  if (target === beforeInitial.smartscan_graph) {
    yield put(setChatSelection({
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: true, 
      target: beforeInitial.selection_ss_al,
      title: "Get your ads predictions based on your company product",
      options: beforeInitialOptions.selection_ss_al,
      animationClass: 'slide-up'
    }));
  }

  if (target === beforeInitial.campaign_creation_options) {
    switch (selectedItem.id) {
      case 'adslibrary':
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: `${i18n.t("Just a moment! We are now generating the winning ads for you!")}`
        }));

        yield put(setBottomRefTrigger(Date.now()));

        // call ads library api
        yield call(handleAdsLibrary, chat_id, '');
        break;
      case 'create ads':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_copy',
          }
        }));

        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: ''
        }));


        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: beforeInitial.initial_copywriting_strategy,
          title: 'Choose the copywriting strategy you\'d prefer for your ads.',
          options: beforeInitialOptions.initial_copywriting_strategy,
          animationClass: 'slide-up'
        }));

        // //get chat details
        // const chatByIdResponse = yield call(getChatByIdApi, chat_id);
        // const { chat } = chatByIdResponse.data;
        // console.log('handleStoreReply: chatByIdResponse', chatByIdResponse);

        // const initial_prompt = "Generate a copywriting to align with brand name, '"+chat.chat_brand_name+"' and the business type is '"+chat.chat_brand_business_type+"'. The product is '"+chat.product_keyword+"'.The advertisement location is in '"+chat.ads_location+"'.";
        // yield put(editChatStage({
        //   chat_id: chat_id,
        //   updates: {
        //     initial_prompt: initial_prompt,
        //     stage: '1',
        //     subStage: 'initial_copy',
        //   }
        // }));

        // // call handle store prompt
        // yield call( handleStorePrompt, { 
        //   payload: { 
        //     chatId: chat_id, 
        //     promptData: {
        //       prompt: '',
        //       promptDate: promptDate,
        //       promptTime: promptTime
        //     }
        //   }
        // });
        break;
      default:
        break;
    }
  } else if (target === 'Ads Library') {
    
    const selected_ads = options.find(option => option.id === selectedItem.id);
    console.log('handleSelectionMode: selected_ads', selected_ads);
    
    const body = selected_ads.body;
    let image_url = '';
    let image_desc = '';

    let replyData = []; // Declare an empty array
    //set reply date and time
    const now = moment(); // Get current time
    const replyDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
    const replyTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

    if(selected_ads.image_url !== ''){
      image_url = selected_ads.image_url;

      // call objreg for image url
      const recognitionResponse = yield call(aiImageRecognitionApi, image_url);
      console.log('handleSelectionMode: recognitionResponse', recognitionResponse);

      image_desc = recognitionResponse.data;
      
      // Append to replyData using spread operator
      replyData = [...replyData, {
        reply_url: image_url, 
        reply_role: 'assistant',
        edited_reply: image_desc,
        reply_date: replyDate,
        reply_time: replyTime,
      }];
    }

    replyData = [...replyData, {
      reply: body, 
      reply_role: 'assistant',
      edited_reply: body,
      reply_date: replyDate,
      reply_time: replyTime,
    }];

    
    // editChatStage: substage(select_adslib), initial_prompt, body, selected_image_url
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        stage: '1',
        subStage: 'select_adslib',
        initial_prompt: body,
        // selected_copy: body,
        // selected_image_url: selected_image_url,
        // selected_image_desc: selected_image_desc,
      }
    }));

    // storeBulkReply
    yield call( handleStoreBulkReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: replyData,
      }
    });
  } else if (target === beforeInitial.regenerate_copy) {
    switch (selectedItem.id) {
      case 'yes':
        // call store prompt
        yield call( handleStorePrompt, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            }
          }
        });
        break;
      case 'no':
        //update isCopy
        const response = yield call(updateChatContentFlagApi, chat_detail_id, 'isCopy');
        console.log('handleSelectionMode: response', response);
        const { chatDetail } = response.data;

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            initial_prompt: '',
            chat_detail_id: '',
            selected_copy: chatDetail.edited_reply,
          }
        }));
        //check have image or not
        if(stageData.selected_image_url === ''){
          //no image, open selection to ask want image or not
          yield put(setChatSelection({ 
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: beforeInitial.generate_adslib_image,
            title: 'Do you want to generate image based on the above content?',
            options: beforeInitialOptions.generate_adslib_image,
            animationClass: 'slide-up'
          }));
        } else {
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: false, 
            isListening: false, 
            message: ''
          }));
          yield put(setChatSelection({ 
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: beforeInitial.regenerate_image,
            title: 'Great! What about the image? Would you like to make any revisions?',
            options: beforeInitialOptions.regenerate_image,
            animationClass: 'slide-up'
          }));
        }
        break;
      default:
        break;
    }
  } else if (target === beforeInitial.generate_adslib_image) {
    switch (selectedItem.id) {
      case 'yes':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'generate_adslib_image',
          }
        }));

        yield call( handleStorePrompt, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            }
          }
        });
        break;
      case 'no':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
          }
        }));
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: ''
        }));
        break;
      default:
        break;
    }
  } else if (target === beforeInitial.regenerate_image) {
    switch (selectedItem.id) {
      case 'yes':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'generate_adslib_image',
          }
        }));

        yield call( handleStorePrompt, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            }
          }
        });
        break;
      case 'no':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'display_campaign',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Your copy and image is ready!")}`, 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if (target === beforeInitial.no_adslibrary_options) {
    switch (selectedItem.id) {
      case 'smart scan':
        yield call(handleResetSmartScan, chat_id);
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'smart_scan',
          }
        }));
        //whats next?
        break;
      case 'create ads':
        // call handle store prompt
        break;
      default:
        break;
    }
  } else if (target === beforeInitial.initial_copywriting_strategy) { // Title: Choose the copywriting strategy you\'d prefer for your ads.
    // chatStages: 1(initial_copy)

    switch (selectedItem.id) {
      case 'custom strategy':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStageOption: 'custom strategy',
          }
        }));
        //store reply: Tell me your strategy.
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me your strategy")}`, 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStageOption: selectedItem.id,
          }
        }));
        yield call( handleStorePrompt, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            }
          }
        });
        break;
    }
  } else if (target === beforeInitial.regenerate_copy) {
    switch (selectedItem.id) {
      case 'yes':
        // call store prompt
        yield call( handleStorePrompt, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            }
          }
        });
        break;
      case 'no':
        //update isCopy
        const response = yield call(updateChatContentFlagApi, chat_detail_id, 'isCopy');
        console.log('handleSelectionMode: response', response);
        const { chatDetail } = response.data;

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            initial_prompt: '',
            chat_detail_id: '',
            selected_copy: chatDetail.edited_reply,
          }
        }));
        //check have image or not
        if(stageData.selected_image_url === ''){
          //no image, open selection to ask want image or not
          yield put(setChatSelection({ 
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: beforeInitial.generate_adslib_image,
            title: 'Do you want to generate image based on the above content?',
            options: beforeInitialOptions.generate_adslib_image,
            animationClass: 'slide-up'
          }));
        } else {
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: false, 
            isListening: false, 
            message: ''
          }));
          yield put(setChatSelection({ 
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: beforeInitial.regenerate_image,
            title: 'Great! What about the image? Would you like to make any revisions?',
            options: beforeInitialOptions.regenerate_image,
            animationClass: 'slide-up'
          }));
        }
        break;
      default:
        break;
    }
  } else if (target === targetList.image_satisfaction) { // Title: Does this look like what you had in mind?
    switch (selectedItem.id) {
      case '1': //Yes
        if(stageData.selected_image_url === '' && stageData.selected_copy === ''){
          yield put(setChatSelection({ 
            chat_id: chat_id, 
            chat_detail_id: chat_detail_id, 
            isDisplay: true, 
            target: targetList.set_is_image,
            title: 'Would you like to keep this image as your advertisement image?',
            options: targetOptionsList.set_is_image,
            animationClass: 'slide-up'
          }));
        } else if (stageData.selected_image_url !== ''){

          yield put(setChatSelection({ 
            chat_id: chat_id, 
            chat_detail_id: chat_detail_id, 
            isDisplay: true, 
            target: targetList.set_is_image,
            title: 'You have already decided on an ad image for this campaign. Would you like to replace it with the new one?',
            options: targetOptionsList.set_is_image,
            animationClass: 'slide-up'
          }));
        } else {
          yield call(handleUpdateImageSatisfaction, { payload: { chatDetailId: chat_detail_id, image_satisfaction: '1' } });
          const response = yield call(updateChatContentFlagApi, chat_detail_id, 'isImage');
          console.log('handleSelectionMode: response', response);
          const { chatDetail } = response.data;
  
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: false, 
            isListening: false, 
            message: "" 
          }));

          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'initial_prompt',
              initial_prompt: '',
              chat_detail_id: '',
              image_size: '',
              image_style: '',
              selected_image_url: chatDetail.reply_url,
              selected_image_desc: chatDetail.edited_reply,
              status: stageData.selected_copy === '' ? 'ongoing' : 'complete',
            }
          }));
  
          if(stageData.selected_copy === '') {
            yield delay(100);
            yield put(setChatSelection({ 
              chat_id: chatDetail.chat_id, 
              chat_detail_id: '', 
              isDisplay: true, 
              target: targetList.assist_copywriting,
              title: "I can assist you with the copywriting that fits best with your visuals. Would you like to proceed?",
              options: targetOptionsList.assist_copywriting,
              animationClass: 'slide-up'
            }));
          } else {
            yield put(editChatStage({
              chat_id: chat_id,
              updates: {
                stage: '1',
                subStage: 'display_campaign',
              }
            }));

            yield call( handleStoreReply, { 
              payload: { 
                chatId: chat_id, 
                replyData: {
                  chat_detail_id: '',
                  reply: `${i18n.t("Your copy and image is ready!")}`, 
                  reply_role: 'system' 
                },
                isSuccess: true,
              }
            });
          }
        }
        
        break;
      case '2': //No
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));

        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: chat_detail_id, 
          isDisplay: true, 
          target: targetList.image_not_satisfy_option,
          title: '',
          options: targetOptionsList.image_not_satisfy_option,
          animationClass: 'slide-up'
        }));
        break;
      default:
        break;
    }
  } else if (target === 'Ads Clone') {
    
    const selected_ads = options.find(option => option.id === selectedItem.id);
    console.log('handleSelectionMode: selected_ads', selected_ads);
    
    const body = selected_ads.body;
    let image_url = '';
    let image_desc = '';

    let replyData = []; // Declare an empty array
    //set reply date and time
    const now = moment(); // Get current time
    const replyDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
    const replyTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

    if(selected_ads.image_url !== ''){
      image_url = selected_ads.image_url;

      // call objreg for image url
      let prompt = 'What do you see? return me only the image prompt combine with the image style,design, texture and color tone into a description';
      const recognitionResponse = yield call(aiImageRecognitionApi, image_url, prompt);
      console.log('handleSelectionMode: recognitionResponse', recognitionResponse);

      image_desc = recognitionResponse.data;
      
      // Append to replyData using spread operator
      replyData = [...replyData, {
        reply_url: image_url, 
        reply_role: 'assistant',
        edited_reply: image_desc,
        reply_date: replyDate,
        reply_time: replyTime,
        ui_type: 'hide',
        // isImage: '1'
      }];
    }

    replyData = [...replyData, {
      reply: body, 
      reply_role: 'assistant',
      edited_reply: body,
      reply_date: replyDate,
      reply_time: replyTime,
      ui_type: 'hide',
      // isCopy: '1',
    }];

    replyData = [...replyData, {
      reply: body, 
      reply_url: image_url, 
      reply_role: 'assistant',
      edited_reply: '',
      reply_date: replyDate,
      reply_time: replyTime,
      ui_type: 'facebook_ads',
      profile_url: selected_ads.profile_pic_url || '',
      page_name: selected_ads.page_name || '',
    }];

    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        stage: '1',
        subStage: 'adsclone_select_ads',
        initial_prompt: body, 
        source_desc: image_desc, //object recognition
      }
    }));

    // storeBulkReply
    yield call( handleStoreBulkReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: replyData,
      }
    });
  } else if (target === targetList.adsclone_satisfaction) { // Title: What are your thoughts? Do these ads align with your preferences?
    switch (selectedItem.id) {
      case '1': //Yes
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Yes")}`,
              editedPrompt: 'Yes',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        //update isCopy and isImage
        yield call(handleUpdateImageSatisfaction, { payload: { chatDetailId: stageData.adsclone_image_id, image_satisfaction: '1' } });
        yield call(updateChatContentFlagApi, stageData.adsclone_copy_id, 'isCopy');
        yield call(updateChatContentFlagApi, stageData.adsclone_image_id, 'isImage');

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_end',
            status: 'complete',
            initial_prompt: '',
            initial_reply: '',
            source_url: '',
            source_desc: '',
            chat_detail_id: '',
            selected_copy: stageData.adsclone_copy,
            selected_image_url: stageData.adsclone_image_url,
            selected_image_desc: stageData.adsclone_image_desc,
          }
        }));

        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: stageData.adsclone_copy,
              reply_url: stageData.adsclone_image_url,
              reply_role: 'system',
              ui_type: 'facebook',
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Congratulations! Your winning ads have been created. How else can I assist you today?")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
        break;
      case '2': //No
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("No")}`,
              editedPrompt: 'No',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        }); 
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("What will you like to improve ?")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });

        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: chat_detail_id, 
          isDisplay: true, 
          target: targetList.adsclone_improvement,
          title: '',
          options: targetOptionsList.adsclone_improvement,
          animationClass: 'slide-up'
        }));
        break;
      default:
        break;
    }
  } else if (target === targetList.brandboost_is_result) { 
    switch (selectedItem.id) {
      case 'yes':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Yes, i would like to try with another keyword")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        
        yield put(editBrandBoostDetail({
          chat_id: chat_id,
          updates: { 
            keyword_approach: '',
            keyword: '',
            keyword_url: '',
            facebook_keyword: '',
            flexible_spec_interest: {},
            reach: '',
            target_audience: {},
            target_spec: {},
            budget: '',
            summary:'',
          }
        }));
        
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'brandboost_location',
            status: 'start',
          }
        }));
  
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        const options = yield call(fetchCountryList);
  
        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: 'Location Dropdown',
          title: "",
          options: options,
          animationClass: 'slide-up'
        }));
        
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Where is the location of the product or service you want to advertise?")}`, 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });

      break;
      case 'no':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'brandboost_end',
            status: 'start',
          }
        }));
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("No, i wanna keep this prediction results")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        const response = yield call(updateChatContentFlagApi, stageData.adsclone_copy_id, 'isCopy');
        console.log('handleBrandBoostSummary: update is copy response', response);
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            selected_copy: stageData.adsclone_copy,
          }
        }));

        break;
      default:
        break;
    }

  } else if (target === targetList.adsclone_method) { 
    switch (selectedItem.id) {
      case 'fb_url': //yes
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Learn from Ads URL")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_fb_url',
          }
        }));
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Please input the ads url that you wish to learn from.")}`,
              edited_reply: '',
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
        break;
      case 'adslib':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Search from Ads Library")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("We are now searching the winning ads for you!")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: ''
        }));
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_adslib',
            status: 'start',
          }
        }));
        //call ads library
        yield call(handleAdsLibrary, chat_id, '');
        break;
      default:
        break;
    }
  } else if (target === targetList.adsclone_continue_url) { 
    switch (selectedItem.id) {
      case 'yes':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Yes")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Please provide another Facebook ad URL")}`,
              edited_reply: '',
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
        
        break;
      case 'no':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("No")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("We are now generating the winning ads for you!")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: ''
        }));
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_adslib',
            status: 'start',
          }
        }));
        //call ads library
        yield call(handleAdsLibrary, chat_id, '');
        break;
      default:
        break;
    }
  } else if (target === targetList.adsclone_improvement) { 
    //get chat details
    const chatByIdResponse = yield call(getChatByIdApi, chat_id);
    const { chat } = chatByIdResponse.data;
    console.log('handleStoreReply: chatByIdResponse', chatByIdResponse);

    switch (selectedItem.id) {
      case 'improve_image':
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Improve Image")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_revise_image',
            initial_prompt: '',
          }
        }));

        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Hold on, we are generating your winning ads image.")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });

        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        
        // Construct the prompt for requesting a image prompt
        const request_image_prompt_1 = 'Write me a prompt for image generation on a '+chat.product_keyword+'. Brand industry '+chat.chat_brand_business_type+' , brand color '+chat.chat_brand_colors+
        ', give suggestion on the scenario , this is for marketing image, to post in social media, give me more attractive ideas to makes the images looks more eye catchy, put in human with posture or action into it if you feels will makes the image more lively, only need visual in the image, change the color in the description to my brand color make sure it mix and match nicely to the image , only return me in text form will do , no listing , no quote , no description., i need only the prompt return to me , i need the prompt to be in 1 whole sentence , explain the object position in the image is important .'+
        stageData.adsclone_image_desc; //adsclone_image_desc is image description from obj reg
        
        // Call chat API to generate the image prompt
        const requestImgPromptResponse_1 = yield call(handleChatbotGeneral, chat_id, request_image_prompt_1);
        console.log('handleStoreReply: requestImgPromptResponse', requestImgPromptResponse_1);

        const image_prompt_1 = 'Design an attention-grabbing graphic for a social post,  realistic style, Remove references to personalities, brands, and text-generating elements.'+requestImgPromptResponse_1;

        // Call text-to-image API to generate the image
        let generated_image_url_1 = yield call(handleTextToImageGeneral, chat_id, image_prompt_1, '');
        console.log('handleStoreReply: generated_image_url', generated_image_url_1);

        let image_desc = '';

        if(generated_image_url_1 !== ''){
    
          // call objreg for image url
          const recognitionResponse = yield call(aiImageRecognitionApi, generated_image_url_1);
          console.log('handleStoreReply: recognitionResponse', recognitionResponse);
    
          image_desc = recognitionResponse.data;
          
        }

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            adsclone_image_url: generated_image_url_1,
            adsclone_image_desc: image_desc,
          }
        }));

        const detail = yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply_url: generated_image_url_1,
              edited_reply: image_desc,
              reply_role: 'assistant',
            },
            isSuccess: true,
          }
        });

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            adsclone_image_id: detail.id, 
            // adsclone_image_url: detail.reply_url ? getFullImageUrl(detail.reply_url) : '',
          }
        }));

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_generate_selected_ads',
            initial_prompt: '',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("What are your thoughts? Do these ads align with your preferences?")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
        break;

      case 'improve_copy':
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Improve Copy")}`,
              editedPrompt: 'Improve Copy',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        }); 
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_revise_copy',
            initial_prompt: '',
          }
        }));
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Hold on, we are generating your winning ads.")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));

        // Construct the prompt for requesting a image prompt
        const request_image_prompt_2 = 'Write me a prompt for image generation on a '+chat.product_keyword+'. Brand industry '+chat.chat_brand_business_type+' , brand color '+chat.chat_brand_colors+
        ', give suggestion on the scenario , this is for marketing image, to post in social media, give me more attractive ideas to makes the images looks more eye catchy, put in human with posture or action into it if you feels will makes the image more lively, only need visual in the image, change the color in the description to my brand color make sure it mix and match nicely to the image , only return me in text form will do , no listing , no quote , no description., i need only the prompt return to me , i need the prompt to be in 1 whole sentence , explain the object position in the image is important .'+
        stageData.adsclone_image_desc; //adsclone_image_desc is image description from obj reg
        
        // Call chat API to generate the image prompt
        const requestImgPromptResponse_2 = yield call(handleChatbotGeneral, chat_id, request_image_prompt_2);
        console.log('handleStoreReply: requestImgPromptResponse', requestImgPromptResponse_2);
            
        //call chatbot to get adsclone copy using image prompt and ...
        const initial_prompt_2 = 'Product keywords: '+chat.product_keyword+'. Business type: '+chat.chat_brand_business_type+'. Company name: '+chat.chat_brand_name+'. Image idea: '+requestImgPromptResponse_2+'. Write me a compelling digital marketing copywriting without explanations and descriptions. Give me only the copywriting';
        const generatedCopyResponse_2 = yield call(handleChatbotGeneral, chat_id, initial_prompt_2);
        console.log('handleStoreReply: generatedCopyResponse', generatedCopyResponse_2);

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            adsclone_copy: generatedCopyResponse_2,
          }
        }));

        const detail_2 = yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: generatedCopyResponse_2,
              edited_reply: generatedCopyResponse_2,
              reply_role: 'assistant',
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_generate_selected_ads',
            initial_prompt: '',
            adsclone_copy_id: detail_2.id, 
          }
        }));

        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("What are your thoughts? Do these ads align with your preferences?")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });


        break;
      default:
        break;
    }
  } else if (target === targetList.copycraft_option) { 
    switch (selectedItem.id) {
      case 'image':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Upload image and scan")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        if(stageData.selected_copy !== ''){ //have copy
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Here is your copy")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: stageData.selected_copy, 
                reply_role: 'system',
                ui_type: 'result_existing'
              },
              isSuccess: true,
            }
          });
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Would you like to regenerate your copywriting")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          yield put(setChatSelection({
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.copycraft_regen_copy,
            title: "",
            options: targetOptionsList.copycraft_regen_copy,
            animationClass: 'slide-up'
          }));

        } else { //no copy
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'copycraft_image_upload',
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Upload an image you'd like me to scan and generate a copywriting for you")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }
        break;
      case 'description':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Create Copywriting from your descriptions")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });

        if(stageData.selected_copy !== ''){ //have copy
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'copycraft_before_prompt',
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Here is your copy")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: stageData.selected_copy, 
                reply_role: 'system',
                ui_type: 'result_existing'
              },
              isSuccess: true,
            }
          });
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Would you like to regenerate your copywriting")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          yield put(setChatSelection({
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.copycraft_regen_copy,
            title: "",
            options: targetOptionsList.copycraft_regen_copy,
            animationClass: 'slide-up'
          }));

        } else { //no copy
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'copycraft_prompt',
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Tell me more about your product or services that you want to highlight in the copywriting")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }

        break;
      default:
        break;
    }
  } else if (target === targetList.visualads_option) { 
    switch (selectedItem.id) {
      case 'generate_new':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Generate a new image")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: ''
        }));
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'visualads_new_image',
          }
        }));
        if(stageData.selected_image_url !== ''){ //have selected image
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Here is your generated image")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: ''
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply_url: getFullImageUrl(stageData.selected_image_url), 
                reply_role: 'system',
                ui_type: 'result_existing'
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: ''
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Would you like to regenerate an image based on your selected image")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          yield put(setChatSelection({
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.visualads_regen_img,
            title: "",
            options: targetOptionsList.visualads_regen_img,
            animationClass: 'slide-up'
          }));
        } else { //no selected image
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Tell me what do you wanna generate?")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });

        }


        break;
      case 'inpainting':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Inpainting")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: ''
        }));
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'visualads_inpainting_mask',
          }
        }));
        if(stageData.selected_image_url !== ''){ //have selected image
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Here is your generated image")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: ''
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply_url: getFullImageUrl(stageData.selected_image_url), 
                reply_role: 'system',
                ui_type: 'result_existing'
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: ''
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Would you edit using inpainting based on your selected image")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          yield put(setChatSelection({
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.visualads_regen_img,
            title: "",
            options: targetOptionsList.visualads_regen_img,
            animationClass: 'slide-up'
          }));
        } else {
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'visualads_inpainting_get_img',
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Upload an image you'd like to edit using inpainting")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }

        break;
      case 'change_bg':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Change background")}`,
              editedPrompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: ''
        }));
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'visualads_change_bg',
          }
        }));
        if(stageData.selected_image_url !== ''){ //have selected image
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Here is your generated image")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: ''
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply_url: getFullImageUrl(stageData.selected_image_url), 
                reply_role: 'system',
                ui_type: 'result_existing'
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: ''
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Would you edit using background generation based on your selected image")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          yield put(setChatSelection({
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.visualads_regen_img,
            title: "",
            options: targetOptionsList.visualads_regen_img,
            animationClass: 'slide-up'
          }));
        } else { //no selected image
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              subStage: 'visualads_change_bg_get_img',
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Upload an image you'd like to edit using background generation")}`,
                reply_role: 'system',
              },
              isSuccess: true,
            }
          });
        }
        break;
      default:
        break;
    }
  } else if (target === targetList.visualads_regen_img) {  //regen if got selected
    if(stageData.subStage === 'visualads_new_image'){
      switch (selectedItem.id) {
        case 'yes':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("Yes")}`,
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: "" 
          }));
          yield call(handleVACreateNewImage, chat_id, stageData.selected_image_desc);
          break;
        case 'no':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("No")}`,
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'visualads_option',
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Would you like to revise your images?")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
    
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: false, 
            isListening: false, 
            message: "" 
          }));
      
          yield put(setChatSelection({
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.visualads_option,
            title: "",
            options: targetOptionsList.visualads_option,
            animationClass: 'slide-up'
          }));
          break;
        default:
          break;
      }
    } else if (stageData.subStage === 'visualads_change_bg') {
      switch (selectedItem.id) {
        case 'yes':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("Yes")}`,
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: "" 
          }));
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              adsclone_image_id: '', 
              adsclone_image_url: stageData.adsclone_image_url === '' ?? stageData.selected_image_url,
              adsclone_image_desc: stageData.adsclone_image_desc === '' ?? stageData.selected_image_desc,
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("How would you like the background to look?")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          break;
        case 'no':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("No")}`,
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'visualads_option',
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Would you like to revise your images?")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
    
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: false, 
            isListening: false, 
            message: "" 
          }));
      
          yield put(setChatSelection({
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.visualads_option,
            title: "",
            options: targetOptionsList.visualads_option,
            animationClass: 'slide-up'
          }));
          break;
        default:
          break;
      }
    } else { //visualads_inpainting_mask
      switch (selectedItem.id) {
        case 'yes':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("Yes")}`,
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: "" 
          }));
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              source_url: stageData.selected_image_url,
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Tell me, What object would you like to swap?")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          break;
        case 'no':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("No")}`,
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'visualads_option',
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Would you like to revise your images?")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
    
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: false, 
            isListening: false, 
            message: "" 
          }));
      
          yield put(setChatSelection({
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.visualads_option,
            title: "",
            options: targetOptionsList.visualads_option,
            animationClass: 'slide-up'
          }));
          break;
        default:
          break;
      }
    }
  } else if (target === targetList.visualads_is_img) { 
    switch (selectedItem.id) {
      case 'yes':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("I like it, save this image")}`,
              editedPrompt: 'I like it, save this image',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        const response = yield call(updateChatContentFlagApi, stageData.adsclone_image_id, 'isImage');
        console.log('handleSelectionMode: response', response);
        const { chatDetail } = response.data;
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply_url: getFullImageUrl(stageData.adsclone_image_url),
              reply_role: 'system',
              ui_type: 'result_final',
            },
            isSuccess: true,
          }
        });
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'visualads_revise',
            selected_image_url: chatDetail.reply_url,
            selected_image_desc: chatDetail.edited_reply,
          }
        }));

        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Would you like to revise your images?")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
  
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
    
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: targetList.visualads_revise_option,
          title: "",
          options: targetOptionsList.visualads_revise_option,
          animationClass: 'slide-up'
        }));

        break;
      case 'revise':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("I wanna revise this")}`,
              editedPrompt: 'I wanna revise this',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'visualads_revise',
          }
        }));

        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("How do you want to revise this image?")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
  
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
    
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: targetList.visualads_revise_option,
          title: "",
          options: targetOptionsList.visualads_revise_option,
          animationClass: 'slide-up'
        }));
        //PENDING:: revise using the generated image or (the original prompt / selected_image_desc)?
        // if(stageData.subStage === 'visualads_new_image'){
        //   let prompt = stageData.selected_image_desc || stageData.initial_prompt || stageData.adsclone_image_desc;
        //   yield call(handleVACreateNewImage, chat_id, prompt);
        // } else if (stageData.subStage === 'visualads_change_bg'){
        //   let image_url = (stageData.adsclone_image_url !== '') ? getFullImageUrl(stageData.adsclone_image_url) : '' ;
        //   if(stageData.selected_image_url !== ''){
        //     image_url = getFullImageUrl(stageData.selected_image_url);
        //   }
        //   yield call(handleVAChangeBackground, chat_id, stageData.initial_prompt, image_url);
        // } else { //visualads_inpainting
        //   yield call(handleVAInpainting, chat_id, stageData.initial_prompt);
        // }
        break;
      case 'other':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("I would like to try another option")}`,
              editedPrompt: 'I would like to try another option',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'visualads_option',
          }
        }));

        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("What can I help you in this ?")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
  
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
    
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: targetList.visualads_option,
          title: "",
          options: targetOptionsList.visualads_option,
          animationClass: 'slide-up'
        }));
        break;
      default:
        break;
    }
  } else if (target === targetList.visualads_revise_option) { 
    switch (selectedItem.id) {
      case 'describe':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'visualads_describe',
          }
        }));

        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Describe image")}`,
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me how you want the image to look like?")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      case 'inpainting':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Inpainting")}`,
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'visualads_inpainting_mask',
            source_url: stageData.adsclone_image_url,
            source_desc: stageData.adsclone_image_desc
          }
        }));
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: ''
        }));
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me, What object would you like to swap?")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      case 'change_bg':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Change background")}`,
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'visualads_change_bg',
          }
        }));
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("How would you like the background to look?")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if (target === targetList.copycraft_regen_copy) { 
    if(stageData.subStage === 'copycraft_option'){
      switch (selectedItem.id) {
        case 'yes':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("Yes")}`,
                editedPrompt: '',
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'copycraft_image_upload',
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Upload an image you'd like me to scan and generate a copywriting for you")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          break;
        case 'no':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("No")}`,
                editedPrompt: '',
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'copycraft_option',
            }
          }));

          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Great! What would you like me to assist you on?")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
    
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: false, 
            isListening: false, 
            message: "" 
          }));
      
          yield put(setChatSelection({
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.copycraft_option,
            title: "",
            options: targetOptionsList.copycraft_option,
            animationClass: 'slide-up'
          }));
  
          break;
        default:
          break;
      }
      
    } else if(stageData.subStage === 'copycraft_before_prompt'){
      switch (selectedItem.id) {
        case 'yes':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("Yes")}`,
                editedPrompt: '',
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'copycraft_prompt',
            }
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Tell me more about your product or services that you want to highlight in the copywriting")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
          break;
        case 'no':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("No")}`,
                editedPrompt: '',
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'copycraft_option',
            }
          }));

          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Great! What would you like me to assist you on?")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
    
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: false, 
            isListening: false, 
            message: "" 
          }));
      
          yield put(setChatSelection({
            chat_id: chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.copycraft_option,
            title: "",
            options: targetOptionsList.copycraft_option,
            animationClass: 'slide-up'
          }));
  
          break;
        default:
          break;
      }
    }
  } else if (target === targetList.copycraft_is_copy) { 
    switch (selectedItem.id) {
      case 'yes':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Yes")}`,
              editedPrompt: 'Yes',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        const response = yield call(updateChatContentFlagApi, stageData.adsclone_copy_id, 'isCopy');
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: stageData.adsclone_copy,
              reply_role: 'system',
              ui_type: 'result_final',
            },
            isSuccess: true,
          }
        });
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'copycraft_option',
            selected_copy: stageData.adsclone_copy,
          }
        }));

        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Great! What would you like me to assist you on?")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
  
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
    
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: targetList.copycraft_option,
          title: "",
          options: targetOptionsList.copycraft_option,
          animationClass: 'slide-up'
        }));
        break;
      case 'no':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("No")}`,
              editedPrompt: 'No',
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'copycraft_option',
          }
        }));
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Great! What would you like me to assist you on?")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
  
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
    
        yield put(setChatSelection({
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: targetList.copycraft_option,
          title: "",
          options: targetOptionsList.copycraft_option,
          animationClass: 'slide-up'
        }));

        break;
      default:
        break;
    }
  }  else if (target === 'CopyCraft') {
    
    const selected_ads = options.find(option => option.id === selectedItem.id);
    console.log('handleSelectionMode: selected_ads', selected_ads);
    
    const body = selected_ads.body;
    

    let detail = yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: body, 
          reply_role: 'assistant',
          edited_reply: body,
          ui_type: 'hide'
        },
        isSuccess: true,
      }
    });

    if(stageData.subStage === 'copycraft_prompt_gen'){

      yield put(editChatStage({
        chat_id: chat_id,
        updates: {
          stage: '1',
          subStage: 'copycraft_prompt_select',
          adsclone_copy_id: detail.id,
          adsclone_copy: body
        }
      }));
    } else { //copycraft_image_upload_gen
      yield put(editChatStage({
        chat_id: chat_id,
        updates: {
          stage: '1',
          subStage: 'copycraft_image_upload_select',
          adsclone_copy_id: detail.id,
          adsclone_copy: body
        }
      }));
    }
    

    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: body, 
          reply_role: 'system',
          ui_type: 'result_generate'
        },
        isSuccess: true,
      }
    });
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("Does the copywriting aligns with your vision?")}`,
          reply_role: 'system',
        },
        isSuccess: true,
      }
    });

    yield put(setChatSelection({ 
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: true, 
      target: targetList.copycraft_is_copy,
      title: '',
      options: targetOptionsList.copycraft_is_copy,
      animationClass: 'slide-up'
    }));
    
  } else if (target === targetList.sketchspark_is_result) {
    switch (selectedItem.id) {
      case 'yes':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Yes")}`,
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield call(handleUpdateImageSatisfaction, { payload: { chatDetailId: stageData.adsclone_image_id, image_satisfaction: '1' } });
        const response1 = yield call(updateChatContentFlagApi, stageData.adsclone_copy_id, 'isCopy');
        const response2 = yield call(updateChatContentFlagApi, stageData.adsclone_image_id, 'isImage');

        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: stageData.adsclone_copy,
              reply_url: getFullImageUrl(stageData.adsclone_image_url),
              reply_role: 'system',
              ui_type: 'result_final',
            },
            isSuccess: true,
          }
        });
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'sketchspark_end',
          }
        }));
        break;
      case 'redo':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("No, i would like to redo.")}`,
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'sketchspark_get_img',
            status: 'start',
          }
        }));

        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Upload or take the image of your sketch")}`,
              reply_role: 'assistant' 
            },
            isSuccess: true,
          }
        });

        break;
      case 'regenerate':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("No, i would like to regenerate from the same image.")}`,
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: "" 
          }));
          yield call(handleSketchSpark, chat_id);

          break;
      default:
        break;
    }
  } else if (target === targetList.sketchspark_save_new) {
    switch (selectedItem.id) {
      case 'yes':
        yield call( handleStorePromptGeneral, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: `${i18n.t("Yes")}`,
              promptDate: promptDate,
              promptTime: promptTime
            },
            isSuccess: true,
          }
        });
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: "" 
        }));
        yield call(handleUpdateImageSatisfaction, { payload: { chatDetailId: stageData.adsclone_image_id, image_satisfaction: '1' } });
        const response1 = yield call(updateChatContentFlagApi, stageData.adsclone_copy_id, 'isCopy');
        const response2 = yield call(updateChatContentFlagApi, stageData.adsclone_image_id, 'isImage');

        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: stageData.adsclone_copy,
              reply_url: getFullImageUrl(stageData.adsclone_image_url),
              reply_role: 'system',
              ui_type: 'result_final',
            },
            isSuccess: true,
          }
        });
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'sketchspark_end',
          }
        }));
        break;
      case 'no':
          yield call( handleStorePromptGeneral, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: `${i18n.t("No, i would like to regenerate from the same image.")}`,
                promptDate: promptDate,
                promptTime: promptTime
              },
              isSuccess: true,
            }
          });
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: "" 
          }));
          yield call(handleSketchSpark, chat_id);

          break;
      default:
        break;
    }
  } else if (target === targetList.image_not_satisfy_option) { // Title: -
    switch (selectedItem.id) {
      case '1': //I want to create a new image.
        
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '3',
            subStage: 'recreate_image',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me more about the image that you want.")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      case '2': //regenerate image
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: `${i18n.t("Hang tight! Your image is on its way!")}`
        }));

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '3',
            subStage: 'regenerate_image',
          }
        }));

        yield call( handleStorePrompt, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: '',
              promptDate: promptDate,
              promptTime: promptTime,
            }
          }
        });
        break;
      case '3': //edit image
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
        
        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: chat_detail_id, 
          isDisplay: true, 
          target: targetList.edit_image_part,
          title: '',
          options: targetOptionsList.edit_image_part,
          animationClass: 'slide-up'
        }));
        
        break;
      case '4': //dont want image
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Ok sure, how can I assist you today?")}`,
              reply_role: 'system'
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if (target === targetList.edit_image_part) { // Title: Which image part do you want to change?
    switch (selectedItem.id) {
      case 'edit_image_part_mask':  //change specific item
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '3',
            subStage: 'edit_image_part_mask',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("What do you want to change from the image?")}`,
              reply_role: 'assistant' 
            },
            isSuccess: true,
          }
        });

        break;
      case 'edit_image_part_background': //change background
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '3',
            subStage: 'edit_image_part_background',
            chat_detail_id: chat_detail_id,
          }
        }));
        
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me how do you want your background to look like?")}`,
              reply_role: 'assistant' 
            },
            isSuccess: true,
          }
        });

        break;
      default:
        break;
    }
  } else if (target === targetList.copywriting_strategy) { // Title: Do you want me to provide a more strategize copywriting?
    // chatStages: 2(strategy)

    switch (selectedItem.id) {
      case 'custom strategy':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStageOption: 'custom strategy',
          }
        }));
        //store reply: Tell me your strategy.
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me your strategy")}`, 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        //stage: 
        break;
      case 'no':
        //update isCopy flag for this 
        const response = yield call(updateChatContentFlagApi, chat_detail_id, 'isCopy');
        console.log('handleSelectionMode: response', response);
        const { chatDetail } = response.data;

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
            initial_prompt: '',
            chat_detail_id: '',
            selected_copy: chatDetail.edited_reply,
            status: stageData.selected_image_url === '' ? 'ongoing' : 'complete',
          }
        }));

        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));

        if(stageData.selected_image_url === '') {
          yield delay(200);
          
          yield put(setChatSelection({ 
            chat_id: chatDetail.chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.assist_image,
            title: "I can assist you with the visuals that fits best with your copywriting. Would you like to proceed?",
            options: targetOptionsList.assist_image,
            animationClass: 'slide-up'
          }));
        } else {
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'display_campaign',
            }
          }));

          yield call( handleStoreReply, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Your copy and image is ready!")}`, 
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }
        break;
      default:
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStageOption: selectedItem.id,
          }
        }));
        yield call( handleStorePrompt, { 
          payload: { 
            chatId: chat_id, 
            promptData: {
              prompt: '',
              promptDate: promptDate,
              promptTime: promptTime
            }
          }
        });
        break;
    }
  } else if (target === targetList.revise_strategy_copywriting) { // Title: Would you like me to expand on specific sections or revise this further? Let me know!
    // chatStages: 2(revise)
    switch (selectedItem.id) {
      case '1': //Yes
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("What do you want me to revise?")}`,
              edited_reply: 'What do you want me to revise from this copy?', 
              reply_role: 'assistant' 
            },
            isSuccess: true,
          }
        });
        break;
      case '2': //No
        const response = yield call(updateChatContentFlagApi, chat_detail_id, 'isCopy');
        console.log('handleSelectionMode: response', response);
        const { chatDetail } = response.data;

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
            initial_prompt: '',
            chat_detail_id: '',
            initial_reply: '',
            selected_copy: chatDetail.edited_reply ?? '',
            status: stageData.selected_image_url === '' ? 'ongoing' : 'complete',
          }
        }));

        yield put(setChatLoader({ 
          chat_id: chatDetail.chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));

        if(stageData.selected_image_url === '') {
          yield delay(100);
          yield put(setChatSelection({ 
            chat_id: chatDetail.chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.assist_image,
            title: "I can assist you with the visuals that fits best with your copywriting. Would you like to proceed?",
            options: targetOptionsList.assist_image,
            animationClass: 'slide-up'
          }));
        } else {
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'display_campaign',
            }
          }));

          yield call( handleStoreReply, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Your copy and image is ready!")}`, 
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }
        break;
      default:
        break;
    }
  } else if (target === targetList.assist_copywriting) { // Title: I can assist you with the copywriting that fits best with your visuals. Would you like to proceed?
    // chatStages: 1(initial_prompt)
    switch (selectedItem.id) {
      case '1': //Yes
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'image_to_copy_prompt',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me more about your product")}`, 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      case '2': //No
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("What else can i help you with?")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if (target === targetList.assist_image) { // Title: I can assist you with the visuals that fits best with your copywriting. Would you like to proceed?
    // chatStages: 1(initial_prompt)
    switch (selectedItem.id) {
      case '1': //Yes
        yield delay(100);
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '3',
            subStage: 'initial_image',
            status: 'ongoing',
          }
        }));
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));
        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: chat_detail_id, 
          isDisplay: true, 
          target: targetList.image_sizes,
          title: 'Tell me what is your image size',
          options: targetOptionsList.image_sizes,
          animationClass: 'slide-up'
        }));
        break;
      case '2': //No
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("No problem, how can I assist you today?")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if (target === targetList.image_sizes) { // Title: Tell me what is your image size
    // chatStages: 3(initial_image)
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        image_size: selectedItem.id,
      }
    }));
    yield delay(100);
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));

    if(stageData.image_style === ''){
      yield put(setChatSelection({ 
        chat_id: chat_id, 
        chat_detail_id: chat_detail_id, 
        isDisplay: true, 
        target: targetList.image_styles,
        title: 'Tell me what kind of style you want your image to be like?',
        options: targetOptionsList.image_styles,
        animationClass: 'slide-up'
      }));
    } else {
      yield call( handleStorePrompt, { 
        payload: { 
          chatId: chat_id, 
          promptData: {
            prompt: '',
            promptDate: promptDate,
            promptTime: promptTime
          }
        }
      });
    }
    
  } else if (target === targetList.image_styles) { // Title: Tell me what kind of style you want your image to be like?
    // chatStages: 3(initial_image - ongoing)
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        image_style: selectedItem.id ?? '',
      }
    }));

    yield call( handleStorePrompt, { 
      payload: { 
        chatId: chat_id, 
        promptData: {
          prompt: '',
          promptDate: promptDate,
          promptTime: promptTime
        }
      }
    });
    
  } else if (target === targetList.generate_image) { // Title: Do you want to generate image based on the above content?
    // chatStages: 3(initial_image)
    switch(selectedItem.id) {
      case '1': //Yes
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            status: 'ongoing'
          }
        }));
        break;
      case '2': //No
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
            status: 'ongoing'
          }
        }));
        break;
      default:
        break;
    }
    yield call( handleStorePrompt, { 
      payload: { 
        chatId: chat_id, 
        promptData: {
          prompt: '',
          promptDate: promptDate,
          promptTime: promptTime
        }
      }
    });
  } else if (target === targetList.revise_complete_ads) { // Title: It seems like you are starting a new advertisement for another product, as your digital marketing assistant. I would advise you to 'start a new chat' for each product. Or do you want to revise anything from our discussion so far?
    // chatStages: 1(initial_prompt), 3(initial_image)

    switch(selectedItem.id){
      case 'revise_copy':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'selected_copy_addon',
            status: 'revise',
          }
        }));
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me what do you wanna add to the copywriting.")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
        break;
      case 'revise_image':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '3',
            subStage: 'selected_image_revise',
            status: 'revise',
          }
        }));
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me what do you wanna add to the image.")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if (target === targetList.copywriting_strategy_revise) { // Title: What kind of strategy you would like?
    // chatStages: 1(selected_copy_addon)
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
    switch(selectedItem.id){
      case 'custom strategy':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStage: 'selected_copy_addon_strategy',
            subStageOption: 'custom strategy',
          }
        }));
        //store reply: Tell me your strategy.
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me your strategy")}`, 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      case 'no':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStage: 'selected_copy_addon_strategy',
            subStageOption: '',
          }
        }));
        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: targetList.copywriting_style_revise,
          title: 'What kind of copywriting style you would like?',
          options: targetOptionsList.copywriting_style_revise,
          animationClass: 'slide-up'
        }));
        break;
      default:
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStage: 'selected_copy_addon_strategy',
            subStageOption: 'based on "'+selectedItem.id+'"',
          }
        }));
        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: targetList.copywriting_style_revise,
          title: 'What kind of copywriting style you would like?',
          options: targetOptionsList.copywriting_style_revise,
          animationClass: 'slide-up'
        }));
        break;
    }
  } else if (target === targetList.copywriting_style_revise) { // Title: What kind of copywriting style you would like?
    // chatStages: 1(selected_copy_addon_strategy)
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        subStage: 'selected_copy_addon_style',
        initial_reply: selectedItem.id,
      }
    }));
    yield call( handleStorePrompt, { 
      payload: { 
        chatId: chat_id, 
        promptData: {
          prompt: '',
          promptDate: promptDate,
          promptTime: promptTime
        }
      }
    });
  } else if (target === targetList.upload_image_options) { // Title: I've notice you have uploaded an image, what do you want me to help you with?
      switch(selectedItem.id){
        case 'edit_image_part_mask':
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '3',
              subStage: 'edit_image_part_mask',
            }
          }));
  
          yield call( handleStoreReply, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("What do you want to change from the image?")}`,
                reply_role: 'assistant' 
              },
              isSuccess: true,
            }
          });
          break;
        case 'edit_image_part_background':
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '3',
              subStage: 'edit_image_part_background',
              chat_detail_id: chat_detail_id,
            }
          }));
          
          yield call( handleStoreReply, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Tell me how do you want your background to look like?")}`,
                reply_role: 'assistant' 
              },
              isSuccess: true,
            }
          });
          break;
        case 'regenerate_image':
          yield put(setChatLoader({ 
            chat_id: chat_id, 
            isLoading: true, 
            isListening: false, 
            message: `${i18n.t("Hang tight! Your image is on its way!")}`
          }));
  
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '3',
              subStage: 'regenerate_image',
            }
          }));
  
          yield call( handleStorePrompt, { 
            payload: { 
              chatId: chat_id, 
              promptData: {
                prompt: '',
                promptDate: promptDate,
                promptTime: promptTime,
              }
            }
          });
          break;
        default:
          break;
      }
  } else if (target === targetList.set_is_image) {
    switch(selectedItem.id){
      case 'yes':
        yield call(handleUpdateImageSatisfaction, { payload: { chatDetailId: chat_detail_id, image_satisfaction: '1' } });
        const response = yield call(updateChatContentFlagApi, chat_detail_id, 'isImage');
        console.log('handleSelectionMode: response', response);
        const { chatDetail } = response.data;

        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: false, 
          isListening: false, 
          message: "" 
        }));

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
            initial_prompt: '',
            chat_detail_id: '',
            image_size: '',
            image_style: '',
            selected_image_url: chatDetail.reply_url,
            selected_image_desc: chatDetail.edited_reply,
            status: stageData.selected_copy === '' ? 'ongoing' : 'complete',
          }
        }));
        
        if(stageData.selected_copy === '') {
          yield delay(100);
          yield put(setChatSelection({ 
            chat_id: chatDetail.chat_id, 
            chat_detail_id: '', 
            isDisplay: true, 
            target: targetList.assist_copywriting,
            title: "Would you like me to help you, generate a copywriting based on your new image?",
            options: targetOptionsList.assist_copywriting,
            animationClass: 'slide-up'
          }));
        } else {
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'display_campaign',
            }
          }));

          yield call( handleStoreReply, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Your copy and image is ready!")}`, 
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }

        break;
      case 'no':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t('How else can i help you?')}`, 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if (target === targetList.create_more_ads) {
    switch(selectedItem.id){
      case 'yes':

        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'more_ads_suggestion',
          }
        }));

        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: targetList.ads_suggestion_copy_strategy,
          title: 'What kind of strategy you would like?',
          options: targetOptionsList.ads_suggestion_copy_strategy,
          animationClass: 'slide-up'
        }));
        // yield call( handleCreateMoreAds, {payload: {chat_id: chat_id}});

        break;
      case 'no':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'initial_prompt',
          }
        }));

        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t('How else can i help you?')}`, 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  } else if (target === targetList.ads_suggestion_copy_strategy) { // Title: What kind of strategy you would like?
    // chatStages: 1(more_ads_suggestion)

    switch(selectedItem.id){
      case 'custom strategy':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStage: 'ads_suggestion_strategy',
          }
        }));
        //store reply: Tell me your strategy.
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Tell me your strategy")}`, 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      case 'no':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStage: 'ads_suggestion_strategy',
            subStageOption: '',
          }
        }));
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: `${i18n.t("Please hold on! We're working on creating more ads for you.")}`
        }));

        yield put(setBottomRefTrigger(Date.now()));
        yield call( handleCreateMoreAds, {payload: {chat_id: chat_id}});
        break;
      default:
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            subStage: 'ads_suggestion_strategy',
            subStageOption: 'based on "'+selectedItem.id+'"',
          }
        }));
        yield put(setChatLoader({ 
          chat_id: chat_id, 
          isLoading: true, 
          isListening: false, 
          message: `${i18n.t("Please hold on! We're working on creating more ads for you.")}`
        }));

        yield put(setBottomRefTrigger(Date.now()));
        yield call( handleCreateMoreAds, {payload: {chat_id: chat_id}});
        break;
    }
  } else if (target === 'Ads Suggestion') {
    
    const selected_ads = options.find(option => option.id === selectedItem.id);
    console.log('handleSelectionMode: selected_suggested_ads', selected_ads);
    
    const selected_copy = selected_ads.body;
    let selected_image_url = '';
    let selected_image_desc = '';

    let replyData = []; // Declare an empty array
    //set reply date and time
    const now = moment(); // Get current time
    const replyDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
    const replyTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

    if(selected_ads.image_url !== ''){
      selected_image_url = selected_ads.image_url;

      // call objreg for image url
      const recognitionResponse = yield call(aiImageRecognitionApi, selected_image_url);
      console.log('handleSelectionMode: recognitionResponse', recognitionResponse);

      selected_image_desc = recognitionResponse.data;
      
      // Append to replyData using spread operator
      replyData = [...replyData, {
        reply_url: selected_image_url, 
        reply_role: 'assistant',
        edited_reply: selected_image_desc,
        isImage: '1',
        reply_date: replyDate,
        reply_time: replyTime,
      }];
    }

    replyData = [...replyData, {
      reply: selected_copy, 
      reply_role: 'assistant',
      edited_reply: selected_copy,
      isCopy: '1',
      reply_date: replyDate,
      reply_time: replyTime,
    }];

    // editChatStage: substage(select_adslib), initial_prompt, selected_copy, selected_image_url
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        stage: '1',
        subStage: 'select_suggested_ads',
        selected_copy: selected_copy,
        selected_image_url: selected_image_url,
        selected_image_desc: selected_image_desc,
      }
    }));

    // storeBulkReply
    yield call( handleStoreBulkReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: replyData,
      }
    });
  } else if (target === targetList.revise_suggested_ads){
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));

    switch(selectedItem.id){
      case 'yes':
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'selected_copy_addon',
            status: 'revise',
          }
        }));
        yield put(setChatSelection({ 
          chat_id: chat_id, 
          chat_detail_id: '', 
          isDisplay: true, 
          target: targetList.revise_complete_ads,
          title: "Tell me what do you wanna add to the copywriting.",
          options: targetOptionsList.revise_complete_ads,
          animationClass: 'slide-up'
        }));
        break;
      case 'no':
        
      yield put(editChatStage({
        chat_id: chat_id,
        updates: {
          stage: '1',
          subStage: 'initial_prompt',
          status: 'complete',
        }
      }));
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t('How else can i help you?')}`, 
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
        break;
      default:
        break;
    }
  }
  
}
// glynnis 
// The saga to handle beforeInitial
// function* handleBeforeInitial(action) {
//   const { chatId } = action.payload;
//   try {
//     // Step 1: Select promptData from smartscan_keyword subStage
//     const promptData = yield select(selectPromptData, chatId, 'smartscan_keyword');

//     if (!promptData) {
//       throw new Error('Prompt data not found for smartscan_keyword subStage.');
//     }

//     const productKeyword = promptData.prompt; // Assuming prompt contains the keyword

//     // Step 2: Make API call to store product_keyword
//     const response = yield call(storeProductKeywordApi, chatId, productKeyword);

//     // Step 3: Dispatch success action with response data
//     yield put(beforeInitialSuccess(chatId, response.data));

//     // Optionally, you can navigate to the next subStage or perform additional actions
//   } catch (error) {
//     console.error('handleBeforeInitial Saga Error:', error);

//     // Step 4: Dispatch failure action with error message
//     yield put(beforeInitialFailure(chatId, error.message || 'Unknown error'));

//     // Optionally, you can notify the user about the error
//   }
// }

function* handleUploadImage(action) {
  console.log('chatSagas: handleUploadImage', action);

  const { chatId, promptData } = action.payload;

  try {
    const stageList = yield select(selectChatStage, chatId);
    const stageData = stageList[chatId];
    console.log('handleUploadImage: stageData', stageData);

    let prompt_reg = '';
    if (stageData.subStage === 'copycraft_image_upload' || stageData.subStage === 'visualads_new_image' || stageData.subStage === 'visualads_change_bg_get_img' || stageData.subStage === 'visualads_inpainting_get_img') {
      //PENDING:: loading message
    } else if(stageData.subStage === 'sketchspark_get_img'){
      //PENDING:: loading message
      prompt_reg = 'What do you see? give me a detailed real-life version of image generation prompt to turn this sketch into a real image. Give me only the image generation prompts. must highlight to turn the sketch into a reaslistic and real life image. i do not want an animated image';
    } else if(stageData.subStage !== 'brandboost_keyword'){
      yield put(setChatLoader({ 
        chat_id: chatId, 
        isLoading: true, 
        isListening: false, 
        message: `${i18n.t("Hang tight! Your image is on its way!")}`
      }));
    }

    let updatedPromptData = {
      ...promptData,
      prompt_api_called: `${process.env.MARVIS_IMAGE_FLUX}`,
      // edited_prompt: promptData.prompt,
    };

    // store reply
    console.log('handleUploadImage: updatedPromptData', updatedPromptData);
    const uploadImageResponse = yield call(uploadImageApi, chatId, updatedPromptData);
    console.log('handleUploadImage: uploadImageResponse', uploadImageResponse);

    const { chatDetailImage, chatDetailDesc, chat_memory_image, chat_memory_desc, sufficient_credit } = uploadImageResponse.data;

    yield put(appendCurrentChatDetails(Array.isArray(chatDetailImage) ? chatDetailImage : [chatDetailImage]));
    // yield put(appendCurrentChatDetails(Array.isArray(chatDetailDesc) ? chatDetailDesc : [chatDetailDesc]));

    yield put(setBottomRefTrigger(Date.now()));

    //call object recognition
    let prompt_url = chatDetailImage.prompt_url ? getFullImageUrl(chatDetailImage.prompt_url) : '';
    if(prompt_url.includes("http://127.0.0.1:8000")) {
      prompt_url = 'https://images.nightcafe.studio/jobs/dDobcxsvhPdi8nsYyxKR/dDobcxsvhPdi8nsYyxKR--1--a6f3y.jpg';//'https://www.coca-cola.com/content/dam/onexp/us/en/homepage/usa_home_1_campaign_card_1280x1024.jpg/width1960.jpg';
    }
    try {
      const recognitionResponse = yield call(aiImageRecognitionApi, prompt_url, prompt_reg);
      console.log('handleUploadImage: recognitionResponse', recognitionResponse);

      //update edited_prompt for chatDetailImage
      let data = {
        edited_prompt: recognitionResponse.data,
      };
      const updateResponse = yield call(updateChatDetailApi, chatDetailImage.id, data);
      const { updateData, chat_memory } = updateResponse.data;
      console.log('handleUploadImage: updateResponse', updateResponse);

      //update chat detail for image
      yield put(editCurrentChatDetails({
        chat_id: chatDetailImage.chat_id,
        chat_detail_id: chatDetailImage.id,
        updatedData: updateData
      }));

      // update active time
      yield put(editCurrentChatData({ field: 'last_active_datetime', value: chatDetailImage.updated_at }));
      
      // append chat memory for image
      if(chat_memory) {
        yield put(appendChatMemory({
          chat_id: chatId,
          chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
        }));
      }

      // append chat memory for desc
      if(chat_memory_desc) {
        yield put(appendChatMemory({
          chat_id: chatId,
          chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
        }));
      }

      yield delay(100); // Optional delay

      if(sufficient_credit) {  
        let prompt = '';
        if(stageData.campaign_type === 'BrandBoost'){
          if(stageData.subStage === 'brandboost_keyword'){
            // prompt = 'what is this product in general, the product must fit facebook keywords. just response without any additional text';
            // prompt = 'what is this product in general and what is the industry it is in, the product must fit facebook keywords. give only one word in string.';
            prompt = 'what is this product in general, the product must fit facebook keywords. just response without any additional text. i want only one word';

            let recognitionResponse = yield call(aiImageRecognitionApi, prompt_url, prompt);
            console.log('handleUploadImage: recognitionResponse keyword', recognitionResponse);
  
            //update edited_prompt for chatDetailImage
            let product_keyword = recognitionResponse.data;
            product_keyword = product_keyword.replace(/\n/g, " ");

            console.log('handleUploadImage: product_keyword', recognitionResponse);
  
            yield call(updateProductKeywordApi, chatId, product_keyword);
  
            yield put(editChatStage({
              chat_id: chatId,
              updates: {
                stage: '1',
                subStage: 'brandboost_scan',
              }
            }));
  
            yield put(editBrandBoostDetail({
              chat_id: chatId,
              updates: {
                keyword_approach: 'image',
                keyword_url: prompt_url,
                facebook_keyword: product_keyword,
              }
            }));
            yield call(handleBrandBoost, chatId);
          }
        } else if(stageData.campaign_type === 'CopyCraft'){
          yield put(editChatStage({
            chat_id: chatId,
            updates: {
              stage: '1',
              subStage: 'copycraft_image_upload_gen',
              adsclone_image_desc: recognitionResponse.data
            }
          }));
          yield put(setChatLoader({ 
            chat_id: chatId, 
            isLoading: true, 
            isListening: false, 
            message: ''
          }));
          yield call(handleCreateMoreCopyCraft,{ 
            payload: { 
              chat_id: chatId, 
            }
          });
        } else if(stageData.campaign_type === 'VisualAds'){
          if(stageData.subStage === 'visualads_change_bg_get_img'){
            yield put(editChatStage({
              chat_id: chatId,
              updates: {
                stage: '1',
                subStage: 'visualads_change_bg',
                adsclone_image_url: chatDetailImage.prompt_url,
                adsclone_image_desc: recognitionResponse.data
              }
            }));
            yield put(setChatLoader({ 
              chat_id: chatId, 
              isLoading: true, 
              isListening: false, 
              message: ''
            }));
            yield call( handleStoreReplyGeneral, { 
              payload: { 
                chatId: chatId, 
                replyData: {
                  chat_detail_id: '',
                  reply: `${i18n.t("How would you like the background to look?")}`,
                  reply_role: 'system' 
                },
                isSuccess: true,
              }
            });
          } else if (stageData.subStage === 'visualads_inpainting_get_img') {
            yield put(editChatStage({
              chat_id: chatId,
              updates: {
                stage: '1',
                subStage: 'visualads_inpainting_mask',
                source_url: chatDetailImage.prompt_url,
                source_desc: recognitionResponse.data
              }
            }));
            yield put(setChatLoader({ 
              chat_id: chatId, 
              isLoading: true, 
              isListening: false, 
              message: ''
            }));
            yield call( handleStoreReplyGeneral, { 
              payload: { 
                chatId: chatId, 
                replyData: {
                  chat_detail_id: '',
                  reply: `${i18n.t("Tell me, What object would you like to swap?")}`,
                  reply_role: 'system' 
                },
                isSuccess: true,
              }
            });
          }
        } else if(stageData.campaign_type === 'SketchSpark'){
          if(stageData.subStage === 'sketchspark_get_img'){
            yield put(editChatStage({
              chat_id: chatId,
              updates: {
                stage: '1',
                subStage: 'sketchspark_keyword',
                source_url: chatDetailImage.prompt_url,
                source_desc: recognitionResponse.data
              }
            }));
            yield put(setChatLoader({ 
              chat_id: chatId, 
              isLoading: true, 
              isListening: false, 
              message: ''
            }));
            
            yield call( handleStoreReplyGeneral, { 
              payload: { 
                chatId: chatId, 
                replyData: {
                  chat_detail_id: '',
                  reply: `${i18n.t("What is your product keyword?")}`,
                  reply_role: 'system',
                },
                isSuccess: true,
              }
            });
            // yield call(handleSketchSpark, chatId);

          } 
        } else {
          yield put(setChatLoader({ 
            chat_id: chatId, 
            isLoading: true, 
            isListening: false, 
            message: `${i18n.t("Hang tight! Your image is on its way!")}`
          }));
  
          yield put(editChatStage({
            chat_id: chatId,
            updates: {
              stage: '3',
              initial_prompt: chatDetailImage.edited_prompt,
              source_url: chatDetailImage.prompt_url,
              source_desc: recognitionResponse.data,
              subStage: 'upload_image',
            }
          }));
          
          yield call(handleTextToImage, chatDetailImage);
        }
      } else {
        yield put(setIsSufficientCredit(false));
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chatId, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("errors.INSUFFICIENT_BALANCE")}`, 
              reply_role: 'system' 
            },
            isSuccess: false
          }
        });
        yield put(setBottomRefTrigger(Date.now()));
      }
    } catch (error) {
      console.error('handleUploadImage Error:', error);
      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatId, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
            reply_role: 'system' 
          },
          isSuccess: false,
        }
      });
    }
  } catch (error) {
    if (error.response && error.response.data) {
      console.log("handleUploadImage Server Error: ", error.response.data);
      // setServerErrors(error.response.data);
    } else {
      console.error('handleUploadImage Error:', error);
    }
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatId, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));
  }
}

function* handleCamera(action) {
  console.log('chatSagas: handleCamera', action);

  const { chatId, promptData } = action.payload;

  try {
    const stageList = yield select(selectChatStage, chatId);
    const stageData = stageList[chatId];
    console.log('handleCamera: stageData', stageData);

    let prompt_reg = '';
    if (stageData.subStage === 'copycraft_image_upload' || stageData.subStage === 'visualads_new_image' || stageData.subStage === 'visualads_change_bg_get_img' || stageData.subStage === 'visualads_inpainting_get_img') {
      //PENDING:: loading message
    } else if(stageData.subStage === 'sketchspark_get_img'){
      //PENDING:: loading message
      prompt_reg = 'What do you see? give me a detailed real-life version of image generation prompt to turn this sketch into a real image. Give me only the image generation prompts. must highlight to turn the sketch into a reaslistic and real life image. i do not want an animated image';
    } else if(stageData.subStage !== 'brandboost_keyword'){
      yield put(setChatLoader({ 
        chat_id: chatId, 
        isLoading: true, 
        isListening: false, 
        message: `${i18n.t("Hang tight! Your image is on its way!")}`
      }));
    }

    let updatedPromptData = {
      ...promptData,
      prompt_api_called: `${process.env.MARVIS_IMAGE_FLUX}`,
    };

    // store reply
    console.log('handleCamera: updatedPromptData', updatedPromptData);
    const uploadImageResponse = yield call(uploadImageApi, chatId, updatedPromptData);
    console.log('handleCamera: uploadImageResponse', uploadImageResponse);

    const { chatDetailImage, chat_memory_image, sufficient_credit } = uploadImageResponse.data;

    yield put(appendCurrentChatDetails(Array.isArray(chatDetailImage) ? chatDetailImage : [chatDetailImage]));
    yield put(setBottomRefTrigger(Date.now()));
    
    //call object recognition
    let prompt_url = chatDetailImage.prompt_url ? getFullImageUrl(chatDetailImage.prompt_url) : '';
    if(backend_url === "http://127.0.0.1:8000") {
      prompt_url = 'https://images.squarespace-cdn.com/content/v1/616cfb6a0343cf58c0a570b5/c5505422-d6f5-4817-ad8d-a80bf328af06/IMG_1463.PNG';//'https://www.coca-cola.com/content/dam/onexp/us/en/homepage/usa_home_1_campaign_card_1280x1024.jpg/width1960.jpg';
    }
    const recognitionResponse = yield call(aiImageRecognitionApi, prompt_url, prompt_reg);
    console.log('handleCamera: recognitionResponse', recognitionResponse);

    //update edited_prompt for chatDetailImage
    let data = {
      edited_prompt: recognitionResponse.data,
    };

    const updateResponse = yield call(updateChatDetailApi, chatDetailImage.id, data);
    const { updateData, chat_memory } = updateResponse.data;
    console.log('handleCamera: updateResponse', updateResponse);

    //update chat detail for image
    yield put(editCurrentChatDetails({
      chat_id: chatDetailImage.chat_id,
      chat_detail_id: chatDetailImage.id,
      updatedData: updateData
    }));

    // let updatedChatDetailImage = {
    //   ...chatDetailImage,
    //   edited_prompt: recognitionResponse.data ?? '',
    // };
    // update active time
    yield put(editCurrentChatData({ field: 'last_active_datetime', value: updateData.updated_at }));
    
    if(chat_memory) {
      yield put(appendChatMemory({
        chat_id: chatId,
        chat_memory: Array.isArray(chat_memory) ? chat_memory : [chat_memory],
      }));
    }

    yield delay(100); // Optional delay

    if(sufficient_credit) {  
      let prompt = '';
      if(stageData.campaign_type === 'BrandBoost'){
        if(stageData.subStage === 'brandboost_keyword'){
          // prompt = 'what is this product in general, the product must fit facebook keywords. just response without any additional text';
          // prompt = 'what is this product in general and what is the industry it is in, the product must fit facebook keywords. just one word.';
          prompt = 'what is this product in general, the product must fit facebook keywords. just response without any additional text. i want only one word';

          let recognitionResponse = yield call(aiImageRecognitionApi, prompt_url, prompt);
          console.log('handleCamera: recognitionResponse product_keyword', recognitionResponse);

          //update edited_prompt for chatDetailImage
          let product_keyword = recognitionResponse.data;

          yield call(updateProductKeywordApi, chatId, product_keyword);

          yield put(editChatStage({
            chat_id: chatId,
            updates: {
              stage: '1',
              subStage: 'brandboost_scan',
            }
          }));

          yield put(editBrandBoostDetail({
            chat_id: chatId,
            updates: {
              keyword_approach: 'image',
              keyword_url: prompt_url,
              facebook_keyword: product_keyword,
            }
          }));
          yield call(handleBrandBoost, chatId);
        }
      } else if(stageData.campaign_type === 'CopyCraft'){
        yield put(editChatStage({
          chat_id: chatId,
          updates: {
            stage: '1',
            subStage: 'copycraft_image_upload_gen',
            adsclone_image_desc: recognitionResponse.data
          }
        }));
        yield call(handleCreateMoreCopyCraft,{ 
          payload: { 
            chat_id: chatId, 
          }
        });
      } else if(stageData.campaign_type === 'VisualAds'){
        if(stageData.subStage === 'visualads_change_bg_get_img'){
          yield put(editChatStage({
            chat_id: chatId,
            updates: {
              stage: '1',
              subStage: 'visualads_change_bg',
              adsclone_image_url: chatDetailImage.prompt_url,
              adsclone_image_desc: recognitionResponse.data
            }
          }));
          yield put(setChatLoader({ 
            chat_id: chatId, 
            isLoading: true, 
            isListening: false, 
            message: ''
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chatId, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("How would you like the background to look?")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        } else if (stageData.subStage === 'visualads_inpainting_get_img') {
          yield put(editChatStage({
            chat_id: chatId,
            updates: {
              stage: '1',
              subStage: 'visualads_inpainting_mask',
              source_url: chatDetailImage.prompt_url,
              source_desc: recognitionResponse.data
            }
          }));
          yield put(setChatLoader({ 
            chat_id: chatId, 
            isLoading: true, 
            isListening: false, 
            message: ''
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chatId, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Tell me, What object would you like to swap?")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }
      } else if(stageData.campaign_type === 'SketchSpark'){
        if(stageData.subStage === 'sketchspark_get_img'){
          yield put(editChatStage({
            chat_id: chatId,
            updates: {
              stage: '1',
              subStage: 'sketchspark_keyword',
              source_url: chatDetailImage.prompt_url,
              source_desc: recognitionResponse.data
            }
          }));
          yield put(setChatLoader({ 
            chat_id: chatId, 
            isLoading: true, 
            isListening: false, 
            message: ''
          }));
          yield call( handleStoreReplyGeneral, { 
            payload: { 
              chatId: chatId, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("What is your product keyword?")}`,
                reply_role: 'system',
              },
              isSuccess: true,
            }
          });
          // yield call(handleSketchSpark, chatId);
        } 
      } else {
        yield put(setChatLoader({ 
          chat_id: chatId, 
          isLoading: false, 
          isListening: false, 
          message: ''
        }));

        yield put(editChatStage({
          chat_id: chatId,
          updates: {
            stage: '3',
            initial_prompt: '',
            source_url: chatDetailImage.prompt_url,
            source_desc: recognitionResponse.data,
            subStage: 'upload_image',
          }
        }));
      
        // yield call(handleTextToImage, updatedChatDetailImage);
        yield put(setChatSelection({ 
          chat_id: chatDetailImage.chat_id, 
          isDisplay: true, 
          chat_detail_id: chatDetailImage.id,
          target: targetList.upload_image_options,
          title: " I’ve noticed you have taken a picture, what do you want me to help you with?",
          options: targetOptionsList.upload_image_options,
          animationClass: 'slide-up'
        }));
      }
    } else {
      yield put(setIsSufficientCredit(false));
      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetailImage.chat_id, 
          replyData: {
            chat_detail_id: chatDetailImage.id,
            reply: `${i18n.t("errors.INSUFFICIENT_BALANCE")}`, 
            reply_role: 'system' 
          },
          isSuccess: false,
        }
      });
      yield put(setBottomRefTrigger(Date.now()));
    }
  } catch (error) {
    if (error.response && error.response.data) {
      console.log("handleCamera Server Error: ", error.response.data);
      // setServerErrors(error.response.data);
    } else {
      console.error('handleCamera Error:', error);
    }
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatId, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chatId, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));

  }
}

function* handleLoadMoreAds(action) {
  const { chat_id, end_cursor } = action.payload;

  yield put(setLoadingMoreAds({ chat_id: chat_id, isLoading: true }));

  //call ads library with end cursor
  yield call(handleAdsLibrary, chat_id, end_cursor);

}

function* handleCreateMoreAds(action) {
  const { chat_id } = action.payload;

  const stageList = yield select(selectChatStage, chat_id);
  const stageData = stageList[chat_id];
  console.log('handleCreateMoreAds: stageData', stageData);

  const chatSelection = yield select(selectChatSelectionForCurrentChat);
  console.log('handleCreateMoreAds: chatSelection', chatSelection);

  yield put(setLoadingMoreAds({ chat_id: chat_id, isLoading: true }));

  //get chat details
  const chatByIdResponse = yield call(getChatByIdApi, chat_id);
  const { chat } = chatByIdResponse.data;
  console.log('handleCreateMoreAds: chatByIdResponse', chatByIdResponse);

  // provide 4 diff style and image
  const styles = [
    { id: 1, name: 'AIDA' },
    { id: 2, name: 'PAS' },
    { id: 3, name: '4Cs' },
    { id: 4, name: 'FAB' },
    { id: 5, name: 'ACC' },
    { id: 6, name: 'SLAP' },
  ];

  let options = [];
  let index = 0;

  if(chatSelection.end_cursor !== '') { // not first load
    options = chatSelection.options;
    index = parseInt(chatSelection.end_cursor);
  }

  let chat_logo_url = chat.chat_logo_url ? getFullImageUrl(chat.chat_logo_url) : '';
  let chat_brand_name = chat.chat_brand_name ?? '';

  for (const style of styles) {
    console.log('handleCreateMoreAds: strategy', style);

    // Construct the copy prompt based on the style
    let copy_prompt = `Write me a strategize copywriting ${stageData.subStageOption} with "${style.name}" on my copywriting ${stageData.selected_copy}`;
    console.log('handleCreateMoreAds: copy_prompt', copy_prompt);

    // Call chat API to generate the copy
    let generated_copy = yield call(handleChatbotGeneral, chat_id, copy_prompt);
    console.log('handleCreateMoreAds: generated_copy', generated_copy);

    // Construct the image prompt based on the strategy
    let image_prompt = "Generate relevant winning advertisement image based on this copy '"+generated_copy+"'. All text should be readable in english language.";
    console.log('handleCreateMoreAds: image_prompt', image_prompt);

    // Call text-to-image API to generate the image
    let generated_image_url = yield call(handleTextToImageGeneral, chat_id, image_prompt, '');
    console.log('handleCreateMoreAds: generated_image_url', generated_image_url);

    // Create a new ad option with the generated copy and image
    let generated_ads = {
      id: index++,
      body: generated_copy, 
      image_url: generated_image_url,
      profile_pic_url: chat_logo_url,
      title: '', 
      page_name: chat_brand_name,
      like_count: 10,
      comment_count: 10,
      share_count: 10,
    };
  
    // Append the new ad option to the options array
    options = [...options, generated_ads];
  }
  
  console.log('handleCreateMoreAds: options', options);

  yield put(setChatLoader({ 
    chat_id: chat_id, 
    isLoading: false, 
    isListening: false, 
    message: "" 
  }));

  yield put(setLoadingMoreAds({ chat_id: chat_id, isLoading: false }));

  yield put(setChatSelection({ 
    chat_id: chat_id, 
    chat_detail_id: '', 
    isDisplay: true, 
    target: 'Ads Suggestion',
    title: 'Choose the ads you\'d prefer.',
    options: options,
    end_cursor: index,
    animationClass: 'slide-up'
  }));
}

function* handleCreateMoreCopyCraft(action) {
  const { chat_id } = action.payload;

  const stageList = yield select(selectChatStage, chat_id);
  const stageData = stageList[chat_id];
  console.log('handleCreateMoreCopyCraft: stageData', stageData);

  const chatSelection = yield select(selectChatSelectionForCurrentChat);
  console.log('handleCreateMoreCopyCraft: chatSelection', chatSelection);

  yield put(setLoadingMoreAds({ chat_id: chat_id, isLoading: true }));

  //get chat details
  const chatByIdResponse = yield call(getChatByIdApi, chat_id);
  const { chat } = chatByIdResponse.data;
  console.log('handleCreateMoreCopyCraft: chatByIdResponse', chatByIdResponse);

  // provide 4 diff style and image
  const styles = [
    { id: 1, name: 'AIDA' },
    { id: 2, name: 'PAS' },
    { id: 3, name: '4Cs' },
    { id: 4, name: 'FAB' },
    { id: 5, name: 'ACC' },
    { id: 6, name: 'SLAP' },
  ];

  let options = [];
  let index = 0;

  if(chatSelection.end_cursor !== '') { // not first load
    options = chatSelection.options;
    index = parseInt(chatSelection.end_cursor);
  }

  let chat_logo_url = chat.chat_logo_url ? getFullImageUrl(chat.chat_logo_url) : '';
  let chat_brand_name = chat.chat_brand_name ?? '';

  for (const style of styles) {
    console.log('handleCreateMoreCopyCraft: strategy', style);

    // Construct the copy prompt based on the style
    // let copy_prompt = `Write me a strategize copywriting with "${style.name}" style, based on ${stageData.adsclone_image_desc}`;
    // console.log('handleCreateMoreCopyCraft: copy_prompt', copy_prompt);
    // let copy_prompt = `Generate a strategize copywriting based on "${style.name}" to align with brand name, "${chat.chat_brand_name}" and the business type is 
    // "${chat.chat_brand_business_type}". Description is "${stageData.adsclone_image_desc}". Dont give any heading or extra explanation, just provide the copywriting which can be post directly to social media.`;
    // let copy_prompt = `Generate a creative and effective copywriting highlighting the main subject in the image. I want the copywriting to be written in the "${style.name}" framework. Please just give me the copywriting without explanation. and make it in a paragraph, no points or listing. Based on this image description: "${stageData.adsclone_image_desc}"`;
    let copy_prompt = `Generate a creative and effective copywriting highlighting the main subject in the image. I want the copywriting to be written in the ${style.name} framework. Please just give me the copywriting without explanation. arrange it in proper paragraph and add in emojis. do not show the format title or guidance in the content. Based on this image description: "${stageData.adsclone_image_desc}"`;
    // Call chat API to generate the copy
    let generated_copy = yield call(handleChatbotGeneral, chat_id, copy_prompt);
    console.log('handleCreateMoreCopyCraft: generated_copy', generated_copy);

    // // Construct the image prompt based on the strategy
    // let image_prompt = "Generate relevant winning advertisement image based on this copy '"+generated_copy+"'. All text should be readable in english language.";
    // console.log('handleCreateMoreAds: image_prompt', image_prompt);

    // // Call text-to-image API to generate the image
    // let generated_image_url = yield call(handleTextToImageGeneral, chat_id, image_prompt, '');
    // console.log('handleCreateMoreAds: generated_image_url', generated_image_url);

    // Create a new ad option with the generated copy and image
    let generated_ads = {
      id: index++,
      body: generated_copy, 
      image_url: null,
      profile_pic_url: chat_logo_url,
      title: '', 
      page_name: chat_brand_name,
      like_count: 10,
      comment_count: 10,
      share_count: 10,
    };
  
    // Append the new ad option to the options array
    options = [...options, generated_ads];
  }
  
  console.log('handleCreateMoreCopyCraft: options', options);

  yield put(setChatLoader({ 
    chat_id: chat_id, 
    isLoading: false, 
    isListening: false, 
    message: "" 
  }));

  yield put(setLoadingMoreAds({ chat_id: chat_id, isLoading: false }));
  yield put(setChatSelection({ 
    chat_id: chat_id, 
    chat_detail_id: '', 
    isDisplay: true, 
    target: 'CopyCraft',
    title: `${i18n.t("Here are the copywriting examples based on six frameworks. Which one aligns best with your vision?")}`,
    options: options,
    end_cursor: index,
    animationClass: 'slide-up'
  }));
}

// Handle Backend API end------------------------------------------- //
// Handle Marvis API ----------------------------------------------- //
function* handleSmartScan(chat_id) {
  console.log('chatSagas: handleSmartScan');

  try {
    const currentChatData = yield select(selectCurrentChatData);
    console.log('handleSmartScan: currentChatData', currentChatData);

    const apiParameter = {
      keywords: currentChatData.product_keyword,
      ads_location: currentChatData.ads_location,
      platform: currentChatData.platform,
    }
    const aiResponse = yield call(adsLibraryApi, apiParameter);
    console.log('handleSmartScan: aiResponse', aiResponse);

    const edges = aiResponse.data.data.ad_library_main.search_results_connection.edges;
    console.log('handleSmartScan: edges', edges);
    
    if (edges.length === 0) {
      yield put(editChatStage({
        chat_id: chat_id,
        updates: {
          stage: '1',
          subStage: 'no_adslib',
          status: 'start',
        }
      }));

      yield call( handleStoreReply, { 
        payload: { 
          chatId: chat_id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("There are currently no similar advertisements for your products. However, no need to worry—I'd be happy to assist you in creating your winning ads!")}`,
            reply_role: 'system' 
          },
          isSuccess: true,
        }
      });
    } else {
      yield put(setChatLoader({ 
        chat_id: chat_id, 
        isLoading: false, 
        isListening: false, 
        message: "" 
      }));

      const options = edges.map((edge, index) => {
        const snapshot = edge.node.collated_results[0].snapshot || {}; // Fallback to an empty object
        // console.log('handleSmartScan: snapshot', snapshot);

        const item = {
          id: (index + 1).toString(), // Assign unique ids starting from 1
          body: snapshot?.body?.text || '', // Use optional chaining to safely access text
          image_url: snapshot?.images?.[0]?.resized_image_url || '', // Safely access the first image or fallback to an empty string
          profile_pic_url: snapshot?.page_profile_picture_url || '',
          title: snapshot?.title || '',
          page_name: snapshot?.page_name || '',
          like_count: 10,
          comment_count: 10,
          share_count: 10,
        };
    
  
        return item;
      });

      console.log('options', options);
      // yield put(setChatOption({ chat_id: chat_id, options: options }));

      yield put(setChatSelection({ 
        chat_id: chat_id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: 'Ads Library',
        title: '',
        options: options,
        animationClass: 'slide-up'
      }));
    }
  } catch (error) {
    console.error('handleSmartScan Saga Error:', error);

    // yield call( handleStoreReply, { 
    //   payload: { 
    //     chatId: chatDetail.chat_id, 
    //     replyData: {
    //       chat_detail_id: chatDetail.id,
    //       reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
    //       reply_role: 'system' 
    //     },
    //     isSuccess: false,
    //   }
    // });
  }
}

function* handleGetFBAdsInfoFromUrl(chat_id, url) {
  console.log('chatSagas: handleGetFBAdsInfoFromUrl');
  console.log('handleGetFBAdsInfoFromUrl: url', url);

  try {
    // const aiResponse = yield call(aiAdsInfoFromFBUrlApi, url);
    // console.log('handleGetFBAdsInfoFromUrl: aiResponse', aiResponse);
    let responseData = yield call(handleGetAdsInfoFromFBUrl, chat_id, url);
    console.log('handleGetFBAdsInfoFromUrl: responseData', responseData);

    if(!responseData || responseData.error) {
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat_id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("It looks like the Facebook ad URL you entered is invalid. Would you like to try again with a different URL, or would you prefer me to suggest some ads based on your keywords?")}`,
            edited_reply: '',
            reply_role: 'system',
          },
          isSuccess: true,
        }
      });
      yield put(setChatSelection({
        chat_id: chat_id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: targetList.adsclone_continue_url,
        title: "",
        options: targetOptionsList.adsclone_continue_url,
        animationClass: 'slide-up'
      }));
    } else {

      const body = responseData.message;
      let image_url = responseData.images[0];
      let image_desc = '';

      let replyData = []; // Declare an empty array
      //set reply date and time
      const now = moment(); // Get current time
      const replyDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
      const replyTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

      if(image_url !== ''){  
        // call objreg for image url
        const recognitionResponse = yield call(aiImageRecognitionApi, image_url);
        console.log('handleGetFBAdsInfoFromUrl: recognitionResponse', recognitionResponse);
  
        image_desc = recognitionResponse.data;
        
        // Append to replyData using spread operator
        replyData = [...replyData, {
          reply_url: image_url, 
          reply_role: 'assistant',
          edited_reply: image_desc,
          reply_date: replyDate,
          reply_time: replyTime,
          ui_type: 'hide',
        }];
      }

      replyData = [...replyData, {
        reply: body, 
        reply_role: 'assistant',
        edited_reply: body,
        reply_date: replyDate,
        reply_time: replyTime,
        ui_type: 'hide',
      }];

      replyData = [...replyData, {
        reply: body, 
        reply_url: image_url, 
        reply_role: 'system',
        reply_date: replyDate,
        reply_time: replyTime,
        ui_type: 'facebook_ads',
        page_name: responseData.from.name,
      }];

      yield put(editChatStage({
        chat_id: chat_id,
        updates: {
          stage: '1',
          subStage: 'adsclone_select_ads',
          initial_prompt: body,
        }
      }));
  
      // storeBulkReply
      yield call( handleStoreBulkReply, { 
        payload: { 
          chatId: chat_id, 
          replyData: replyData,
        }
      });
    }
  } catch (error) {
    console.error('handleGetFBAdsInfoFromUrl Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleBrandBoost(chat_id, attempts = 1) {
  console.log('chatSagas: handleBrandBoost attempts',attempts);

  try {
    const brandBoostDetail = yield select(state => selectBrandBoostDetailById(state, chat_id));
    console.log('handleBrandBoost: brandBoostDetail', brandBoostDetail);

    let keyword = brandBoostDetail.facebook_keyword;
    if(attempts !== 1){
      //get keyword prompt
      let prompt_request = 'Based on my keyword: '+brandBoostDetail.facebook_keyword+
      '. Let me know if it belongs to which industry with a single word. It must fit facebook keywords. give me 1 keywords and only give me 1 word keywords.';
      
      let product_keyword = yield call(handleChatbotGeneral, chat_id, prompt_request);
      console.log('handleBrandBoost: prompt_request keyword', product_keyword);
    
      yield put(editBrandBoostDetail({
        chat_id: chat_id,
        updates: {
          facebook_keyword: product_keyword,
        }
      }));

      keyword = product_keyword;
    } 
    
    // let interest_scraper = yield call(handleInterestScraper, chat_id, keyword);
    const aiResponse = yield call(aiAdsInterestScraperApi, keyword);
    console.log('handleInterestScraper: aiResponse', aiResponse);
    const interest_scraper =  aiResponse.data;

    console.log('handleBrandBoost: interest scraper', interest_scraper);
    const interest = interest_scraper.data.slice(0, 5);
    console.log('handleBrandBoost: 5 interest', interest);

    if (!interest_scraper || !interest_scraper.status) {
      if(attempts === 1){
        yield call(handleBrandBoost, chat_id, 2);
      } else if (attempts === 2){
        yield call(handleBrandBoost, chat_id, 3);
      } else {
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'brandboost_keyword',
          }
        }));
  
        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("We're sorry, there is no insights. Let's try with a different product.")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
      }
    } else {
      yield call(updateProductKeywordApi, chat_id, keyword);
      
      //get chat details
      const chatByIdResponse = yield call(getChatByIdApi, chat_id);
      const { chat } = chatByIdResponse.data;
      // console.log('handleBrandBoost: chatByIdResponse', chatByIdResponse);

      const flexible_spec_interest = interest.slice(0, 5).map(item => ({
        id: item.id,
        name: item.name
      }));
    
      //[X]get audience_size_upper_bound
      // const audience_size_upper_bound = interest[0]?.audience_size_upper_bound;

      console.log('handleBrandBoost: interest', interest);
      console.log('handleBrandBoost: flexible_spec_interest', flexible_spec_interest);
      // console.log('handleBrandBoost: reach', audience_size_upper_bound);
      
    
      //get target audience
      let prompt_request = 'Can you tell me the target audience suggest for me if i want to sell '+ chat.product_keyword +'product in '+ chat.ads_location +' , let me know the gender ( return only the gender name ) , married status , language , currency,  age ( not more than 10 years old display in age_min and age_max ) and state ( maximun 3 location ) that is good for me to do my digital marketing with very accurate suggestion, but reply me only the specific output and only in json object format without additianl explanation and description'
      let target_audience_raw = yield call(handleChatbotGeneral, chat_id, prompt_request);
      console.log('handleBrandBoost: prompt_request target_audience_raw', target_audience_raw);
      
      let target_audience = target_audience_raw;

      if (typeof target_audience_raw === 'string') {
        let target_audience_trim = target_audience_raw.replace(/```json|```/g, '').trim();
        console.log('handleBrandBoost: prompt_request target_audience trimmed ', target_audience_trim);

        
        target_audience = JSON.parse(target_audience_trim);
      }
      console.log('handleBrandBoost: prompt_request target_audience ', target_audience);

      //prepare target_spec
      const target_spec = {
        age_max: target_audience.age_max,
        age_min: target_audience.age_min,
        // genders: [],
        // brand_safety_content_filter_levels: ["FACEBOOK_STANDARD"],
        geo_locations: {
          countries: [chat.ads_location],
          location_types: ['home', 'recent']
        },
        publisher_platforms: ["facebook"],
        flexible_spec: [
          {
            interests: flexible_spec_interest
          }
        ],
        // device_platforms: ["mobile"],
        // facebook_positions: ["facebook_reels_overlay", "feed", "search", "marketplace", "video_feeds", "story", "facebook_reels", "instream_video"],
        // audience_network_positions: [],
        // messenger_positions: [],
        // user_os: [],
        // user_device: [],
        // wireless_carrier: [],
        // excluded_publisher_list_ids: [],
        // excluded_brand_safety_content_types: []
      };
      yield put(editBrandBoostDetail({
        chat_id: chat_id,
        updates: {
          flexible_spec_interest: JSON.stringify(flexible_spec_interest), // If you want to keep this as a string
          reach: 200000,//harcoded //audience_size_upper_bound,
          budget: 10000000,//harcoded
          target_audience: JSON.stringify([target_audience]), // Stringify this object
          target_spec: JSON.stringify(target_spec), // Stringify this object
        }
      }));

      yield call(handleBrandBoostSummary, chat_id);

      // yield put(editChatStage({
      //   chat_id: chat_id,
      //   updates: {
      //     stage: '1',
      //     subStage: 'brandboost_budget',
      //   }
      // }));
  
      // yield call( handleStoreReplyGeneral, { 
      //   payload: { 
      //     chatId: chat_id, 
      //     replyData: {
      //       chat_detail_id: '',
      //       reply: 'What is your estimated budget?',
      //       reply_role: 'system',
      //     },
      //     isSuccess: true,
      //   }
      // });
    }
  } catch (error) {
    console.error('handleBrandBoost Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleBrandBoostSummary(chat_id) {
  console.log('chatSagas: handleBrandBoostSummary');

  try {
    const brandBoostDetail = yield select(state => selectBrandBoostDetailById(state, chat_id));
    console.log('handleBrandBoostSummary: brandBoostDetail', brandBoostDetail);

    const apiParameter = {
      budget: brandBoostDetail.budget,
      reach: brandBoostDetail.reach,
      target_spec: brandBoostDetail.target_spec,
    }
    const aiResponse = yield call(adsMetricsApi, apiParameter);
    console.log('handleBrandBoostSummary: adsMetricsApiResponse', aiResponse);

    // let audience_size_lower_bound = aiResponse.data.audience_size_lower_bound;
    // let audience_size_upper_bound = aiResponse.data.audience_size_upper_bound;

    // //get summary
    // let prompt_request = 'Write me a paragraph summary of my facebook ads predictions on how my ads will perform in facebook ads based on my '
    // + 'target audience: ' + brandBoostDetail.target_audience
    //   +' Audience size: '+audience_size_lower_bound+' - '+audience_size_upper_bound
    //   + '. Interest keywords: ' + brandBoostDetail.flexible_spec_interest
    //   +' no explanation, just the summary, no point for';
    // let summary = yield call(handleChatbotGeneral, chat_id, prompt_request);

    let summary = aiResponse.data.summary;
    console.log('handleBrandBoostSummary: summary', summary);

    yield put(editBrandBoostDetail({
      chat_id: chat_id,
      updates: {
        // audience_size_lower_bound: audience_size_lower_bound,
        // audience_size_upper_bound: audience_size_upper_bound,
        summary: summary,
      }
    }));
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        stage: '1',
        subStage: 'brandboost_end',
        status: 'complete',
      }
    }));

    const replyString = {
      keyword_approach: brandBoostDetail.keyword_approach,  //a string
      keyword: brandBoostDetail.keyword, //a string
      keyword_url: brandBoostDetail.keyword_url, //a string
      facebook_keyword: brandBoostDetail.facebook_keyword, //a string
      flexible_spec_interest: JSON.parse(brandBoostDetail.flexible_spec_interest), //parse the stringtified flexible_spec_interest: "[{"id":"6003279598823","name":"Marketing"},{"id":"6003403706343","name":"Promotion (marketing)"},{"id":"6003454920344","name":"Viral marketing"},{"id":"6003127206524","name":"Digital marketing"},{"id":"6003389760112","name":"Social media marketing"}]"
      reach: brandBoostDetail.reach, //a string
      target_spec: JSON.parse(brandBoostDetail.target_spec), //parse the stringtified target_spec: "{"age_max":35,"age_min":25,"geo_locations":{"countries":["MY"]},"publisher_platforms":["facebook"],"flexible_spec":[{"interests":[{"id":"6003279598823","name":"Marketing"},{"id":"6003403706343","name":"Promotion (marketing)"},{"id":"6003454920344","name":"Viral marketing"},{"id":"6003127206524","name":"Digital marketing"},{"id":"6003389760112","name":"Social media marketing"}]}]}"
      target_audience: JSON.parse(brandBoostDetail.target_audience), //parse the stringtified target_audience: "[{"gender":"all","married_status":"all","language":"English, Malay","currency":"MYR","age_min":25,"age_max":35,"state":["Selangor","Penang","Kuala Lumpur"]}]"
      budget: brandBoostDetail.budget, //a string
      // audience_size_lower_bound: audience_size_lower_bound, //a string
      // audience_size_upper_bound: audience_size_upper_bound, //a string
      summary: summary, //a string
    }

    console.log('handleBrandBoostSummary replyString', replyString)

    // Now, you can stringify the entire object if needed
    const finalString = JSON.stringify(replyString);

    console.log('handleBrandBoostSummary finalString', finalString)
    
    const detail = yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: finalString,
          edited_reply: finalString,
          reply_role: 'system',
          ui_type: 'brandboost',
        },
        isSuccess: true,
      }
    });
    console.log('handleBrandBoostSummary store reply', detail)
    
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        adsclone_copy: finalString,
        adsclone_copy_id: detail.id,

      }
    }));

    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: ''
    }));
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("This is the prediction for your ads based on your keyword, would you like to retry with another product?")}`,
          reply_role: 'system',
        },
        isSuccess: true,
      }
    });
    yield put(setChatSelection({ 
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: true, 
      target: targetList.brandboost_is_result,
      title: '',
      options: targetOptionsList.brandboost_is_result,
      animationClass: 'slide-up'
    }));
  } catch (error) {
    console.error('handleBrandBoost Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleVACreateNewImage(chat_id, prompt) {
  console.log('chatSagas: handleVACreateNewImage prompt', prompt);
  
  const stageList = yield select(selectChatStage, chat_id);
  const stageData = stageList[chat_id];
  console.log('handleVACreateNewImage: stageData', stageData);

  try {
    let generated_image_url = yield call(handleTextToImageGeneral, chat_id, prompt, '');

    let image_desc = '';

    if(generated_image_url !== ''){

      // call objreg for image url
      const recognitionResponse = yield call(aiImageRecognitionApi, generated_image_url);
      console.log('handleVACreateNewImage: recognitionResponse', recognitionResponse);

      image_desc = recognitionResponse.data;
    }

    
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    const detail = yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply_url: generated_image_url, 
          edited_reply: image_desc,
          reply_role: 'system',
          ui_type: 'hide'
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        adsclone_image_id: detail.id, 
        adsclone_image_url: detail.reply_url,
        adsclone_image_desc: image_desc,
      }
    }));
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply_url: generated_image_url, 
          reply_role: 'system',
          ui_type: 'result_generate'
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("Great! Here's your image. Does this look okay to you?")}`,
          reply_role: 'system',
        },
        isSuccess: true,
      }
    });

    yield put(setChatSelection({ 
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: true, 
      target: targetList.visualads_is_img,
      title: '',
      options: targetOptionsList.visualads_is_img,
      animationClass: 'slide-up'
    }));
  } catch (error) {
    console.error('handleVACreateNewImage Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleVAChangeBackground(chat_id, prompt, image_url) {
  console.log('chatSagas: handleVAChangeBackground');
  console.log('handleVAChangeBackground: prompt', prompt);
  console.log('handleVAChangeBackground: image_url', image_url);

  // const stageList = yield select(selectChatStage, chat_id);
  // const stageData = stageList[chat_id];
  // console.log('handleVAChangeBackground: stageData', stageData);

  try {

    if(backend_url === "http://127.0.0.1:8000") {
      image_url = 'https://images.squarespace-cdn.com/content/v1/616cfb6a0343cf58c0a570b5/c5505422-d6f5-4817-ad8d-a80bf328af06/IMG_1463.PNG';//'https://www.coca-cola.com/content/dam/onexp/us/en/homepage/usa_home_1_campaign_card_1280x1024.jpg/width1960.jpg';
    }
    console.log('handleVAChangeBackground: real image_url', image_url);

    // let generated_image_url = yield call(handleTextToImageGeneral, chat_id, prompt, '', image_url);
    const aiResponse = yield call(aiBackgroundRegenerateApi, image_url , prompt);
    console.log('handleVAChangeBackground: aiResponse',aiResponse);

    const generated_image_url = aiResponse.data.result[0][0] ?? '';
    let image_desc = '';

    if(generated_image_url !== ''){

      // call objreg for image url
      const recognitionResponse = yield call(aiImageRecognitionApi, generated_image_url);
      console.log('handleVAChangeBackground: recognitionResponse', recognitionResponse);

      image_desc = recognitionResponse.data;
    }

    
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    const detail = yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply_url: generated_image_url, 
          edited_reply: image_desc,
          reply_role: 'system',
          ui_type: 'hide'
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        adsclone_image_id: detail.id, 
        adsclone_image_url: detail.reply_url,
        adsclone_image_desc: image_desc,
      }
    }));
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply_url: generated_image_url, 
          reply_role: 'system',
          ui_type: 'result_generate'
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("Great! Here's your image. Does this look okay to you?")}`,
          edited_reply: image_desc,
          reply_role: 'system',
        },
        isSuccess: true,
      }
    });

    yield put(setChatSelection({ 
      chat_id: chat_id, 
      chat_detail_id: '', 
      isDisplay: true, 
      target: targetList.visualads_is_img,
      title: '',
      options: targetOptionsList.visualads_is_img,
      animationClass: 'slide-up'
    }));
  } catch (error) {
    console.error('handleVACreateNewImage Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleVAMasking(chat_id, prompt) {
  console.log('chatSagas: handleVAMasking');
  console.log('handleVAMasking: prompt', prompt);

  try {
    const stageList = yield select(selectChatStage, chat_id);
    const stageData = stageList[chat_id];
    console.log('handleVAMasking: stageData', stageData)

    let image_url = '';
    if(stageData.source_url !== ''){
      image_url = getFullImageUrl(stageData.source_url);
    }
    console.log('handleVAMasking: image_url', image_url);

    if(backend_url === "http://127.0.0.1:8000") {
      image_url = 'https://images.nightcafe.studio/jobs/dDobcxsvhPdi8nsYyxKR/dDobcxsvhPdi8nsYyxKR--1--a6f3y.jpg';//'https://www.coca-cola.com/content/dam/onexp/us/en/homepage/usa_home_1_campaign_card_1280x1024.jpg/width1960.jpg';
    }
    console.log('handleVAMasking: image_url', image_url);
    //PENDING::translate prompt to english

    const aiResponse = yield call(aiImageMaskApi, image_url , prompt);
    console.log('handleVAMasking: aiResponse',aiResponse);

    const mask_url = aiResponse.data.mask_url ?? '';

    let detail = yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply_url: mask_url, 
          mask_url: mask_url, 
          reply_role: 'system',
          ui_type: 'hide'
        },
        isSuccess: true,
      }
    });

    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        subStage: 'visualads_inpainting',
        mask_url: detail.reply_url,
      }
    }));
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("Great, what would you like me to swap it with?")}`,
          reply_role: 'system' 
        },
        isSuccess: true,
      }
    });
  } catch (error) {
    console.error('handleMasking Saga Error:', error);
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleVAInpainting(chat_id, prompt) {
  console.log('chatSagas: handleVAInpainting');
  console.log('handleVAInpainting: prompt', prompt);

  try {
    const stageList = yield select(selectChatStage, chat_id);
    const stageData = stageList[chat_id];
    console.log('handleVAInpainting: stageData', stageData);

    let source_url = '';
    let mask_url = '';

    if(stageData.source_url !== ''){
      source_url = getFullImageUrl(stageData.source_url)
    }

    if(stageData.mask_url !== ''){
      mask_url = getFullImageUrl(stageData.mask_url)
    }
    console.log('handleVAInpainting: mask_url', mask_url);
    console.log('handleVAInpainting: source_url', source_url);
    if(backend_url === "http://127.0.0.1:8000") {
      //dragon image
      source_url = 'https://images.nightcafe.studio/jobs/dDobcxsvhPdi8nsYyxKR/dDobcxsvhPdi8nsYyxKR--1--a6f3y.jpg';
      //dragon mask
      mask_url = 'https://portal.staging.omninexus.io/storage/uploads/brand_logo/1728494506.png';
    }
    console.log('handleVAInpainting: real mask_url', mask_url);
    console.log('handleVAInpainting: real source_url', source_url);

    const aiResponse = yield call(aiImageInpaintingApi, prompt , source_url , mask_url);
    console.log('handleVAInpainting: aiResponse',aiResponse);

    const inpainting_url = aiResponse.data[0] ?? '';

    if(inpainting_url !== ''){
      // call objreg for image url
      const recognitionResponse = yield call(aiImageRecognitionApi, inpainting_url);
      console.log('handleVAInpainting: recognitionResponse', recognitionResponse);

      const inpainting_desc = recognitionResponse.data;

      let detail = yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat_id, 
          replyData: {
            chat_detail_id: '',
            reply_url: inpainting_url, 
            edited_reply: inpainting_desc, 
            reply_role: 'assistant',
            ui_type: 'hide'
          },
          isSuccess: true,
        }
      });
      yield put(setChatLoader({ 
        chat_id: chat_id, 
        isLoading: true, 
        isListening: false, 
        message: "" 
      }));
      yield put(editChatStage({
        chat_id: chat_id,
        updates: {
          adsclone_image_id: detail.id, 
          adsclone_image_url: detail.reply_url,
          adsclone_image_desc: inpainting_desc,
        }
      }));
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat_id, 
          replyData: {
            chat_detail_id: '',
            reply_url: inpainting_url, 
            reply_role: 'system',
            ui_type: 'result_generate'
          },
          isSuccess: true,
        }
      });
      yield put(setChatLoader({ 
        chat_id: chat_id, 
        isLoading: true, 
        isListening: false, 
        message: "" 
      }));
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat_id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("Great! Here's your image. Does this look okay to you?")}`,
            reply_role: 'system',
          },
          isSuccess: true,
        }
      });
  
      yield put(setChatSelection({ 
        chat_id: chat_id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: targetList.visualads_is_img,
        title: '',
        options: targetOptionsList.visualads_is_img,
        animationClass: 'slide-up'
      }));
    }

  } catch (error) {
    console.error('handleVAInpainting Saga Error:', error);
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleSketchSpark(chat_id) {
  console.log('chatSagas: handleSketchSpark');

  const stageList = yield select(selectChatStage, chat_id);
  const stageData = stageList[chat_id];
  console.log('handleSketchSpark: stageData', stageData);

  try {

    let sketch_desc = stageData.source_desc;
    let product_keyword = stageData.product_keyword ?? '';

    let sketch_desc_complete = 'Generate an realistic and real-life image with this image prompt: '+sketch_desc+
    '. Include in the keyword: '+product_keyword;
    let generated_image_url = yield call(handleTextToImageGeneral, chat_id, sketch_desc_complete, '');
    console.log('handleSketchSpark: generated_image_url', generated_image_url);

    let image_desc = '';

    if(generated_image_url !== ''){

      // call objreg for image url
      let prompt_reg = 'What do you see? Describe this image in detail and generate a copywriting prompt based on this image';
      const recognitionResponse = yield call(aiImageRecognitionApi, generated_image_url, prompt_reg);
      console.log('handleSketchSpark: recognitionResponse', recognitionResponse);

      image_desc = recognitionResponse.data;
    }

    const initial_prompt = 'Only give me the copywriting without explanation. Generate a compelling copywriting for a facebook ads with this: '+image_desc;
    const generatedCopyResponse = yield call(handleChatbotGeneral, chat_id, initial_prompt);
    console.log('handleSketchSpark: generatedCopyResponse', generatedCopyResponse);

    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    const detail_img = yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply_url: generated_image_url, 
          edited_reply: image_desc,
          reply_role: 'system',
          ui_type: 'hide',
        },
        isSuccess: true,
      }
    });
    const detail_copy = yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: generatedCopyResponse, 
          edited_reply: generatedCopyResponse,
          reply_role: 'system',
          ui_type: 'hide',
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    yield put(editChatStage({
      chat_id: chat_id,
      updates: {
        adsclone_copy_id: detail_copy.id, 
        adsclone_copy: detail_copy.reply,
        adsclone_image_id: detail_img.id, 
        adsclone_image_url: detail_img.reply_url,
        adsclone_image_desc: image_desc,
      }
    }));
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    yield call( handleStoreReplyGeneral, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("Here is the generated image and copy")}`,
          edited_reply: image_desc,
          reply_role: 'system',
        },
        isSuccess: true,
      }
    });
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: true, 
      isListening: false, 
      message: "" 
    }));
    

    if(stageData.subStage === 'sketchspark') {
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat_id, 
          replyData: {
            chat_detail_id: '',
            reply: generatedCopyResponse,
            reply_url: generated_image_url, 
            reply_role: 'system',
            ui_type: 'result_generate',
          },
          isSuccess: true,
        }
      });
      yield put(setChatLoader({ 
        chat_id: chat_id, 
        isLoading: true, 
        isListening: false, 
        message: "" 
      }));
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat_id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("Does this align with what you envision?")}`,
            edited_reply: image_desc,
            reply_role: 'system',
          },
          isSuccess: true,
        }
      });
  
      yield put(setChatSelection({ 
        chat_id: chat_id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: targetList.sketchspark_is_result,
        title: '',
        options: targetOptionsList.sketchspark_is_result,
        animationClass: 'slide-up'
      }));
    } else {
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat_id, 
          replyData: {
            chat_detail_id: '',
            reply: generatedCopyResponse,
            reply_url: generated_image_url, 
            reply_role: 'system',
            ui_type: 'result_regenerate',
          },
          isSuccess: true,
        }
      });
      yield put(setChatLoader({ 
        chat_id: chat_id, 
        isLoading: true, 
        isListening: false, 
        message: "" 
      }));
      yield call( handleStoreReplyGeneral, { 
        payload: { 
          chatId: chat_id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("Would you like to save the new image and copy?")}`,
            edited_reply: image_desc,
            reply_role: 'system',
          },
          isSuccess: true,
        }
      });
  
      yield put(setChatSelection({ 
        chat_id: chat_id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: targetList.sketchspark_save_new,
        title: '',
        options: targetOptionsList.sketchspark_save_new,
        animationClass: 'slide-up'
      }));
    }
    
  } catch (error) {
    console.error('handleVACreateNewImage Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleAdsLibrary(chat_id, end_cursor, first = 5) {
  console.log('chatSagas: handleAdsLibrary');
  console.log('handleAdsLibrary: end_cursor', end_cursor);
  console.log('handleAdsLibrary: first', first);
  const stageList = yield select(selectChatStage, chat_id);
  const stageData = stageList[chat_id];
  console.log('handleAdsLibrary: stageData', stageData);

  try {
    const chatByIdResponse = yield call(getChatByIdApi, chat_id);
    const { chat } = chatByIdResponse.data;
    // console.log('handleAdsLibrary: chatByIdResponse', chatByIdResponse);
    let keyword = chat.product_keyword;

    if(stageData.subStage === 'adsclone_adslib'){
      keyword = `"${chat.product_keyword}"`.replace(/\"/g,"\\\"");
      console.log('handleAdsLibrary: quoted keyword', keyword);
    }

    console.log('handleAdsLibrary: keyword used', keyword);

    if(stageData.campaign_type === 'AdsClone'){
      first = null;
    }

    const apiParameter = {
      keywords: keyword ?? '',
      ads_location: chat.ads_location ?? '',
      platform: chat.platform ?? '',
      end_cursor: end_cursor,
      first: first ?? null
    }
    const aiResponse = yield call(adsLibraryApi, apiParameter);
    console.log('handleAdsLibrary: aiResponse', aiResponse);

    if(aiResponse.data.errors) {
      if(stageData.subStage === 'adsclone_adslib'){
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_adslib_2',
          }
        }));

        //PENDING:: set product_keyword
        let keyword = chat.product_keyword.replace(/"/g, '');
        console.log('handleAdsLibrary: unquoted keyword', keyword);

        //update keyword to db
        yield call(updateProductKeywordApi, chat_id, keyword);

        //call adslibrary with unquoted keyword
        yield call(handleAdsLibrary, chat_id, '');

      } else if(stageData.subStage === 'adsclone_adslib_2'){
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_keyword',
          }
        }));

        yield call( handleStoreReplyGeneral, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("We're sorry, but something went wrong. Let's try with a different keyword.")}`,
              reply_role: 'system',
            },
            isSuccess: true,
          }
        });
      } else {
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
              reply_role: 'system' 
            },
            isSuccess: false,
          }
        });
      }
    } else {
      //testing data
      // const responseData = {
      //   edges: [
      //     {
      //       node: {
      //         collated_results: [
      //           {
      //             snapshot: {
      //               body: { text: "This is an ad body text" },
      //               images: [{ resized_image_url: "https://portal.staging.omninexus.io/storage/uploads/reply/Ae4cY2czqc5QLMmUJW9FScIxoqDY9GdDp1f8Wex6.png"}],
      //               page_profile_picture_url: "https://portal.staging.omninexus.io/storage/uploads/brand_logo/1728238582.png",
      //               title: "Ad Title 1",
      //               page_name: "Brand Page 1",
      //             }
      //           }
      //         ]
      //       }
      //     },
      //     {
      //       node: {
      //         collated_results: [
      //           {
      //             snapshot: {
      //               body: { text: "Pepsi is the official home of Pepsi®, providing the latest products, promotions, news, and more to its audience. Stay up to date with Pepsi at www.pepsi.com." },  // This will be excluded
      //               images: [{ resized_image_url: "https://portal.staging.omninexus.io/storage/uploads/reply/Ae4cY2czqc5QLMmUJW9FScIxoqDY9GdDp1f8Wex6.png"}],
      //               page_profile_picture_url: "https://portal.staging.omninexus.io/storage/uploads/brand_logo/1728238582.png",
      //               title: "Ad Title 2",
      //               page_name: "Brand Page 2",
      //             }
      //           }
      //         ]
      //       }
      //     },
      //     {
      //       node: {
      //         collated_results: [
      //           {
      //             snapshot: {
      //               body: { text: "Pepsi is the official home of Pepsi®, providing the latest products, promotions, news, and more to its audience. Stay up to date with Pepsi at www.pepsi.com." },
      //               images: [{ resized_image_url: "https://portal.staging.omninexus.io/storage/uploads/reply/Ae4cY2czqc5QLMmUJW9FScIxoqDY9GdDp1f8Wex6.png"}],
      //               page_profile_picture_url: "https://portal.staging.omninexus.io/storage/uploads/brand_logo/1728238582.png",
      //               title: "Ad Title 3",
      //               page_name: "Brand Page 3",
      //             }
      //           }
      //         ]
      //       }
      //     }
      //   ],
      //   page_info: {
      //     has_next_page: false,  // Indicates if there's more data available
      //     end_cursor: ""     // Helps fetch the next set of results
      //   }    
      // };
      const responseData =  aiResponse.data.data.ad_library_main.search_results_connection;
      
      if (end_cursor === '' && responseData.edges.length === 0) { //if empty for the first ads library call
        yield put(setLoadingMoreAds({ chat_id: chat_id, isLoading: false }));
        
        if(stageData.subStage === 'adsclone_adslib'){
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'adsclone_adslib_2',
            }
          }));
  
          let keyword = chat.product_keyword.replace(/"/g, '');
          console.log('handleAdsLibrary: unquoted keyword', keyword);
  
          //update keyword to db
          yield call(updateProductKeywordApi, chat_id, keyword);
  
          //call adslibrary with unquoted keyword
          yield call(handleAdsLibrary, chat_id, '');
  
        } else if(stageData.subStage === 'adsclone_adslib_2'){
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'adsclone_keyword',
            }
          }));
          yield call( handleStoreReply, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("Oh no, looks like there are no existing ads based on your product keyword. Let's try with a different keyword.")}`,
                reply_role: 'system',
              },
              isSuccess: true,
            }
          });
        } else if(stageData.subStage === 'adslibrary'){
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'no_adslib',
              status: 'start',
            }
          }));
      
          yield call( handleStoreReply, { 
            payload: { 
              chatId: chat_id, 
              replyData: {
                chat_detail_id: '',
                reply: `${i18n.t("There are currently no similar advertisements for your products. However, no need to worry I'd be happy to assist you in creating your winning ads!")}`,
                reply_role: 'system' 
              },
              isSuccess: true,
            }
          });
        }
        
  
      } else {
        yield call(handleAdsLibraryOptions, chat_id, end_cursor, responseData);  
      }
    }

  } catch (error) {
    console.error('handleAdsLibrary Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleAdsLibraryOptions(chat_id, end_cursor, responseData) {

  const chatOption = yield select(state => selectChatOptionById(state, chat_id));
  const chatSelection = yield select(state => selectChatSelectionById(state, chat_id));
  const stageList = yield select(selectChatStage, chat_id);
  const stageData = stageList[chat_id];
  console.log('handleAdsLibrary: stageData', stageData);
  console.log('handleAdsLibraryOptions: chatOption', chatOption);

  const edges = responseData.edges;
  const page_info = responseData.page_info;

  console.log('handleAdsLibraryOptions: edges', edges);
  let count = 0;
  let length = chatSelection.options.length + chatOption.length;
  console.log('handleAdsLibraryOptions: length', length);

  const options = edges.map((edge, index) => {
    const snapshot = edge.node.collated_results[0].snapshot || {}; // Fallback to an empty object
    // console.log('handleAdsLibraryOptions: snapshot', snapshot);

    if((snapshot?.body?.text === "{{product.brand}}" || snapshot?.body?.text === '' || !snapshot?.body?.text) || !(snapshot?.images?.[0]?.resized_image_url)){
      return null;
    } else {

      console.log('handleAdsLibraryOptions: count', count);
      let id = parseInt(count) + parseInt(length);
      console.log('handleAdsLibraryOptions: id', id);
      const item = {
        id: (id).toString(), // Assign unique ids starting from 1
        body: (snapshot?.body?.text === "{{product.brand}}" ? '' : snapshot?.body?.text) || '', 
        image_url: snapshot?.images?.[0]?.resized_image_url || '', // Safely access the first image or fallback to an empty string
        profile_pic_url: snapshot?.page_profile_picture_url || '',
        title: (snapshot?.title === "{{product.name}}" ? '' : snapshot?.title) || '', 
        page_name: snapshot?.page_name || '',
        like_count: 10,
        comment_count: 10,
        share_count: 10,
      };
      count++;

      return item;
    }
  }).filter(item => item !== null); // Filter out null values

  console.log('handleAdsLibraryOptions: valid options', options);

  let finalNewOptions = options;

  if(chatOption.length > 0) {
    finalNewOptions = [...chatOption, ...options]; // Concatenate both arrays
  }
  console.log('handleAdsLibraryOptions: finalNewOptions', finalNewOptions);

  yield put(setChatOption({ chat_id: chat_id, options: finalNewOptions }));

  //update parameter end cursor
  let new_end_cursor = '';
  if(page_info.has_next_page){
    new_end_cursor = page_info.end_cursor;
  } 
  console.log('handleAdsLibraryOptions: new_end_cursor', new_end_cursor);

  if (finalNewOptions.length >= 5 || (finalNewOptions.length > 0 && new_end_cursor === '')) { //if options exactly or more than 5 or its the last set of options
    yield put(setChatOption({ chat_id: chat_id, options: [] }));

    let updatedOptions = finalNewOptions;
    const chatSelection = yield select(state => selectChatSelectionById(state, chat_id));

    if(end_cursor !== ''){ //not first load, append options
      const existingOptions = chatSelection.options;

      console.log('handleAdsLibraryOptions: existingOptions', existingOptions);
      // append new options
      updatedOptions = [...existingOptions, ...finalNewOptions]; // Concatenate both arrays
    }
    console.log('handleAdsLibraryOptions: updatedOptions', updatedOptions);

    //hide loader
    yield put(setChatLoader({ 
      chat_id: chat_id, 
      isLoading: false, 
      isListening: false, 
      message: "" 
    }));

    if(stageData.subStage === 'adsclone_adslib' || stageData.subStage === 'adsclone_adslib_2'){
      //show selection
      yield put(setChatSelection({ 
        chat_id: chat_id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: 'Ads Clone',
        title: '',
        options: updatedOptions,
        end_cursor: new_end_cursor,
        animationClass: 'slide-up'
      }));
      yield put(setLoadingMoreAds({ chat_id: chat_id, isLoading: true }));

      //loop the api if have more ads untill last valid ads
      if(new_end_cursor !== ''){ //if have more ads
        yield call(handleAdsLibrary, chat_id, new_end_cursor);  
      }

    } else if(stageData.subStage === 'adslibrary'){
      //show selection
      yield put(setChatSelection({ 
        chat_id: chat_id, 
        chat_detail_id: '', 
        isDisplay: true, 
        target: 'Ads Library',
        title: '',
        options: updatedOptions,
        end_cursor: new_end_cursor,
        animationClass: 'slide-up'
      }));
    }
    
  } else { // if non from this keyword of ads is valid or if the loaded ads is less than 5
    if(new_end_cursor !== ''){ //if have more ads
      //update new end cursor
      yield put(editChatSelection({
        chat_id: chat_id,
        updates: {
          end_cursor: new_end_cursor,
        }
      }));

      let first = 5 - finalNewOptions.length;
      console.log('handleAdsLibraryOptions: first', first);

      // call again to get next n ads
      yield call(handleAdsLibrary, chat_id, new_end_cursor, first);  
    } else {
      if(stageData.subStage === 'adsclone_adslib'){
        const chatByIdResponse = yield call(getChatByIdApi, chat_id);
        const { chat } = chatByIdResponse.data;
        // console.log('handleAdsLibraryOptions: chatByIdResponse', chatByIdResponse);
        
          yield put(editChatStage({
            chat_id: chat_id,
            updates: {
              stage: '1',
              subStage: 'adsclone_adslib_2',
            }
          }));

          console.log('handleAdsLibraryOptions: unquoted keyword', chat.product_keyword);
  
          //update keyword to db
          yield call(updateProductKeywordApi, chat_id, chat.product_keyword);
  
          //call adslibrary with unquoted keyword
          yield call(handleAdsLibrary, chat_id, '');
  
      } else if(stageData.subStage === 'adsclone_adslib_2'){
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'adsclone_keyword',
          }
        }));
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("Oh no, looks like there are no existing ads based on your product keyword. Let's try with a different keyword.")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
      } else {
        yield put(editChatStage({
          chat_id: chat_id,
          updates: {
            stage: '1',
            subStage: 'no_adslib',
            status: 'start',
          }
        }));
    
        yield call( handleStoreReply, { 
          payload: { 
            chatId: chat_id, 
            replyData: {
              chat_detail_id: '',
              reply: `${i18n.t("There are currently no similar advertisements for your products. However, no need to worry I'd be happy to assist you in creating your winning ads!")}`,
              reply_role: 'system' 
            },
            isSuccess: true,
          }
        });
      }
    }
  }

  yield put(setLoadingMoreAds({ chat_id: chat_id, isLoading: false }));
}

function* handleChatbot(chatDetail, newDetail = false) {
  console.log('chatSagas: handleChatbot');

  try {
    const chatMemory = yield select(selectChatMemoryForCurrentChat);
    console.log('handleChatbot: chatMemory',chatMemory);

    const aiResponse = yield call(aiChatbotApi, chatMemory);
    console.log('handleChatbot: aiResponse', aiResponse);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: newDetail ? '' : chatDetail.id,
          reply: aiResponse.data ?? '', 
          edited_reply: aiResponse.data ?? '', 
          reply_role: 'assistant' 
        },
        isSuccess: true,
      }
    });

  } catch (error) {
    console.error('handleChatbot Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleChatbotGeneral(chat_id, prompt) {
  console.log('chatSagas: handleChatbotGeneral prompt', prompt);

  try {
    const chatMemory = yield select(selectChatMemoryForCurrentChat);
    console.log('handleChatbotGeneral: chatMemory',chatMemory);

    //apend new_memory to chat_memory
    const new_memory = {
      role: 'user',
      content: prompt,
    };
    
    const updatedChatMemory = [...chatMemory, new_memory];
    console.log('handleChatbotGeneral: updatedChatMemory',updatedChatMemory);

    const aiResponse = yield call(aiChatbotApi, updatedChatMemory);
    console.log('handleChatbotGeneral: aiResponse', aiResponse);

    return aiResponse.data;

  } catch (error) {
    console.error('handleChatbotGeneral Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleTextToImage(chatDetail) {
  console.log('chatSagas: handleTextToImage: chatDetail', chatDetail)

  try {
    // call api: aiImageFlux
    const stageList = yield select(selectChatStage, chatDetail.chat_id);
    const stageData = stageList[chatDetail.chat_id];
    console.log('handleTextToImage: stageData', stageData)

    let prompt = stageData.initial_prompt; //chatDetail.edited_prompt;
    let image_url = ''; //chatDetail.prompt_url ? getFullImageUrl(chatDetail.prompt_url) : '';

    switch (stageData.stage) {
      case '1':
        if(stageData.subStage === 'generate_adslib_image'){
          //only text from chatDetail edited_prompt
          prompt = chatDetail.edited_prompt;
        }
        break;
      case '3':
        if(stageData.subStage === 'initial_image'){
          //only text from stageData initial prompt
        } else if (stageData.subStage === 'recreate_image'){
          //text(from chatDetail edited prompt)
          prompt = chatDetail.edited_prompt;
        } else if (stageData.subStage === 'regenerate_image'){
          prompt = chatDetail.edited_prompt;
        } else if (stageData.subStage === 'selected_image_revise'){
          //only text from stageData initial prompt
        } else if (stageData.subStage === 'upload_image'){
          //text(from chatDetail edited prompt) & image (from stageData source url)
          prompt = chatDetail.edited_prompt ?? '';
          image_url = stageData.source_url ? getFullImageUrl(stageData.source_url) : '';
        }
        break;
      default:
        break;
    }

    const apiParameter = {
      size: stageData.image_size
    }

    if(backend_url === "http://127.0.0.1:8000") {
      image_url = 'https://images.nightcafe.studio/jobs/dDobcxsvhPdi8nsYyxKR/dDobcxsvhPdi8nsYyxKR--1--a6f3y.jpg';//'https://www.coca-cola.com/content/dam/onexp/us/en/homepage/usa_home_1_campaign_card_1280x1024.jpg/width1960.jpg';
    }
    console.log('handleTextToImage: prompt',prompt);
    console.log('handleTextToImage: image_url',image_url);
    console.log('handleTextToImage: apiParameter',apiParameter);


    const aiResponse = yield call(aiImageFluxApi, prompt, image_url, apiParameter);
    console.log('handleTextToImage: aiResponse',aiResponse);

    const aiDataResult = aiResponse.data;

    const reply_url = aiDataResult.images[0].url ?? '';
    let edited_reply = '';

    if(reply_url !== ''){
      //recognize image
      const recognitionResponse = yield call(aiImageRecognitionApi, reply_url);
      console.log('handleTextToImage: recognitionResponse', recognitionResponse);
      
      edited_reply = recognitionResponse.data;
    }

    switch (stageData.stage) {
      case '3':
        if (stageData.subStage === 'recreate_image' || stageData.subStage === 'regenerate_image'){
          yield put(editChatStage({
            chat_id: chatDetail.chat_id,
            updates: {
              stage: '3',
              subStage: 'initial_image',
            }
          }));
        } else if (stageData.subStage === 'selected_image_revise'){ //Stage 3
          yield put(editChatStage({
            chat_id: chatDetail.chat_id,
            updates: {
              stage: '3',
              subStage: 'selected_image_edit',
            }
          }));
        } else if (stageData.subStage === 'upload_image'){
          // do nothing
        }
        break;
      default:
        break;
    }
    
    // call function: handleStoreReply
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply_url: reply_url, 
          edited_reply: edited_reply,
          reply_role: 'assistant' ,
        },
        isSuccess: true,
      }
    });
    
  } catch (error) {
    console.error('handleTextToImage Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleTextToImageGeneral(chat_id, prompt, size, image_url = '') {
  console.log('chatSagas: handleTextToImageGeneral: chat_id', chat_id)

  try {
    if(backend_url === "http://127.0.0.1:8000") {
      image_url = 'https://images.nightcafe.studio/jobs/dDobcxsvhPdi8nsYyxKR/dDobcxsvhPdi8nsYyxKR--1--a6f3y.jpg';//'https://www.coca-cola.com/content/dam/onexp/us/en/homepage/usa_home_1_campaign_card_1280x1024.jpg/width1960.jpg';
    }
    const apiParameter = {
      size: size
    }

    console.log('handleTextToImageGeneral: prompt',prompt);
    console.log('handleTextToImageGeneral: image_url',image_url);
    console.log('handleTextToImageGeneral: apiParameter',apiParameter);

    const aiResponse = yield call(aiImageFluxApi, prompt, image_url, apiParameter);
    console.log('handleTextToImageGeneral: aiResponse',aiResponse);

    const aiDataResult = aiResponse.data;

    const reply_url = aiDataResult.images[0].url ?? '';

    return reply_url;
    
  } catch (error) {
    console.error('handleTextToImageGeneral Saga Error:', error);

    yield call( handleStoreReply, { 
      payload: { 
        chatId: chat_id, 
        replyData: {
          chat_detail_id: '',
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleMasking(chatDetail) {
  console.log('chatSagas: handleMasking',chatDetail);

  try {
    const stageList = yield select(selectChatStage, chatDetail.chat_id);
    const stageData = stageList[chatDetail.chat_id];
    console.log('handleMasking: stageData', stageData)

    let image_url = '';
    if(stageData.source_url !== ''){
      image_url = getFullImageUrl(stageData.source_url);
    }
    if(backend_url === "http://127.0.0.1:8000") {
      image_url = 'https://i.postimg.cc/bvPN21bX/Girl.jpg';
    }

    const aiResponse = yield call(aiImageMaskApi, image_url , chatDetail.prompt);
    console.log('handleMasking: aiResponse',aiResponse);

    const mask_url = aiResponse.data.mask_url ?? '';

    if(mask_url !== ''){
      // call objreg for image url
      const recognitionResponse = yield call(aiImageRecognitionApi, mask_url);
      console.log('handleMasking: recognitionResponse', recognitionResponse);

      const mask_desc = recognitionResponse.data;

      yield put(editChatStage({
        chat_id: chatDetail.chat_id,
        updates: {
          stage: '3',
          subStage: 'edit_image_part_inpainting',
        }
      }));

      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: chatDetail.id,
            reply_url: mask_url, 
            edited_reply: mask_desc, 
            reply_role: 'assistant' 
          },
          isSuccess: true,
        }
      });

    } else {
      yield put(editChatStage({
        chat_id: chatDetail.chat_id,
        updates: {
          stage: '1',
          subStage: 'initial_prompt',
        }
      }));
      

      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
            reply_role: 'system' 
          },
          isSuccess: false,
        }
      });
    }
    
  } catch (error) {
    console.error('handleMasking Saga Error:', error);
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleInpainting(chatDetail) {
  console.log('chatSagas: handleInpainting');

  try {
    const stageList = yield select(selectChatStage, chatDetail.chat_id);
    const stageData = stageList[chatDetail.chat_id];
    console.log('handleInpainting: stageData', stageData);

    let source_url = '';
    let mask_url = '';

    if(stageData.source_url !== ''){
      source_url = getFullImageUrl(stageData.source_url)
    }

    if(stageData.mask_url !== ''){
      mask_url = getFullImageUrl(stageData.mask_url)
    }

    if(backend_url === "http://127.0.0.1:8000") {
      source_url = 'https://portal.staging.omninexus.io/storage/uploads/prompt/1727673961.png';
      mask_url = 'https://portal.staging.omninexus.io/storage/uploads/prompt/1727677706.png';
    }

    const aiResponse = yield call(aiImageInpaintingApi, chatDetail.prompt , source_url , mask_url);
    console.log('handleInpainting: aiResponse',aiResponse);

    const inpainting_url = aiResponse.data[0] ?? '';

    if(inpainting_url !== ''){
      // call objreg for image url
      const recognitionResponse = yield call(aiImageRecognitionApi, inpainting_url);
      console.log('handleInpainting: recognitionResponse', recognitionResponse);

      const inpainting_desc = recognitionResponse.data;

      yield put(editChatStage({
        chat_id: chatDetail.chat_id,
        updates: {
          stage: '3',
          subStage: 'initial_image',
        }
      }));

      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: chatDetail.id,
            reply_url: inpainting_url, 
            edited_reply: inpainting_desc, 
            reply_role: 'assistant' 
          },
          isSuccess: true,
        }
      });

    } else {
      yield put(editChatStage({
        chat_id: chatDetail.chat_id,
        updates: {
          stage: '1',
          subStage: 'initial_prompt',
        }
      }));
      
      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
            reply_role: 'system' 
          },
          isSuccess: false,
        }
      });
    }

  } catch (error) {
    console.error('handleInpainting Saga Error:', error);
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleChangeBackground(chatDetail) {
  console.log('chatSagas: handleChangeBackground', chatDetail);

  try {
    const stageList = yield select(selectChatStage, chatDetail.chat_id);
    const stageData = stageList[chatDetail.chat_id];
    console.log('handleChangeBackground: stageData', stageData);

    let image_url = chatDetail.source_url ? getFullImageUrl(chatDetail.source_url) : '';
    if(backend_url === "http://127.0.0.1:8000") {
      image_url = 'https://images.squarespace-cdn.com/content/v1/616cfb6a0343cf58c0a570b5/c5505422-d6f5-4817-ad8d-a80bf328af06/IMG_1463.PNG';//'https://www.coca-cola.com/content/dam/onexp/us/en/homepage/usa_home_1_campaign_card_1280x1024.jpg/width1960.jpg';
    }
    const aiResponse = yield call(aiBackgroundRegenerateApi, image_url , chatDetail.prompt);
    console.log('handleChangeBackground: aiResponse',aiResponse);

    const image_edit_bg_url = aiResponse.data.result[0][0] ?? '';

    if(image_edit_bg_url !== ''){
      // call objreg for image url
      const recognitionResponse = yield call(aiImageRecognitionApi, image_edit_bg_url);
      console.log('handleChangeBackground: recognitionResponse', recognitionResponse);

      const image_edit_bg_desc = recognitionResponse.data;

      yield put(editChatStage({
        chat_id: chatDetail.chat_id,
        updates: {
          stage: '3',
          subStage: 'initial_image',
        }
      }));

      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: chatDetail.id,
            reply_url: image_edit_bg_url, 
            edited_reply: image_edit_bg_desc, 
            reply_role: 'assistant' 
          },
          isSuccess: true,
        }
      });
    } else {

      yield put(editChatStage({
        chat_id: chatDetail.chat_id,
        updates: {
          stage: '1',
          subStage: 'initial_prompt',
        }
      }));

      yield call( handleStoreReply, { 
        payload: { 
          chatId: chatDetail.chat_id, 
          replyData: {
            chat_detail_id: '',
            reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
            reply_role: 'system' 
          },
          isSuccess: false,
        }
      });
    }

  } catch (error) {
    console.error('handleChangeBackground Saga Error:', error);
    yield call( handleStoreReply, { 
      payload: { 
        chatId: chatDetail.chat_id, 
        replyData: {
          chat_detail_id: chatDetail.id,
          reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
          reply_role: 'system' 
        },
        isSuccess: false,
      }
    });
  }
}

function* handleGetAdsInfoFromFBUrl (chat_id, url) {
  try {
    const aiResponse = yield call(aiAdsInfoFromFBUrlApi, url);
    console.log('handleGetAdsInfoFromFBUrl: aiResponse', aiResponse);

    return aiResponse.data;
  } catch (error) {
    return false;
  }
}
// function* handleRemoveBackground(chatDetail) {
//   console.log('chatSagas: handleRemoveBackground');

//   try {
    
//   } catch (error) {
//     console.error('handleRemoveBackground Saga Error:', error);
//     yield call( handleStoreReply, { 
//       payload: { 
//         chatId: chatDetail.chat_id, 
//         replyData: {
//           chat_detail_id: chatDetail.id,
//           reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
//           reply_role: 'system' 
//         },
//         isSuccess: false,
//       }
//     });
//   }
// }


// function* handleInterestScraper(chat_id, keyword) {
//   console.log('chatSagas: handleInterestScraper');
//   console.log('handleInterestScraper: keyword', keyword);

//   try {
//     const aiResponse = yield call(aiAdsInterestScraperApi, keyword);
//     console.log('handleInterestScraper: aiResponse', aiResponse);

//     const responseData =  aiResponse.data;
//     console.log('handleInterestScraper: responseData', responseData)

//     // const flexible_spec = responseData.data.slice(0, 5);
    
//     return responseData;

//   } catch (error) {
//     console.error('handleInterestScraper Saga Error:', error);

//     yield call( handleStoreReply, { 
//       payload: { 
//         chatId: chat_id, 
//         replyData: {
//           chat_detail_id: '',
//           reply: `${i18n.t("errors.FAILED_AI_RESPONSE")}`, 
//           reply_role: 'system' 
//         },
//         isSuccess: false,
//       }
//     });
//   }
// }

// Handle Marvis API end-------------------------------------------- //

// Root Saga for Chat
export function* chatSagas() {
  yield takeLatest(chatClickAction.type, handleChatClick);
  yield takeLatest(chatClickExternalAction.type, handleChatClickExternal);
  yield takeLatest(fetchChatMemoryAction.type, handleFetchChatMemory);
  yield takeLatest(updateReactionAction.type, handleUpdateReaction);
  yield takeLatest(storePromptAction.type, handleStorePrompt);
  yield takeLatest(fetchMoreChatDetailsAction.type, handleFetchMoreChatDetails);
  yield takeLatest(createChatAction.type, handleCreateChat);
  yield takeLatest(selectionModeAction.type, handleSelectionMode);
  yield takeLatest(uploadImageAction.type, handleUploadImage);
  yield takeLatest(cameraAction.type, handleCamera);
  yield takeLatest(loadMoreAdsAction.type, handleLoadMoreAds);
  yield takeLatest(createMoreAdsAction.type, handleCreateMoreAds);
  yield takeLatest(selectLocationAction.type, handleSelectLocation);
  yield takeLatest(createMoreCopyCraftAction.type, handleCreateMoreCopyCraft);

}

export default chatSagas;


